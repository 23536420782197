import axios from 'axios'
import moment from 'moment'
import store from '@/store'

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL
const MISC_API_BASE_URL = process.env.VUE_APP_MISC_API_BASE_URL

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.withCredentials = true

axios.interceptors.request.use(config => {
  config.headers['Accept-Language'] = store.state.lang
  return config
})

export default {

  getMaintenanceConfig() {
    const timestamp = moment().valueOf()

    return axios({
      method: 'GET',
      url: `/maintenance.json?ts=${timestamp}`,
    })
  },

  getBranches() {
    return axios({
      method: 'GET',
      url: `${MISC_API_BASE_URL}/data-service/branch/form-type/600`
    })
  },

  getPsd2DataUrl() {
    return axios({
      method: 'POST',
      url: `${API_BASE_URL}/psd2-data/init`
    })
  },

  getApplicationId(editToken) {
    return axios({
      method: 'GET',
      url: `${API_BASE_URL}/tokens/${editToken}`
    })
  },

  getApplication(applicationId) {
    return axios({
      method: 'GET',
      url: `${API_BASE_URL}/applications/${applicationId}`
    })
  },

  getConsents() {
    return axios({
      method: 'GET',
      url: `${API_BASE_URL}/sessions/consents`
    })
  },

  rejectApplication(applicationId) {
    return axios({
      method: 'DELETE',
      url: `${API_BASE_URL}/applications/${applicationId}/second-applicant/approval`
    })
  },

  approveApplication(applicationId, applicant) {
    return axios({
      method: 'POST',
      url: `${API_BASE_URL}/applications/${applicationId}/second-applicant/approval`,
      data: applicant
    })
  },

  submitApplication(data, mortgageType) {
    let url

    switch(mortgageType) {
      case 'CONSTRUCTION_LOAN':
        url = `${API_BASE_URL}/applications/construction-loan`
        break
      case 'REFINANCE':
        url = `${API_BASE_URL}/applications/refinance-loan`
        break
      default:
        url = `${API_BASE_URL}/applications/mortgage-loan`
    }

    return axios({
      method: 'POST',
      url: url,
      data: data
    })
  }

}
