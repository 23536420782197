export class CollateralCalculator {

  static calculateCollateralValue(assetSellingPrice, assetDebtFreePrice, assetType, loanTargetType, isDebtFreePriceIncluded) {
    let value

    switch(true) {
      case assetType === 'CURRENT_TARGET' && loanTargetType === 'APARTMENT' && isDebtFreePriceIncluded:
        value = assetDebtFreePrice * .7
        break
      case assetType === 'CURRENT_TARGET' && loanTargetType === 'APARTMENT' && !isDebtFreePriceIncluded:
        value = assetDebtFreePrice * .7 - (assetDebtFreePrice - assetSellingPrice)
        break
      case assetType === 'CURRENT_TARGET' && loanTargetType === 'REAL_ESTATE':
        value = assetDebtFreePrice * .7
        break
      case assetType === 'CURRENT_TARGET' && loanTargetType === 'RIGHT_OF_OCCUPANCY':
        value = assetDebtFreePrice * .85
        break
      case assetType === 'AKTIA_WARRANTY':
        value = assetDebtFreePrice * .3
        break
      case assetType === 'GOVERNMENT_WARRANTY':
        value = (assetDebtFreePrice * .2) <= 50000 ? (assetDebtFreePrice * .2) : 50000
        break
      case assetType === 'APARTMENT':
        value = assetDebtFreePrice * .7
        break
      case assetType === 'REAL_ESTATE':
        value = assetDebtFreePrice * .7
        break
      case assetType === 'VACATION_HOME':
        value = assetDebtFreePrice * .5
        break
      case assetType === 'SECURITIES':
        value = assetDebtFreePrice * .4
        break
      case assetType === 'FOREST_OR_LAND_AREA':
        value = assetDebtFreePrice * .5
        break
      case assetType === 'BANK_SAVINGS':
        value = assetDebtFreePrice * 1
        break
      default:
        value = 0
    }

    return value
  }

}
