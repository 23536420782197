<template>
  <SummaryList>
    <SummaryItem lg :label="$t('mortgageCalculator.summary.loanPayment')">
      <span v-if="hasError">&ndash; {{ $t('eurosPerMonth') }}</span>
      <span v-else>{{ loanPayment | currency(0, ` ${this.$t('eurosPerMonth')}`) }}</span>
    </SummaryItem>
    <div class="divider mt-3 mb-4"></div>
    <SummaryItem :label="$t('mortgageCalculator.summary.effectiveInterest')">
      <span v-if="hasError">&ndash; &percnt;</span>
      <span v-else>{{ effectiveInterest | currency(2, ' %') }}</span>
    </SummaryItem>
    <SummaryItem :label="$t('mortgageCalculator.summary.totalInterest')">
      <span v-if="hasError">&ndash; &euro;</span>
      <span v-else>{{ totalInterest | currency }}</span>
    </SummaryItem>
    <SummaryItem :label="$t('mortgageCalculator.summary.totalExpense')">
      <span v-if="hasError">&ndash; &euro;</span>
      <span v-else>{{ totalExpense | currency }}</span>
    </SummaryItem>
  </SummaryList>
</template>

<script>
import SummaryList from '../SummaryList'
import SummaryItem from '../SummaryItem'
import { LoanCalculator } from '@/utils/loan-calculator'

export default {
  components: {
    SummaryList,
    SummaryItem
  },
  props: {
    loanAmount: {
      type: [ Number, String ],
      required: true
    },
    loanTime: {
      type: [ Number, String ],
      required: true
    },
    loanInterest: {
      type: Number,
      required: true
    },
    numberOfPaymentsPerYear: {
      type: Number,
      required: true
    },
    originationFee: {
      type: Number,
      required: true
    },
    serviceFee: {
      type: Number,
      required: true
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loanPayment: '',
      effectiveInterest: '',
      totalExpense: '',
      totalInterest: ''
    }
  },
  mounted() {
    this.calculateLoanPayment()
  },
  watch: {
    $props: {
      handler() {
        this.calculateLoanPayment()
      },
      deep: true
    }
  },
  methods: {
    calculateLoanPayment() {
      const {
        loanAmount,
        loanTime,
        loanInterest,
        numberOfPaymentsPerYear,
        originationFee,
        serviceFee
      } = this

      const loanPayment = LoanCalculator.calculateLoanPayment(
        loanAmount,
        loanTime,
        numberOfPaymentsPerYear,
        loanInterest
      )

      const effectiveInterest = LoanCalculator.calculateEffectiveInterest(
        loanAmount,
        loanTime,
        loanPayment,
        numberOfPaymentsPerYear,
        originationFee,
        serviceFee,
        loanInterest
      )

      const totalExpense = (loanTime * numberOfPaymentsPerYear * loanPayment) + (loanTime * numberOfPaymentsPerYear * serviceFee) + originationFee
      const totalInterest = loanTime * numberOfPaymentsPerYear * loanPayment - loanAmount

      this.loanPayment = loanPayment
      this.effectiveInterest = effectiveInterest * 100
      this.totalInterest = totalInterest
      this.totalExpense = totalExpense
    }
  }
}
</script>
