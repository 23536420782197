import Vue from 'vue'
import Vuelidate from 'vuelidate'
import wNumb from 'wnumb'
import noUiSlider from 'nouislider'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import '@/directives'
import '@/filters'

import { Api } from '@/api'

import Footer from '@/components/Footer.vue'
import Icon from '@/components/Icon.vue'
import Toast from '@/components/Toast.vue'

import Calculator from '@/views/Calculator.vue'
import App from '@/App.vue'

// Prevent production tip on Vue Startup
Vue.config.productionTip = false

// Make noUiSlider and wNumb available to Vue and window
Vue.prototype.$noUiSlider = noUiSlider
window.noUiSlider = noUiSlider

Vue.prototype.$wNumb = wNumb
window.wNumb = wNumb

// Register global components
Vue.component('Footer', Footer)
Vue.component('Icon', Icon)
Vue.component('Toast', Toast)

// Form validator
Vue.use(Vuelidate)

if ((/laskuri/.test(window.location.href))) {
  new Vue({
    i18n,
    render: h => h(Calculator)
  }).$mount('#app')
} else {
  Api.getMaintenanceConfig()
    .then(({ data }) => {
      Vue.prototype.$isMaintenance = data.maintenance

      new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
      }).$mount('#app')
    })
}
