<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'toast',
  props: {
    success: Boolean,
    warning: Boolean,
    error: Boolean
  },
  computed: {
    classes() {
      return {
        'toast': true,
        'toast-success': this.success,
        'toast-warning': this.warning,
        'toast-error': this.error
      }
    }
  }
}
</script>
