import store from '@/store'
import _ from 'lodash'

export default {

  /**
   * Get form data for applicant base info
   *
   * @param {String="firstApplicant","secondApplicant"} applicant
   *
   * @return Object
   */
  getApplicantBaseInfoData(applicant = 'firstApplicant') {
    const state = JSON.parse(JSON.stringify(store.state))

    const {
      firstName,
      lastName,
      ssn,
      phoneNumber,
      email
    } = state[applicant]

    const formData = {
      firstName,
      lastName,
      ssn,
      phoneNumber,
      email
    }

    return formData
  },

  /**
   * Get form data for applicant
   *
   * @param {String="firstApplicant","secondApplicant"} applicant
   *
   * @return Object
   */
  getApplicantData(applicant = 'firstApplicant') {
    const state = JSON.parse(JSON.stringify(store.state))
    const getters = JSON.parse(JSON.stringify(store.getters))

    const {
      firstName,
      lastName,
      ssn,
      phoneNumber,
      email,
      address,
      education,
      maritalStatus,
      statusOfHousing,
      socioeconomicStatus,
      grossIncome,
      netIncome,
      dividendIncome,
      rentalIncome,
      otherCapitalIncome,
      childBenefitIncome,
      childSupportIncome,
      otherIncome,
      additionalInformation,
      employment,
      employer,
      occupation,
      assets,
      loans,
      generalTermsAccepted,
      consents
    } = state[applicant]

    const {
      streetAddress,
      zip,
      city
    } = address

    const formData = {
      firstOwner: getters.firstOwner[applicant],
      baseInfo: {
        firstName,
        lastName,
        ssn,
        phoneNumber,
        email
      },
      address: {
        streetAddress,
        zip,
        city
      },
      education,
      maritalStatus,
      statusOfHousing,
      socioeconomicStatus,
      grossIncome,
      netIncome,
      dividendIncome,
      rentalIncome,
      otherCapitalIncome,
      capitalIncome: getters.capitalIncome[applicant],
      childBenefitIncome,
      childSupportIncome,
      otherIncome,
      additionalInformation,
      generalTermsAccepted
    }

    if (getters.isEmploymentApplied[applicant]) {
      formData.employment = employment
      formData.employer = employer
      formData.occupation = occupation
      formData.employmentStart = getters.employmentStartDate[applicant]

      if (getters.isEmploymentEndApplied[applicant]) {
        formData.employmentEnd = getters.employmentEndDate[applicant]
      }
    }

    if (getters.isAssetsApplied[applicant]) {
      formData.assets = assets
    }

    if (getters.isLoansApplied[applicant]) {
      formData.loans = loans
    }

    formData.consents = consents
    return formData
  },

  getFormData() {
    const state = JSON.parse(JSON.stringify(store.state))
    const getters = JSON.parse(JSON.stringify(store.getters))

    const formData = {}

    formData.externalId = state.transactionId
    formData.campaignId = state.campaignId
    formData.lang = _.toUpper(state.lang)
    formData.loanType = state.mortgageType
    formData.numberOfApplicants = state.numberOfApplicants

    if (getters.isLoanTargetKnownApplied) {
      formData.loanTargetKnown = state.isLoanTargetKnown
    }

    /** ASUNNON TIEDOT **/
    if ((getters.isLoanTargetKnownApplied && state.isLoanTargetKnown) || getters.isConstructionMortgage || getters.isRefinance) {
      formData.loanTarget = {
        otherHousingExpenses: state.loanTarget.otherHousingExpenses
      }

      if (getters.isConstructionMortgage) {
        if (state.loanTarget.address.streetAddress) {
          formData.loanTarget.streetAddress = state.loanTarget.address.streetAddress
        }

        if (state.loanTarget.address.zip) {
          formData.loanTarget.zip = state.loanTarget.address.zip
        }

        if (state.loanTarget.address.city) {
          formData.loanTarget.city = state.loanTarget.address.city
        }
      } else {
        formData.loanTarget.address = {
          streetAddress: state.loanTarget.address.streetAddress,
          zip: state.loanTarget.address.zip,
          city: state.loanTarget.address.city
        }
      }

      if (!getters.isConstructionMortgage) {
        formData.loanTarget.type = state.loanTarget.type
      }

      if (getters.isLoanTargetKnownApplied && state.isLoanTargetKnown && (getters.isApartment || getters.isRealEstate)) {
        formData.loanTarget.sellingPrice = state.loanTarget.sellingPrice
      }

      if (getters.isLoanTargetKnownApplied && state.isLoanTargetKnown && getters.isApartment) {
        formData.loanTarget.debtFreePrice = state.loanTarget.debtFreePrice
        formData.loanTarget.housingCompanyLoan = state.loanTarget.debtFreePrice - state.loanTarget.sellingPrice
      }

      if (!getters.isConstructionMortgage && getters.isApartment) {
        formData.loanTarget.maintenanceCost = state.loanTarget.maintenanceCost
        formData.loanTarget.financingCost = state.loanTarget.financingCost
      }

      if (!getters.isConstructionMortgage && getters.isRightOfOccupancy) {
        formData.loanTarget.originalRightOfOccupancyFee = state.loanTarget.originalRightOfOccupancyFee
        formData.loanTarget.rightOfOccupancySellingPrice = state.loanTarget.rightOfOccupancySellingPrice
        formData.loanTarget.residenceCharge = state.loanTarget.residenceCharge
      }

      if (getters.isConstructionMortgage) {
        formData.loanTarget.buildingCostEstimate = state.loanTarget.buildingCostEstimate
        formData.loanTarget.buildingGroundRented = state.loanTarget.isBuildingGroundRented

        if (state.loanTarget.isBuildingGroundRented) {
          formData.loanTarget.buildingGroundRentAmount = state.loanTarget.buildingGroundRentAmount
          formData.loanTarget.buildingGroundLeaseEndDate = getters.buildingGroundLeaseEndDate
        } else {
          formData.loanTarget.buildingGroundPrice = state.loanTarget.buildingGroundPrice
        }
      }

      if (getters.isRefinance) {
        formData.loanTarget.purchaseYear = state.loanTarget.purchaseYear
      }

      if (getters.isRefinance && getters.isApartment) {
        formData.loanTarget.housingCompanyLoan = state.loanTarget.housingCompanyLoan
      }

      if (state.loanTarget.advertisementUrl) {
        formData.loanTarget.advertisementUrl = state.loanTarget.advertisementUrl
      }
    }

    /** NYKYINEN ASUNTOLAINA **/
    if (getters.isRefinance) {
      formData.currentMortgage = {
        amount: state.currentMortgage.amount
      }

      if (state.currentMortgage.bank) {
        formData.currentMortgage.bank = state.currentMortgage.bank
      }

      if (state.currentMortgage.margin) {
        formData.currentMortgage.margin = state.currentMortgage.margin
      }
    }

    /** LAINAN TIEDOT **/
    formData.loanInfo = {
      loanAmount: getters.loanAmount,
      additionalLoanAmount: state.additionalLoanAmount,
      otherSavings: state.otherSavings,
      desiredLoanTime: state.desiredLoanTime
    }

    if (((!getters.isLoanTargetKnown || !getters.isRefinance) && !getters.isRightOfOccupancy) && !getters.isInvestmentPropertyMortgage && !getters.isVacationHomeMortgage) {
      formData.loanInfo.collaterals = state.collaterals
    } else {
      formData.loanInfo.collaterals = state.collaterals.filter(item => item != 'AKTIA_WARRANTY' && item != 'GOVERNMENT_WARRANTY')
    }

    if (getters.isPurchasePriceApplied) {
      formData.loanInfo.purchasePrice = state.purchasePrice
    } else {
      if (getters.isMortgage || getters.isVacationHomeMortgage || getters.isInvestmentPropertyMortgage) {
        if (getters.isDebtFreePriceIncluded) {
          formData.loanInfo.purchasePrice = state.loanTarget.debtFreePrice
        }
        if (getters.isSellingPriceIncluded) {
          formData.loanInfo.purchasePrice = state.loanTarget.sellingPrice
        }
        if (getters.isRightOfOccupancySellingPriceIncluded) {
          formData.loanInfo.purchasePrice = state.loanTarget.rightOfOccupancySellingPrice
        }
      }
      if (getters.isRefinance) {
        if (getters.isApartment || getters.isRealEstate) {
          formData.loanInfo.purchasePrice = state.loanTarget.originalSellingPrice
        }
        if (getters.isRightOfOccupancy) {
          formData.loanInfo.purchasePrice = state.loanTarget.originalRightOfOccupancyFee
        }
      }
      if (getters.isConstructionMortgage) {
        formData.loanInfo.purchasePrice = state.loanTarget.buildingCostEstimate
      }
    }

    if (getters.isCollateralInfosApplied) {
      formData.loanInfo.collateralInfos = state.collateralInfos
    }

    formData.loanInfo.transferTax = getters.transferTax

    if (getters.isApartmentSalesProfitApplied) {
      formData.loanInfo.apartmentSalesProfit = state.apartmentSalesProfit
    }

    if (getters.isAspSavingsApplied) {
      formData.loanInfo.aspSavings = state.aspSavings
    }

    formData.purchasePriceIncluded = getters.isPurchasePriceIncluded
    formData.sellingPriceIncluded = getters.isDebtFreePriceIncluded || getters.isSellingPriceIncluded
    formData.housingCompanyLoanIncluded = getters.isDebtFreePriceIncluded || getters.isHousingCompanyLoanIncluded
    formData.rightOfOccupancySellingPriceIncluded = getters.isRightOfOccupancySellingPriceIncluded
    formData.currentMortgageAmountIncluded = getters.isCurrentMortgageAmountIncluded
    formData.transferTaxIncluded = getters.isTransferTaxIncluded

    if (getters.isConstructionMortgage) {
      formData.buildingCostEstimateIncluded = getters.isBuildingCostEstimateIncluded
      formData.buildingGroundPriceIncluded = getters.isBuildingGroundPriceIncluded
    }

    /** ENSIMMÄINEN HAKIJA **/
    formData.firstApplicant = this.getApplicantData('firstApplicant')

    /** TOINEN HAKIJA **/
    if (getters.isTwoApplicants) {
      formData.secondApplicant = {
        firstOwner: getters.firstOwner.secondApplicant,
        firstName: state.secondApplicant.firstName,
        lastName: state.secondApplicant.lastName,
        ssn: state.secondApplicant.ssn,
        phoneNumber: state.secondApplicant.phoneNumber,
        email: state.secondApplicant.email,
      }
    }

    formData.household = {
      numberOfAdults: state.numberOfAdults,
      numberOfChildren: state.numberOfChildren,
      numberOfCars: state.numberOfCars,
      expenses: {
        housingExpenses: state.housingExpenses,
        foodExpenses: state.foodExpenses,
        transportationExpenses: state.transportationExpenses,
        clothingExpenses: state.clothingExpenses,
        healthExpenses: state.healthExpenses,
        leisureExpenses: state.leisureExpenses,
        childrenExpenses: state.childrenExpenses,
        insuranceExpenses: state.insuranceExpenses,
        otherExpenses: state.otherExpenses
      }
    }

    if (!getters.isHousingExpensesApplied) {
      formData.household.expenses.housingExpenses = getters.housingExpensesCalculated
    }

    formData.generalTermsAccepted = state.generalTermsAccepted
    formData.branchId = state.branchId

    return formData
  }
}
