<template>
  <div>
    <ul :class="{ 'tab': true, 'tab-block': !!fullWidth }" role="tablist">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ 'tab-item': true, 'active': activeIndex === index }"
      >
        <a
          href="#"
          class="tab-link"
          role="tab"
          :aria-selected="activeIndex === index ? 'true' : ''"
          @click.prevent="change(index)"
        >
          {{ tab.title }}
        </a>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: 0,
      tabs: []
    }
  },
  created() {
    this.tabs = this.$children
  },
  mounted() {
    this.change(0)
  },
  methods: {
    change(index) {
      this.activeIndex = index

      this.tabs.forEach((tab, i) => {
        tab.isActive = (i === index)
      })
    }
  }
}
</script>
