import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'fi',
  fallbackLocale: 'fi',
  messages: {
    fi: require('@/i18n/fi.json'),
    sv: require('@/i18n/sv.json')
  }
})
