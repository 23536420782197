<template>
  <div id="main" class="third pt-12">
    <div class="container container-lg">
      <div class="row">

        <div class="col col-12 col-md-9">
          <form class="page" @submit.prevent="handleSubmit" autocomplete="off">

            <h2 class="h3 heading-underline heading-underline-lg mb-8">{{ $t("thirdStep.loanInfo.title") }}</h2>

            <!-- Rakentamisen kustannusarvio (rakennuslaina) -->
            <div v-if="isConstructionMortgage" class="row gx-4 mb-4">
              <div class="col col-12 col-sm-9">
                <div class="h6 text-weight-medium mt-3 text-sm-right">{{ $t("form.buildingCostEstimate.label") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="h3 text-weight-medium text-sm-right">{{ buildingCostEstimate | currency }}</div>
              </div>
            </div>

            <!-- Nykyinen asuntolaina (lainan kilpailutus) -->
            <div v-if="isRefinance" class="row gx-4 mb-4">
              <div class="col col-12 col-sm-9">
                <div class="h6 text-weight-medium mt-3 text-sm-right">{{ $t("form.currentMortgageAmount.label") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="h3 text-weight-medium text-sm-right">{{ currentMortgageAmount | currency }}</div>
              </div>
            </div>

            <!-- Asunnon hankintahinta (kohde tiedossa, asunto-osake) -->
            <div v-if="isDebtFreePriceIncludedApplied" class="form-group row gx-4">
              <div class="col col-12 col-sm-6 col-form-label">
                <label class="form-label">{{ $t("form.isDebtFreePriceIncluded.label") }}</label>
              </div>
              <div class="col col-12 col-sm-6 text-sm-right">
                <label class="form-button" for="isDebtFreePriceIncluded-true">
                  <input id="isDebtFreePriceIncluded-true" type="radio" name="isDebtFreePriceIncluded" :value="true" v-model="isDebtFreePriceIncluded">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.isDebtFreePriceIncluded.opts.debtFreePrice") }}</span>
                </label>
                <label class="form-button mr-0" for="isDebtFreePriceIncluded-false">
                  <input id="isDebtFreePriceIncluded-false" type="radio" name="isDebtFreePriceIncluded" :value="false" v-model="isDebtFreePriceIncluded">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.isDebtFreePriceIncluded.opts.sellingPrice") }}</span>
                </label>
              </div>
            </div>

            <!-- Asunnon hankintahinta placeholder (kohde tiedossa, kiinteistö) -->
            <div v-if="!isConstructionMortgage && !isRefinance && isLoanTargetKnown && isRealEstate" class="row gx-4 mb-4">
              <div class="col col-12 col-sm-9">
                <div class="h6 text-weight-medium mt-3 text-sm-right">{{ $t("form.purchasePrice.label") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="h3 text-weight-medium text-sm-right">{{ sellingPrice | currency }}</div>
              </div>
            </div>

            <!-- Asunnon hankintahinta placeholder (kohde tiedossa, asumisoikeusasunto) -->
            <div v-if="!isConstructionMortgage && !isRefinance && isLoanTargetKnown && isRightOfOccupancy" class="row gx-4 mb-4">
              <div class="col col-12 col-sm-9">
                <div class="h6 text-weight-medium mt-3 text-sm-right">{{ $t("form.purchasePrice.label") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="h3 text-weight-medium text-sm-right">{{ rightOfOccupancySellingPrice | currency }}</div>
              </div>
            </div>

            <div v-if="isDebtFreePriceIncludedApplied">
              <div class="text-gray text-sm text-sm-right">
                {{
                  $t("thirdStep.loanInfo.debtFreePriceInfo", {
                    debtFreePrice: $options.filters.currency(debtFreePrice),
                    sellingPrice: $options.filters.currency(sellingPrice)
                  })
                }}
              </div>
              <div class="divider my-6"></div>
            </div>

            <!-- Asunnon hankintahinta placeholder (kohde tiedossa, asunto-osake) -->
            <div v-if="isDebtFreePriceIncludedApplied" class="row gx-4 mb-4">
              <div class="col col-12 col-sm-9">
                <div class="h6 text-weight-medium mt-3 text-sm-right">{{ $t("form.purchasePrice.label") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div v-if="isDebtFreePriceIncluded" class="h3 text-weight-medium text-sm-right">{{ debtFreePrice | currency }}</div>
                <div v-else class="h3 text-weight-medium text-sm-right">{{ sellingPrice | currency }}</div>
              </div>
            </div>

            <div v-if="isConstructionMortgage || isHousingCompanyLoanIncludedApplied || isTransferTaxIncludedApplied || isBuildingGroundPriceIncludedApplied" class="row gx-4 mb-4">
              <div class="col col-12 col-sm-5"></div>
              <div class="col col-12 col-sm-7 text-gray text-sm text-sm-right">
                <div v-if="isBuildingGroundPriceIncludedApplied">
                  {{
                    $t("thirdStep.loanInfo.buildingGroundPriceInfo", {
                      buildingGroundPrice: $options.filters.currency(buildingGroundPrice),
                    })
                  }}
                </div>
                <div v-if="!isConstructionMortgage && isTransferTaxIncludedApplied && isApartment">
                  {{
                    $t("thirdStep.loanInfo.transferTaxApartmentInfo", {
                      transferTax: $options.filters.currency(transferTax)
                    })
                  }}
                </div>
                <div v-if="!isConstructionMortgage && isTransferTaxIncludedApplied && isRealEstate">
                  {{
                    $t("thirdStep.loanInfo.transferTaxRealEstateInfo", {
                      transferTax: $options.filters.currency(transferTax)
                    })
                  }}
                </div>
                <div v-if="isRefinance && isHousingCompanyLoanIncludedApplied">
                  {{
                    $t("thirdStep.loanInfo.housingCompanyLoanInfo", {
                      housingCompanyLoan: $options.filters.currency(housingCompanyLoan)
                    })
                  }}
                </div>
                <div v-if="isConstructionMortgage && isTransferTaxIncludedApplied">
                  {{
                    $t("thirdStep.loanInfo.transferTaxBuildingGroundInfo", {
                      transferTax: $options.filters.currency(transferTax)
                    })
                  }}
                </div>
              </div>
            </div>

            <!-- Asunnon hankintahinta (kohde ei tiedossa) -->
            <div v-if="isPurchasePriceApplied" class="form-group row gx-4" :class="{ 'has-error': $v.purchasePrice.$error }">
              <div class="col col-12 col-sm-9 col-form-label text-left">
                <label class="form-label" for="purchasePrice">{{ $t("form.purchasePrice.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input
                    id="purchasePrice"
                    class="form-input"
                    type="text"
                    name="purchasePrice"
                    v-integer
                    v-positive-number
                    v-model.number="purchasePrice"
                    @keyup="$refs.purchasePriceSlider.updateValue($event.target.value)">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.purchasePrice.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.purchasePrice.between">{{ $t("formError.between", { min: $options.filters.currency($v.purchasePrice.$params.between.min, 0 , ''), max: $options.filters.currency($v.purchasePrice.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.purchasePrice.integer">{{ $t("formError.integer") }}</div>
              </div>
              <div class="col col-12 mt-6 mb-12 show-sm">
                <Slider
                  ref="purchasePriceSlider"
                  v-bind:id="'purchasePriceSlider'"
                  v-bind:config="{
                    step: 1000,
                    connect: 'lower',
                    range: {
                      'min': [ 10000 ],
                      '50%': [ 1000000, 1000 ],
                      'max': [ 2000000 ]
                    },
                    format: $wNumb({
                      decimals: 0
                    }),
                    pips: {
                      mode: 'values',
                      values: [ 10000, 500000, 1000000, 1500000, 2000000 ],
                      density: 4,
                      format: {
                        to: function (value) {
                          return $options.filters.currency(value)
                        }
                      }
                    }
                  }"
                  v-bind:values="[ purchasePrice ]"
                  @input="purchasePrice = $event">
                </Slider>
              </div>
            </div>

            <!-- Tarvitsetko lainaa tonttia varten? -->
            <div v-if="isBuildingGroundPriceIncludedApplied" class="form-group row gx-4" :class="{ 'has-error': $v.isBuildingGroundPriceIncluded.$error }">
              <div class="col col-12 col-sm-9 col-form-label">
                <label class="form-label">{{ $t("form.isBuildingGroundPriceIncluded.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3  text-sm-right">
                <label class="form-radio form-inline" for="isBuildingGroundPriceIncluded-true">
                  <input id="isBuildingGroundPriceIncluded-true" type="radio" name="isBuildingGroundPriceIncluded" :value="true" v-model="isBuildingGroundPriceIncluded">
                  <i class="form-icon"></i>{{ $t("form.isBuildingGroundPriceIncluded.opts.yes") }}
                </label>
                <label class="form-radio form-inline" for="isBuildingGroundPriceIncluded-false">
                  <input id="isBuildingGroundPriceIncluded-false" type="radio" name="isBuildingGroundPriceIncluded" :value="false" v-model="isBuildingGroundPriceIncluded">
                  <i class="form-icon"></i>{{ $t("form.isBuildingGroundPriceIncluded.opts.no") }}
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.isBuildingGroundPriceIncluded.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <!-- Tarvitsetko lainaa asunnon yhtiölainaosuutta varten? -->
            <div v-if="isRefinance && isHousingCompanyLoanIncludedApplied" class="form-group row gx-4" :class="{ 'has-error': $v.isHousingCompanyLoanIncluded.$error }">
              <div class="col col-12 col-sm-9 col-form-label">
                <label class="form-label">{{ $t("form.isHousingCompanyLoanIncluded.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3 text-sm-right">
                <label class="form-radio form-inline" for="isHousingCompanyLoanIncluded-true">
                  <input id="isHousingCompanyLoanIncluded-true" type="radio" name="isHousingCompanyLoanIncluded" :value="true" v-model="isHousingCompanyLoanIncluded">
                  <i class="form-icon"></i>{{ $t("form.isHousingCompanyLoanIncluded.opts.yes") }}
                </label>
                <label class="form-radio form-inline" for="isHousingCompanyLoanIncluded-false">
                  <input id="isHousingCompanyLoanIncluded-false" type="radio" name="isHousingCompanyLoanIncluded" :value="false" v-model="isHousingCompanyLoanIncluded">
                  <i class="form-icon"></i>{{ $t("form.isHousingCompanyLoanIncluded.opts.no") }}
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.isHousingCompanyLoanIncluded.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <!-- Tarvitseko lainaa varainsiirtoveroa varten? -->
            <div v-if="isTransferTaxIncludedApplied" class="form-group row gx-4" :class="{ 'has-error': $v.isTransferTaxIncluded.$error }">
              <div class="col col-12 col-sm-9 col-form-label">
                <label class="form-label">{{ $t("form.isTransferTaxIncluded.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3 text-sm-right">
                <label class="form-radio form-inline" for="isTransferTaxIncluded-true">
                  <input id="isTransferTaxIncluded-true" type="radio" name="isTransferTaxIncluded" :value="true" v-model="isTransferTaxIncluded">
                  <i class="form-icon"></i>{{ $t("form.isTransferTaxIncluded.opts.yes") }}
                </label>
                <label class="form-radio form-inline" for="isTransferTaxIncluded-false">
                  <input id="isTransferTaxIncluded-false" type="radio" name="isTransferTaxIncluded" :value="false" v-model="isTransferTaxIncluded">
                  <i class="form-icon"></i>{{ $t("form.isTransferTaxIncluded.opts.no") }}
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.isTransferTaxIncluded.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <!-- Muut kulut -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.additionalLoanAmount.$error }">
              <div class="col col-12 col-sm-9 col-form-label">
                <label class="form-label" for="additionalLoanAmount">{{ $t("form.additionalLoanAmount.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="additionalLoanAmount" class="form-input" type="text" name="additionalLoanAmount" v-model.number="additionalLoanAmount">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.additionalLoanAmount.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.additionalLoanAmount.between">{{ $t("formError.between", { min: $options.filters.currency($v.additionalLoanAmount.$params.between.min, 0 , ''), max: $options.filters.currency($v.additionalLoanAmount.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.additionalLoanAmount.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Edellisestä asunnosta saatava myyntivoitto -->
            <div v-if="isApartmentSalesProfitApplied" class="form-group row gx-4" :class="{ 'has-error': $v.apartmentSalesProfit.$error }">
              <div class="col col-12 col-sm-9 col-form-label">
                <label class="form-label" for="apartmentSalesProfit">{{ $t("form.apartmentSalesProfit.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="apartmentSalesProfit" class="form-input" type="text" name="apartmentSalesProfit" v-model.number="apartmentSalesProfit">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.apartmentSalesProfit.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.apartmentSalesProfit.between">{{ $t("formError.between", { min: $options.filters.currency($v.apartmentSalesProfit.$params.between.min, 0 , ''), max: $options.filters.currency($v.apartmentSalesProfit.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.apartmentSalesProfit.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- ASP-säästöt -->
            <div v-if="isAspSavingsApplied" class="form-group row gx-4" :class="{ 'has-error': $v.aspSavings.$error }">
              <div class="col col-12 col-sm-9 col-form-label">
                <label class="form-label" for="aspSavings">
                  {{ $t("form.aspSavings.label") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.aspSavings.info')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="aspSavings" class="form-input" type="text" name="aspSavings" v-model.number="aspSavings">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.aspSavings.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.aspSavings.between">{{ $t("formError.between", { min: $options.filters.currency($v.aspSavings.$params.between.min, 0 , ''), max: $options.filters.currency($v.aspSavings.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.aspSavings.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Omat säästöt -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.otherSavings.$error }">
              <div class="col col-12 col-sm-9 col-form-label">
                <label class="form-label" for="otherSavings">{{ $t("form.otherSavings.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="otherSavings" class="form-input" type="text" name="otherSavings" v-model.number="otherSavings">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.otherSavings.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.otherSavings.between">{{ $t("formError.between", { min: $options.filters.currency($v.otherSavings.$params.between.min, 0 , ''), max: $options.filters.currency($v.otherSavings.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.otherSavings.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <div class="divider"></div>

            <!-- Haettava lainan määrä -->
            <div class="row gx-4">
              <div class="col col-12 col-sm-9">
                <div class="h6 text-weight-medium mt-3 text-sm-right">{{ $t("form.loanAmount.label") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="h3 text-weight-medium text-sm-right">{{ loanAmount | currency }}</div>
              </div>
              <div class="col" :class="{ 'has-error': $v.loanAmount.$error }">
                <div class="form-input-hint text-sm-right" v-if="submitted && !$v.loanAmount.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint text-sm-right" v-if="submitted && !$v.loanAmount.between">{{ $t("formError.between", { min: $options.filters.currency($v.loanAmount.$params.between.min, 0 , ''), max: $options.filters.currency($v.loanAmount.$params.between.max) }) }}</div>
              </div>
            </div>

            <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-4">{{ $t("thirdStep.loanTime.title") }}</h2>
            <p class="mb-8" v-html="$t('thirdStep.loanTime.text', { value: $n(kLoanInterestDefault, { minimumFractionDigits: 1, maximumFractionDigits: 3 }) })"></p>
            <!-- <p class="mb-8">{{ $t("thirdStep.loanTime.text", { value: 2 }) }}</p> -->

            <!-- Laina-aika -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.desiredLoanTime.$error }">
              <div class="col col-12 col-sm-9 col-form-label">
                <label class="form-label" for="desiredLoanTime">{{ $t("form.desiredLoanTime.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="desiredLoanTime" class="form-input" type="text" name="desiredLoanTime" v-model.number="desiredLoanTime">
                  <span class="form-unit">{{ $t("years") }}</span>
                </div>
                <div class="form-input-hint">{{ $t("formError.between", { min: $v.desiredLoanTime.$params.between.min, max: $options.filters.currency($v.desiredLoanTime.$params.between.max, 0, ` ${ $t("years") }`)}) }}
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.desiredLoanTime.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <div class="divider"></div>

            <div class="row gx-4">
              <div class="col col-12 col-sm-9">
                <div class="h6 text-weight-medium mt-3 text-sm-right">{{ $t("form.loanPayment.label") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="h3 text-weight-medium text-sm-right">{{ loanPayment | currency }}</div>
              </div>
            </div>

            <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-4">{{ $t("thirdStep.collaterals.title") }}</h2>
            <p class="mb-8">{{ $t("thirdStep.collaterals.text") }}</p>

            <!-- Vakuudet -->
            <div class="row gx-4">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label">{{ $t("form.collaterals.label") }}</label>
              </div>
              <div class="col col-12 col-sm-8">
                <div class="form-group" :class="{ 'has-error': $v.collaterals.$error }">
                  <label class="form-button" for="collaterals-currentTarget">
                    <input id="collaterals-currentTarget" type="checkbox" name="collaterals" value="CURRENT_TARGET" v-model="collaterals" @change="resetCollaterals($event)">
                    <span v-if="isRefinance" class="form-button-body"><i class="form-icon"></i>{{ $t("form.collaterals.opts.currentTarget_2") }}</span>
                    <span v-else class="form-button-body"><i class="form-icon"></i>{{ $t("form.collaterals.opts.currentTarget_1") }}</span>
                  </label>
                  <label v-if="((!isLoanTargetKnown || !isRefinance) && !isRightOfOccupancy) && !isInvestmentPropertyMortgage && !isVacationHomeMortgage" class="form-button" for="collaterals-aktiaWarranty">
                    <input id="collaterals-aktiaWarranty" type="checkbox" name="collaterals" value="AKTIA_WARRANTY" v-model="collaterals" @change="resetCollaterals($event)">
                    <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.collaterals.opts.aktiaWarranty") }}</span>
                  </label>
                  <label v-if="((!isLoanTargetKnown || !isRefinance) && !isRightOfOccupancy) && !isInvestmentPropertyMortgage && !isVacationHomeMortgage" class="form-button" for="collaterals-governmentWarranty">
                    <input id="collaterals-governmentWarranty" type="checkbox" name="collaterals" value="GOVERNMENT_WARRANTY" v-model="collaterals" @change="resetCollaterals($event)">
                    <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.collaterals.opts.governmentWarranty") }}</span>
                  </label>
                  <label class="form-button" for="collaterals-otherWealth">
                    <input id="collaterals-otherWealth" type="checkbox" name="collaterals" value="OTHER_WEALTH" v-model="collaterals" @change="resetCollaterals($event)">
                    <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.collaterals.opts.otherWealth") }}</span>
                  </label>
                  <div class="form-input-hint" v-if="submitted && !$v.collaterals.required">{{ $t("formError.required") }}</div>
                </div>
              </div>
            </div>

            <!-- Lisää uusi vakuus -->
            <div class="row" v-if="collaterals.includes('OTHER_WEALTH')">
              <div class="col col-12 col-sm-4 col-form-label pt-0">
                <label class="form-label">{{ $t("form.collateralInfo.label") }}</label>
              </div>
              <div class="col col-12 col-sm-8">
                <div class="bg-gray p-5 s-rounded mb-4" v-for="(item, key) in collateralInfos" :key="key">
                  <!-- Vakuuden tyyppi -->
                  <div class="form-group" :class="{ 'has-error': submitted && $v.collateralInfos.$each[key].type.$invalid }">
                    <label class="form-label" :for="'collateralInfoType-' + key">{{ $t("form.collateralInfoType.label") }}</label>
                    <select :id="'collateralInfoType-' + key" class="form-select" :name="'collateralInfoType-' + key" :value="item.type" @change="setCollateralInfoItemType($event.target.value, key)">
                      <option value="" disabled>{{ $t("select") }}</option>
                      <option value="APARTMENT">{{ $t("form.collateralInfoType.opts.apartment") }}</option>
                      <option value="REAL_ESTATE">{{ $t("form.collateralInfoType.opts.realEstate") }}</option>
                      <option value="VACATION_HOME">{{ $t("form.collateralInfoType.opts.vacationHome") }}</option>
                      <option value="SECURITIES">{{ $t("form.collateralInfoType.opts.securities") }}</option>
                      <option value="FOREST_OR_LAND_AREA">{{ $t("form.collateralInfoType.opts.forestOrLandArea") }}</option>
                      <option value="BANK_SAVINGS">{{ $t("form.collateralInfoType.opts.bankSavings") }}</option>
                    </select>
                    <div class="form-input-hint" v-if="submitted && !$v.collateralInfos.$each[key].type.required">{{ $t("formError.required") }}</div>
                  </div>

                  <!-- Arvio vakuuden arvosta -->
                  <div class="row">
                    <div class="col col-6 form-group" :class="{ 'has-error': submitted && $v.collateralInfos.$each[key].amount.$invalid }">
                      <label class="form-label" :for="'collateralInfoAmount-' + key">{{ $t("form.collateralInfoAmount.label") }}</label>
                      <div class="has-unit-right">
                        <input :id="'collateralInfoAmount-' + key" class="form-input" type="text" :name="'collateralInfoAmount-' + key" :value="item.amount" @input="setCollateralInfoItemAmount($event.target.value, key)">
                        <span class="form-unit">&euro;</span>
                      </div>
                      <div class="form-input-hint" v-if="submitted && !$v.collateralInfos.$each[key].amount.required">{{ $t("formError.required") }}</div>
                      <div class="form-input-hint" v-if="submitted && !$v.collateralInfos.$each[key].amount.between">{{ $t("formError.between", { min: $v.collateralInfos.$each[key].amount.$params.between.min, max: $options.filters.currency($v.collateralInfos.$each[key].amount.$params.between.max) }) }}</div>
                      <div class="form-input-hint" v-if="submitted && !$v.collateralInfos.$each[key].amount.integer">{{ $t("formError.integer") }}</div>
                    </div>
                  </div>

                  <!-- Vakuus on toisen henkilön omaisuutta -->
                  <div class="form-group">
                    <label class="form-checkbox" :for="'collateralInfoOwnedByThirdParty' + key">
                      <input :id="'collateralInfoOwnedByThirdParty' + key" type="checkbox" :name="'collateralInfoOwnedByThirdParty' + key" :checked="item.ownedByThirdParty" @change="setCollateralInfoItemOwnedByThirdParty($event.target.checked, key)">
                      <i class="form-icon"></i>{{ $t("form.collateralInfoOwnedByThirdParty.label") }}
                    </label>
                  </div>

                  <!-- Vakuus on käytössä toisessa lainassa -->
                  <div class="form-group">
                    <label class="form-checkbox" :for="'collateralInfoAssetPledged' + key">
                      <input :id="'collateralInfoAssetPledged' + key" type="checkbox" :name="'collateralInfoAssetPledged' + key" :checked="item.assetPledged" @change="setCollateralInfoItemAssetPledged($event.target.checked, key)">
                      <i class="form-icon"></i>{{ $t("form.collateralInfoAssetPledged.label") }}
                    </label>
                  </div>

                  <!-- Poista -->
                  <div class="text-right">
                    <button :id="'collateralInfoDelete-' + key" class="btn btn-sm btn-outline-primary" @click.prevent="removeCollateralInfoItem(item)">
                      {{ $t("delete") }}
                    </button>
                  </div>
                </div>

                <!-- Lisää uusi -->
                <button id="collateralInfoAddNew" class="btn btn-sm btn-primary mt-2" @click.prevent="addCollateralInfoItem">
                  <Icon class="mr-1" name="add" />{{ $t("addNew") }}
                </button>
              </div>
            </div>

            <div class="divider my-8"></div>

            <h6 class="text-weight-medium">{{ $t("thirdStep.collateralsCoverage.title") }}</h6>
            <p class="text-sm">{{ $t("thirdStep.collateralsCoverage.text") }}</p>

            <Bar
              class="my-4"
              :min="collateralsCoverage.min"
              :max="collateralsCoverage.max"
              :value="collateralsCoverage.value"
              :text="collateralsCoverage.text"
            />

            <div class="row">
              <div class="col col-12 col-sm-9">
                <div class="h6 text-weight-medium mt-3 text-sm-right">{{ $t("thirdStep.collateralsCoverage.missingValue") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="h3 text-weight-medium text-sm-right">{{ collateralsCoverage.missingValue | currency }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12 col-sm-9">
                <div class="text-sm-right">{{ $t("form.loanAmount.label") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="text-sm-right">{{ loanAmount | currency }}</div>
              </div>
            </div>

            <Toast v-if="submitted && $v.$invalid" error class="mt-8">
              <div class="row gx-4">
                <div class="col col-auto">
                  <Icon name="info" />
                </div>
                <div class="col">
                  {{ $t("formError.anyError") }}
                </div>
              </div>
            </Toast>

            <div class="d-flex justify-content-between mt-12">
              <router-link id="thirdStepPrev" v-if="!isLoanTargetKnown && !isConstructionMortgage && !isRefinance" to="/1" class="btn btn-lg btn-prev">
                {{ $t("prev") }}
              </router-link>
              <router-link id="thirdStepPrev" v-else to="/2" class="btn btn-lg btn-prev">
                {{ $t("prev") }}
              </router-link>
              <button id="thirdStepNext" type="submit" class="btn btn-lg btn-primary btn-next">
                {{ $t("next") }}<Icon class="ml-1" name="arrow-forward" />
              </button>
            </div>
          </form>

          <Footer />
        </div>

        <!-- Sidebar -->
        <div class="col col-12 col-md-3">
          <Stepper />
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Bar from '@/components/Bar.vue'
import Slider from '@/components/Slider.vue'
import Stepper from '@/components/Stepper.vue'
import { between, integer, required, requiredIf } from 'vuelidate/lib/validators'
import { CollateralCalculator } from '@/utils/collateral-calculator'
import _ from 'lodash'
import * as common from '@/common'

export default {
  name: 'third-step',
  components: {
    Bar,
    Slider,
    Stepper
  },
  data() {
    return {
      collateralsCoverage: {
        min: 0,
        max: 0,
        missingValue: 0,
        value: 0,
        text: ''
      },
      submitted: false
    }
  },
  computed: {
    isConstructionMortgage() {
      return this.$store.getters.isConstructionMortgage
    },
    isVacationHomeMortgage() {
      return this.$store.getters.isVacationHomeMortgage
    },
    isInvestmentPropertyMortgage() {
      return this.$store.getters.isInvestmentPropertyMortgage
    },
    isRefinance() {
      return this.$store.getters.isRefinance
    },
    isLoanTargetKnown() {
      return this.$store.state.isLoanTargetKnown
    },
    isApartment() {
      return this.$store.getters.isApartment
    },
    isRealEstate() {
      return this.$store.getters.isRealEstate
    },
    isRightOfOccupancy() {
      return this.$store.getters.isRightOfOccupancy
    },
    isPurchasePriceApplied() {
      return this.$store.getters.isPurchasePriceApplied
    },
    isDebtFreePriceIncludedApplied() {
      return this.$store.getters.isDebtFreePriceIncludedApplied
    },
    isHousingCompanyLoanIncludedApplied() {
      return this.$store.getters.isHousingCompanyLoanIncludedApplied
    },
    isTransferTaxIncludedApplied() {
      return this.$store.getters.isTransferTaxIncludedApplied
    },
    isBuildingGroundPriceIncludedApplied() {
      return this.$store.getters.isBuildingGroundPriceIncludedApplied
    },
    isApartmentSalesProfitApplied() {
      return this.$store.getters.isApartmentSalesProfitApplied
    },
    isAspSavingsApplied() {
      return this.$store.getters.isAspSavingsApplied
    },
    isCollateralInfosApplied() {
      return this.$store.getters.isCollateralInfosApplied
    },
    transferTax() {
      return this.$store.getters.transferTax
    },
    loanAmountSavingsPercentage() {
      return this.$store.getters.loanAmountSavingsPercentage
    },
    purchasePrice: {
      get() {
        return this.$store.state.purchasePrice
      },
      set(value) {
        this.$store.commit('setPurchasePrice', value)
      }
    },
    additionalLoanAmount: {
      get() {
        return this.$store.state.additionalLoanAmount
      },
      set(value) {
        this.$store.commit('setAdditionalLoanAmount', value)
      }
    },
    isDebtFreePriceIncluded: {
      get() {
        return this.$store.state.isDebtFreePriceIncluded
      },
      set(value) {
        this.$store.commit('setIsDebtFreePriceIncluded', value)
      }
    },
    isHousingCompanyLoanIncluded: {
      get() {
        return this.$store.state.isHousingCompanyLoanIncluded
      },
      set(value) {
        this.$store.commit('setIsHousingCompanyLoanIncluded', value)
      }
    },
    isBuildingGroundPriceIncluded: {
      get() {
        return this.$store.state.isBuildingGroundPriceIncluded
      },
      set(value) {
        this.$store.commit('setIsBuildingGroundPriceIncluded', value)
      }
    },
    isTransferTaxIncluded: {
      get() {
        return this.$store.state.isTransferTaxIncluded
      },
      set(value) {
        this.$store.commit('setIsTransferTaxIncluded', value)
      }
    },
    apartmentSalesProfit: {
      get() {
        return this.$store.state.apartmentSalesProfit
      },
      set(value) {
        this.$store.commit('setApartmentSalesProfit', value)
      }
    },
    aspSavings: {
      get() {
        return this.$store.state.aspSavings
      },
      set(value) {
        this.$store.commit('setAspSavings', value)
      }
    },
    otherSavings: {
      get() {
        return this.$store.state.otherSavings
      },
      set(value) {
        this.$store.commit('setOtherSavings', value)
      }
    },
    desiredLoanTime: {
      get() {
        return this.$store.state.desiredLoanTime
      },
      set(value) {
        this.$store.commit('setDesiredLoanTime', value)
      }
    },
    collaterals: {
      get() {
        return this.$store.state.collaterals
      },
      set(value) {
        this.$store.commit('setCollaterals', value)
      }
    },
    collateralInfos() {
      return this.$store.state.collateralInfos
    },
    loanTargetType() {
      return this.$store.state.loanTarget.type
    },
    debtFreePrice() {
      return this.$store.state.loanTarget.debtFreePrice
    },
    sellingPrice() {
      return this.$store.state.loanTarget.sellingPrice
    },
    housingCompanyLoan() {
      return this.$store.state.loanTarget.housingCompanyLoan
    },
    rightOfOccupancySellingPrice() {
      return this.$store.state.loanTarget.rightOfOccupancySellingPrice
    },
    buildingCostEstimate() {
      return this.$store.state.loanTarget.buildingCostEstimate
    },
    buildingGroundPrice() {
      return this.$store.state.loanTarget.buildingGroundPrice
    },
    originalSellingPrice() {
      return this.$store.state.loanTarget.originalSellingPrice
    },
    currentMortgageAmount() {
      return this.$store.state.currentMortgage.amount
    },
    loanAmount() {
      return this.$store.getters.loanAmount
    },
    loanPayment() {
      return this.$store.getters.loanPayment
    },
    kLoanInterestDefault() {
      return common.kLoanInterestDefault
    },
  },
  validations() {
    return {
      purchasePrice: {
        required: requiredIf(() => !this.isConstructionMortgage && !this.isRefinance && !this.isLoanTargetKnown),
        ...!this.isConstructionMortgage && !this.isRefinance && !this.isLoanTargetKnown && {
          integer,
          between: between(10000, 2000000)
        }
      },
      additionalLoanAmount: {
        required,
        integer,
        between: between(0, 2000000)
      },
      isDebtFreePriceIncluded: {
        required: requiredIf(() => !this.isConstructionMortgage && !this.isRefinance && this.isLoanTargetKnown && this.isApartment)
      },
      isHousingCompanyLoanIncluded: {
        required: requiredIf(() => this.isHousingCompanyLoanIncludedApplied)
      },
      isTransferTaxIncluded: {
        required: requiredIf(() => this.isTransferTaxIncludedApplied),
      },
      isBuildingGroundPriceIncluded: {
        required: requiredIf(() => this.isBuildingGroundPriceIncludedApplied),
      },
      apartmentSalesProfit: {
        required: requiredIf(() => this.isApartmentSalesProfitApplied),
        ...this.isApartmentSalesProfitApplied && {
          integer,
          between: between(0, 2000000)
        }
      },
      aspSavings: {
        required: requiredIf(() => this.isAspSavingsApplied),
        ...this.isAspSavingsApplied && {
          integer,
          between: between(0, 2000000)
        }
      },
      otherSavings: {
        required,
        integer,
        between: between(0, 2000000)
      },
      loanAmount: {
        required,
        between: between(10000, 5000000)
      },
      desiredLoanTime: {
        required,
        integer,
        between: between(1, 25)
      },
      collaterals: {
        required
      },
      collateralInfos: {
        $each: {
          type: {
            required: requiredIf(() => this.isCollateralInfosApplied)
          },
          amount: {
            required: requiredIf(() => this.isCollateralInfosApplied),
            ...this.isCollateralInfosApplied && {
              integer,
              between: between(1, 2000000)
            }
          }
        }
      }
    }
  },
  created() {
    this.$store.commit('setIsThirdStepSubmitted', false)
    this.calculateCollateralsCoverage()
  },
  watch: {
    'loanAmount'() {
      this.calculateCollateralsCoverage()
    },
    'collaterals'() {
      this.calculateCollateralsCoverage()
    },
    collateralInfos: {
      handler() {
        this.calculateCollateralsCoverage()
      },
      deep: true
    }
  },
  methods: {
    resetCollaterals(event) {
      if (event.target.value === 'OTHER_WEALTH') {
        this.$store.commit('resetCollateralInfos')
      }
    },
    setCollateralInfoItemType(value, key) {
      this.$store.commit('setCollateralInfoItemType', { value, key })
    },
    setCollateralInfoItemAmount(value, key) {
      this.$store.commit('setCollateralInfoItemAmount', { value, key })
    },
    setCollateralInfoItemOwnedByThirdParty(value, key) {
      this.$store.commit('setCollateralInfoItemOwnedByThirdParty', { value, key })
    },
    setCollateralInfoItemAssetPledged(value, key) {
      this.$store.commit('setCollateralInfoItemAssetPledged', { value, key })
    },
    addCollateralInfoItem() {
      this.$store.commit('addCollateralInfoItem')
    },
    removeCollateralInfoItem(item) {
      this.$store.commit('removeCollateralInfoItem', item)
    },
    getLoanTargetPrice() {
      const price = {
        sellingPrice: 0,
        debtFreePrice: 0
      }

      if (!this.isConstructionMortgage && !this.isRefinance && this.isLoanTargetKnown) {
        if (this.isApartment) {
          price.sellingPrice = this.sellingPrice
          price.debtFreePrice = this.debtFreePrice
        }

        if (this.isRealEstate) {
          price.sellingPrice = this.sellingPrice
          price.debtFreePrice = this.sellingPrice
        }

        if (this.isRightOfOccupancy) {
          price.sellingPrice = this.rightOfOccupancySellingPrice
          price.debtFreePrice = this.rightOfOccupancySellingPrice
        }
      }

      if (!this.isConstructionMortgage && !this.isRefinance && !this.isLoanTargetKnown) {
        price.sellingPrice = this.purchasePrice
        price.debtFreePrice = this.purchasePrice
      }

      if (this.isConstructionMortgage) {
        price.sellingPrice = this.buildingCostEstimate
        price.debtFreePrice = this.buildingCostEstimate
      }

      if (this.isRefinance) {
        if (this.isRightOfOccupancy) {
          price.sellingPrice = this.rightOfOccupancySellingPrice
          price.debtFreePrice = this.rightOfOccupancySellingPrice
        } else {
          price.sellingPrice = this.originalSellingPrice
          price.debtFreePrice = this.originalSellingPrice
        }
      }

      return price
    },

    calculateCollateralsCoverage() {
      const loanTargetPrice = this.getLoanTargetPrice()
      const loanTargetType = this.isConstructionMortgage ? 'REAL_ESTATE' : this.loanTargetType
      const isDebtFreePriceIncluded = this.isDebtFreePriceIncluded

      const collateralValues = this.collaterals
        .map(collateral => {
          return CollateralCalculator.calculateCollateralValue(
            loanTargetPrice.sellingPrice,
            loanTargetPrice.debtFreePrice,
            collateral,
            loanTargetType,
            isDebtFreePriceIncluded
          )
        })

      let collateralInfoValues = []

      if (this.isCollateralInfosApplied) {
        collateralInfoValues = this.collateralInfos
          .map(collateralInfo => {
            return CollateralCalculator.calculateCollateralValue(
              collateralInfo.amount,
              collateralInfo.amount,
              collateralInfo.type,
              loanTargetType
            )
          })
      }

      const collateralsTotalValue = _.sum(_.concat(collateralValues, collateralInfoValues))
      const collateralsMissingValue = this.loanAmount - collateralsTotalValue
      const _collateralsCoveragePercentage = collateralsTotalValue / this.loanAmount * 100
      const collateralsCoveragePercentage = _collateralsCoveragePercentage > 100 ? 100 : _collateralsCoveragePercentage
      const _collateralsCoverageText = this.$options.filters.currency(collateralsCoveragePercentage, 0, ' %')
      const collateralsCoverageText = collateralsCoveragePercentage != 0 ? _collateralsCoverageText : ''

      this.collateralsCoverage.min = 0
      this.collateralsCoverage.max = this.loanAmount
      this.collateralsCoverage.value = collateralsTotalValue
      this.collateralsCoverage.missingValue = collateralsMissingValue > 0 ? collateralsMissingValue : 0
      this.collateralsCoverage.text = collateralsCoverageText
    },
    handleSubmit() {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$store.commit('setIsThirdStepSubmitted', true)
        this.$router.push('4-1')
      }
    }
  }
}
</script>
