<template>
  <div class="summary-item-container" :class="containerClassList">
    <dt class="summary-item-label" v-if="label" :class="labelClassList">
      {{ label }}
    </dt>
    <dd class="summary-item-value" :class="valueClassList">
      <slot></slot>
    </dd>
  </div>
</template>

<script>
export default {
  props: {
    lg: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    labelClassList() {
      const size = this.lg ? 'summary-item-label-lg' : ''
      return `${size}`
    },
    valueClassList() {
      const size = this.lg ? 'summary-item-value-lg' : ''
      return `text-nowrap ${size}`
    },
    containerClassList() {
      const size = this.lg ? 'summary-item-container-lg' : ''
      return `${size}`
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/_variables.scss";

.summary-item-container {
  align-items: baseline;
  display: flex;
}

.summary-item-container-lg {
  flex-direction: column;
}

.summary-item-label {
  font-weight: 400;
}

.summary-item-label-lg {
  font-size: $font-size;
}

.summary-item-value {
  font-weight: 500;
  margin-bottom: 0;
  margin-left: auto;
}

.summary-item-value-lg {
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
}
</style>
