var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col col-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"loan-interest-input"}},[_vm._v(" "+_vm._s(_vm.$t('mortgageCalculator.label.interest'))+" ")])]),_c('div',{staticClass:"col col-12"},[_c('NumberInput',{directives:[{name:"text-select",rawName:"v-text-select"},{name:"min-number",rawName:"v-min-number",value:(.1),expression:".1"},{name:"max-number",rawName:"v-max-number",value:(10),expression:"10"}],attrs:{"id":"loan-interest-input","value":_vm.value,"hasError":_vm.hasError,"unitRight":'%',"decimals":2},on:{"input":_vm.onInput}}),_c('FormInputHints',{attrs:{"hasError":_vm.hasError,"rules":_vm.rules}})],1),_c('div',{staticClass:"col col-12 d-none"},[_c('Slider',{ref:"loan-interest-slider",staticClass:"mt-6 mb-8",attrs:{"id":"loan-interest-slider","config":{
        step: .05,
        connect: 'lower',
        range: {
          min: .1,
          max: 10
        },
        format: _vm.$wNumb({ decimals: 2 }),
        pips: {
          mode: 'values',
          values: [ .1, 10 ],
          density: 8,
          format: {
            to: function(val) {
              return _vm.$options.filters.currency(val, 1, ' %')
            }
          }
        }
      },"values":[ _vm.value ]},on:{"input":_vm.onSliderInput}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }