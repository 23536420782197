<template>
  <div>
    <div :id="id"></div>
  </div>
</template>

<script>
export default {
  name: 'slider',
  props: {
    id: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      slider: undefined
    }
  },
  methods: {
    updateValue(value) {
      this.slider.noUiSlider.set(value)
    }
  },
  mounted() {
    this.slider = document.getElementById(this.id)
    this.config.start = this.values
    this.$noUiSlider.create(this.slider, this.config)

    this.slider.noUiSlider.on('slide', (values, handle) => {
      this.$emit('input', values[handle])
    })
  }
}
</script>
