import debounce from 'lodash/debounce'

export default {
  created() {
    this.bouncedResize = debounce(this.handleResize, 200)
  },
  mounted() {
    window.addEventListener('resize', this.bouncedResize)
    this.bouncedResize()
  },
  updated() {
    this.bouncedResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.bouncedResize)
  },
  data() {
    return {
      height: null
    }
  },
  methods: {
    handleResize() {
      const { height } = document
        .getElementsByTagName('html')[0]
        .getBoundingClientRect()

      // No need to send the height if it stays the same
      if (this.height === height) return

      this.height = height
      this.sendSetHeightToParent(height)
    },
    sendSetHeightToParent(height) {
      window.parent.postMessage(['setHeight', height], '*')
    }
  }
}
