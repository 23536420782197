<template>
  <div id="main" class="first">

    <div class="hero overlay text-white">
      <div class="container container-md">
        <div class="col-12 col-sm-9">
          <h1 class="hero-title heading heading-xl mb-4" v-html="$t('firstStep.hero.title')"></h1>
          <p class="hero-text text-xl">{{ $t("firstStep.hero.text") }}</p>
        </div>
      </div>
    </div>

    <div class="container container-md">
      <form class="page" @submit.prevent="handleSubmit" autocomplete="off">

        <!-- Mihin tarkoitukseen haet lainaa? -->
        <fieldset class="form-group" :class="{ 'has-error': $v.mortgageType.$error }" role="group" aria-labelledby="mortgageTypeLabel">
          <h2 id="mortgageTypeLabel" class="h3 heading-underline heading-underline-lg mb-6">{{ $t("form.mortgageType.label") }}</h2>

          <div class="row g-4">
            <!-- Oma asunto -->
            <label class="form-card col col-6 col-sm-4 col-md-3" for="mortgageType-mortgage">
              <input id="mortgageType-mortgage" type="radio" name="mortgageType" value="MORTGAGE" v-model="mortgageType">
              <span class="form-card-body">
                <i class="form-icon"></i>
                <span class="form-card-image">
                  <img src="@/assets/img/oma_asunto.png" :alt="$t('form.mortgageType.opts.mortgage')" />
                </span>
                <span class="form-card-text">{{ $t("form.mortgageType.opts.mortgage") }}</span>
              </span>
            </label>

            <!-- Vapaa-ajan asunto -->
            <label class="form-card col col-6 col-sm-4 col-md-3" for="mortgageType-vacationHomeMortgage">
              <input id="mortgageType-vacationHomeMortgage" type="radio" name="mortgageType" value="VACATION_HOME_LOAN" v-model="mortgageType">
              <span class="form-card-body">
                <i class="form-icon"></i>
                <span class="form-card-image">
                  <img src="@/assets/img/vapaa-ajan_asunto.png" :alt="$t('form.mortgageType.opts.vacationHomeMortgage')" />
                </span>
                <span class="form-card-text">{{ $t("form.mortgageType.opts.vacationHomeMortgage") }}</span>
              </span>
            </label>

            <!-- Sijoitusasunto -->
            <label class="form-card col col-6 col-sm-4 col-md-3" for="mortgageType-investmentPropertyMortgage">
              <input id="mortgageType-investmentPropertyMortgage" type="radio" name="mortgageType" value="INVESTMENT_PROPERTY_LOAN" v-model="mortgageType">
              <span class="form-card-body">
                <i class="form-icon"></i>
                <span class="form-card-image">
                  <img src="@/assets/img/sijoitusasunto.png" :alt="$t('form.mortgageType.opts.investmentPropertyMortgage')" />
                </span>
                <span class="form-card-text">{{ $t("form.mortgageType.opts.investmentPropertyMortgage") }}</span>
              </span>
            </label>

            <!-- Rakennuslaina -->
            <label class="form-card col col-6 col-sm-4 col-md-3" for="mortgageType-constructionMortgage">
              <input id="mortgageType-constructionMortgage" type="radio" name="mortgageType" value="CONSTRUCTION_LOAN" v-model="mortgageType">
              <span class="form-card-body">
                <i class="form-icon"></i>
                <span class="form-card-image">
                  <img src="@/assets/img/rakennuslaina.png" :alt="$t('form.mortgageType.opts.constructionMortgage')" />
                </span>
                <span class="form-card-text">{{ $t("form.mortgageType.opts.constructionMortgage") }}</span>
              </span>
            </label>

            <!-- Lainan kilpailutus -->
            <label class="form-card col col-6 col-sm-4 col-md-3" for="mortgageType-refinance">
              <input id="mortgageType-refinance" type="radio" name="mortgageType" value="REFINANCE" v-model="mortgageType">
              <span class="form-card-body">
                <i class="form-icon"></i>
                <span class="form-card-image">
                  <img src="@/assets/img/lainan_kilpailutus.png" alt="Lainan kilpailutus" />
                </span>
                <span class="form-card-text">{{ $t("form.mortgageType.opts.refinance") }}</span>
              </span>
            </label>
          </div>
          <div class="form-input-hint" v-if="submitted && !$v.mortgageType.required">{{ $t("formError.required") }}</div>
        </fieldset>

        <div>
          <span v-html="$t('firstStep.mortgageType.text')"></span>
          <Icon class="ml-1" name="launch" />
        </div>

        <fieldset v-if="isLoanTargetKnownApplied" class="form-group" :class="{ 'has-error': $v.isLoanTargetKnown.$error }">
          <!-- Onko ostettava asunto tiedossa? -->
          <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-6">{{ $t("form.isLoanTargetKnown.label") }}</h2>

          <label class="form-button" for="isLoanTargetKnown-true">
            <input id="isLoanTargetKnown-true" type="radio" name="isLoanTargetKnown" :value="true" v-model="isLoanTargetKnown">
            <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.isLoanTargetKnown.opts.yes") }}</span>
          </label>
          <label class="form-button" for="isLoanTargetKnown-false">
            <input id="isLoanTargetKnown-false" type="radio" name="isLoanTargetKnown" :value="false" v-model="isLoanTargetKnown">
            <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.isLoanTargetKnown.opts.no") }}</span>
          </label>
          <div class="form-input-hint" v-if="submitted && !$v.isLoanTargetKnown.required">{{ $t("formError.required") }}</div>
        </fieldset>

        <fieldset class="form-group" :class="{ 'has-error': $v.numberOfApplicants.$error }">
          <!-- Haetko lainaa yksin vai yhdessä toisen henkilön kanssa? -->
          <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-6">{{ $t("form.numberOfApplicants.label") }}</h2>

          <div class="row g-4">
            <label class="form-card col col-6 col-sm-4 col-md-3" for="numberOfApplicants-1">
              <input id="numberOfApplicants-1" type="radio" name="numberOfApplicants" value="1" v-model.number="numberOfApplicants">
              <span class="form-card-body">
                <i class="form-icon"></i>
                <span class="form-card-image">
                  <img src="@/assets/img/one-applicant.png" :alt="$t('form.numberOfApplicants.opts.one')" />
                </span>
                <span class="form-card-text">{{ $t("form.numberOfApplicants.opts.one") }}</span>
              </span>
            </label>
            <label class="form-card col col-6 col-sm-4 col-md-3" for="numberOfApplicants-2">
              <input id="numberOfApplicants-2" type="radio" name="numberOfApplicants" value="2" v-model.number="numberOfApplicants">
              <span class="form-card-body">
                <i class="form-icon"></i>
                <span class="form-card-image">
                  <img src="@/assets/img/two-applicants.png" :alt="$t('form.numberOfApplicants.opts.two')" />
                </span>
                <span class="form-card-text">{{ $t("form.numberOfApplicants.opts.two") }}</span>
              </span>
            </label>
          </div>
          <div class="form-input-hint" v-if="submitted && !$v.numberOfApplicants.required">{{ $t("formError.required") }}</div>
        </fieldset>

        <!-- Oletko ensiasunnon ostaja? -->
        <fieldset v-if="!isVacationHomeMortgage && !isInvestmentPropertyMortgage && !isTwoApplicants" class="form-group" :class="{ 'has-error': $v.firstOwnerOneApplicant.$error }">
          <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-4">{{ $t("form.firstOwnerOneApplicant.label") }}</h2>

          <label class="form-button" for="firstOwnerOneApplicant-true">
            <input id="firstOwnerOneApplicant-true" type="radio" name="firstOwnerOneApplicant" :value="true" v-model="firstOwnerOneApplicant">
            <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.firstOwnerOneApplicant.opts.yesIAm") }}</span>
          </label>
          <label class="form-button" for="firstOwnerOneApplicant-false">
            <input id="firstOwnerOneApplicant-false" type="radio" name="firstOwnerOneApplicant" :value="false" v-model="firstOwnerOneApplicant">
            <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.firstOwnerOneApplicant.opts.noIAmNot") }}</span>
          </label>
          <div class="form-input-hint" v-if="submitted && !$v.firstOwnerOneApplicant.required">{{ $t("formError.required") }}</div>
        </fieldset>

        <!-- Oletko ensiasunnon ostaja? -->
        <fieldset v-show="!isVacationHomeMortgage && !isInvestmentPropertyMortgage && isTwoApplicants" class="form-group" :class="{ 'has-error': $v.firstOwnerTwoApplicants.$error }">
          <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-4">{{ $t("form.firstOwnerTwoApplicants.label") }}</h2>

          <label class="form-button" for="firstOwnerTwoApplicants-both">
            <input id="firstOwnerTwoApplicants-both" type="radio" name="firstOwnerTwoApplicants" value="BOTH" v-model="firstOwnerTwoApplicants">
            <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.firstOwnerTwoApplicants.opts.both") }}</span>
          </label>
          <label class="form-button" for="firstOwnerTwoApplicants-other">
            <input id="firstOwnerTwoApplicants-other" type="radio" name="firstOwnerTwoApplicants" value="OTHER" v-model="firstOwnerTwoApplicants">
            <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.firstOwnerTwoApplicants.opts.other") }}</span>
          </label>
          <label class="form-button" for="firstOwnerTwoApplicants-neither">
            <input id="firstOwnerTwoApplicants-neither" type="radio" name="firstOwnerTwoApplicants" value="NEITHER" v-model="firstOwnerTwoApplicants">
            <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.firstOwnerTwoApplicants.opts.neither") }}</span>
          </label>
          <div class="form-input-hint" v-if="submitted && !$v.firstOwnerTwoApplicants.required">{{ $t("formError.required") }}</div>
        </fieldset>

        <Toast v-if="submitted && $v.$invalid" error class="mt-8">
          <div class="row gx-4">
            <div class="col col-auto">
              <Icon name="info" />
            </div>
            <div class="col">
              {{ $t("formError.anyError") }}
            </div>
          </div>
        </Toast>

        <div v-if="isAuthenticated" class="text-center mt-12">
          <button id="firstStepNext" type="submit" class="btn btn-lg btn-primary btn-next">
            {{ $t("next") }}<Icon class="ml-1" name="arrow-forward" />
          </button>
        </div>

        <div v-else class="text-center mt-12">
          <button @click="handleSubmitAndLogin" id="firstStepNextAuth" type="button" class="btn btn-lg btn-primary btn-next">
            {{ $t("auth.loginAndContinue") }}<Icon class="ml-1" name="arrow-forward" />
          </button>
        </div>
      </form>

      <Footer />
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'first-step',
  mounted() {
    const s = JSON.parse(sessionStorage.getItem("store"))
    const laina10Url = `${process.env.VUE_APP_FORMS_BASE_URL}/laina10?lang=${s.lang ?? "fi"}`
    window.location.replace(laina10Url + (s.campaignId ? `&cid=${s.campaignId}` : ""))
  },
  data() {
    return {
      submitted: false
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.isFirstApplicantAuthenticated
    },
    isTwoApplicants() {
      return this.$store.getters.isTwoApplicants
    },
    isVacationHomeMortgage() {
      return this.$store.getters.isVacationHomeMortgage
    },
    isInvestmentPropertyMortgage() {
      return this.$store.getters.isInvestmentPropertyMortgage
    },
    isConstructionMortgage() {
      return this.$store.getters.isConstructionMortgage
    },
    isRefinance() {
      return this.$store.getters.isRefinance
    },
    isLoanTargetKnownApplied() {
      return this.$store.getters.isLoanTargetKnownApplied
    },
    mortgageType: {
      get() {
        return this.$store.state.mortgageType
      },
      set(value) {
        this.$store.commit('setMortgageType', value)
      }
    },
    isLoanTargetKnown: {
      get() {
        return this.$store.state.isLoanTargetKnown
      },
      set(value) {
        this.$store.commit('setIsLoanTargetKnown', value)
      }
    },
    numberOfApplicants: {
      get() {
        return this.$store.state.numberOfApplicants
      },
      set(value) {
        this.$store.commit('setNumberOfApplicants', value)
      }
    },
    firstOwnerOneApplicant: {
      get() {
        return this.$store.state.firstOwnerOneApplicant
      },
      set(value) {
        this.$store.commit('setFirstOwnerOneApplicant', value)
      }
    },
    firstOwnerTwoApplicants: {
      get() {
        return this.$store.state.firstOwnerTwoApplicants
      },
      set(value) {
        this.$store.commit('setFirstOwnerTwoApplicants', value)
      }
    }
  },
  validations() {
    return {
      mortgageType: {
        required
      },
      isLoanTargetKnown: {
        required: requiredIf(() => this.isLoanTargetKnownApplied)
      },
      numberOfApplicants: {
        required
      },
      firstOwnerOneApplicant: {
        required: requiredIf(() => !this.isVacationHomeMortgage && !this.isInvestmentPropertyMortgage && !this.isTwoApplicants)
      },
      firstOwnerTwoApplicants: {
        required: requiredIf(() => !this.isVacationHomeMortgage && !this.isInvestmentPropertyMortgage && this.isTwoApplicants)
      }
    }
  },
  created() {
    this.$store.commit('setIsFirstStepSubmitted', false)
  },
  methods: {
    handleSubmitAndLogin() {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$store.commit('setIsFirstStepSubmitted', true)

        if (this.isLoanTargetKnownApplied && !this.isLoanTargetKnown) {
          this.$router.push({ name: 'login', query: { nextPath: '/3', reason: 'AUTHENTICATION' }})
        } else {
          this.$router.push({ name: 'login', query: { nextPath: '/2', reason: 'AUTHENTICATION' }})
        }
      }
    },
    handleSubmit() {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$store.commit('setIsFirstStepSubmitted', true)

        if (this.isLoanTargetKnownApplied && !this.isLoanTargetKnown) {
          this.$router.push({ name: 'thirdStep' })
        } else {
          this.$router.push({ name: 'secondStep' })
        }
      }
    }
  }
}
</script>
