<template>
  <div id="main" class="maintenance">
    <div class="hero bg-gray">
      <div class="container container-lg">
        <div class="col-12 col-sm-10 col-md-8 col-mx-auto text-center">
          <div class="hero-title heading heading-lg">
            <Icon name="settings" />
          </div>
          <h1 class="hero-title heading heading-lg mb-4">{{ $t("maintenance.title") }}</h1>
          <p class="hero-text text-xl">{{ $t("maintenance.text") }}</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: 'maintenance-view'
}
</script>
