<template>
  <div id="main" class="summary pt-12">
    <div class="container container-md">
      <form class="page" @submit.prevent="handleSubmit" autocomplete="off">
        <h1 class="hero-title heading heading-lg text-center mb-4">{{ $t("summary.hero.title") }}</h1>
        <p class="hero-text text-xl text-center mb-12">{{ $t("summary.hero.text") }}</p>
        <div class="col-12 col-sm-10 col-md-7 col-mx-auto">
          <div class="summary-item">
            <router-link class="summary-item-edit" to="/1">{{ $t("edit") }}</router-link>
            <div class="summary-item-icon"></div>
            <div class="summary-item-body">
              <div class="summary-item-title">{{ $t("firstStep.title") }}</div>
              <div v-if="isMortgage">{{ $t("form.mortgageType.label") }} {{ $t("form.mortgageType.opts.mortgage") }}</div>
              <div v-if="isVacationHomeMortgage">{{ $t("form.mortgageType.label") }} {{ $t("form.mortgageType.opts.vacationHomeMortgage") }}</div>
              <div v-if="isInvestmentPropertyMortgage">{{ $t("form.mortgageType.label") }} {{ $t("form.mortgageType.opts.investmentPropertyMortgage") }}</div>
              <div v-if="isConstructionMortgage">{{ $t("form.mortgageType.label") }} {{ $t("form.mortgageType.opts.constructionMortgage") }}</div>
              <div v-if="isRefinance">{{ $t("form.mortgageType.label") }} {{ $t("form.mortgageType.opts.refinance") }}</div>
            </div>
          </div>

          <div v-if="isLoanTargetKnown || isConstructionMortgage || isRefinance" class="summary-item">
            <router-link class="summary-item-edit" to="/2">{{ $t("edit") }}</router-link>
            <div class="summary-item-icon"></div>
            <div class="summary-item-body">
              <div class="summary-item-title">{{ $t("secondStep.title") }}</div>
              <div v-if="isApartment && !isConstructionMortgage">{{ $t("form.loanTargetType.opts.apartment") }}</div>
              <div v-if="isRealEstate || isConstructionMortgage">{{ $t("form.loanTargetType.opts.realEstate") }}</div>
              <div v-if="isRightOfOccupancy && !isConstructionMortgage">{{ $t("form.loanTargetType.opts.rightOfOccupancy") }}</div>
              <div v-if="streetAddress && zip && city">{{ streetAddress }}, {{ zip }} {{ city }}</div>
            </div>
          </div>

          <div class="summary-item">
            <router-link class="summary-item-edit" to="/3">{{ $t("edit") }}</router-link>
            <div class="summary-item-icon"></div>
            <div class="summary-item-body">
              <div class="summary-item-title">{{ $t("thirdStep.title") }}</div>
              <div>{{ $t("form.loanAmount.label") }} {{ loanAmount | currency }}</div>
              <div>{{ $t("form.desiredLoanTime.label") }} {{ desiredLoanTime }} {{ $t("years") }}</div>
            </div>
          </div>

          <div class="summary-item">
            <router-link class="summary-item-edit" to="/4-1">{{ $t("edit") }}</router-link>
            <div class="summary-item-icon"></div>
            <div class="summary-item-body">
              <div class="summary-item-title">{{ $t("fourthStep.title") }}</div>
              <div>{{ firstApplicantName }}, {{ firstApplicantSsn }}</div>
              <div v-if="isTwoApplicants">{{ secondApplicantName }}, {{ secondApplicantSsn }}</div>
            </div>
          </div>

          <div class="summary-item">
            <router-link class="summary-item-edit" to="/5">{{ $t("edit") }}</router-link>
            <div class="summary-item-icon"></div>
            <div class="summary-item-body">
              <div class="summary-item-title">{{ $t("fifthStep.title") }}</div>
              <div>{{ $t("summaryStep.summary.text_1", { value: $options.filters.currency(totalExpenses, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
            </div>
          </div>
        </div>

        <div class="bg-gray p-7 s-rounded mt-8">
          <h2 class="h3 text-center mb-8">Hyväksy ehdot</h2>

          <div class="col-12 col-sm-10 col-md-7 col-mx-auto">
            <div class="form-group mb-8" :class="{ 'has-error': $v.branchId.$error }">
              <label for="branchId" class="form-label">{{ $t("form.branchId.label") }}</label>
              <select id="branchId" class="form-select" name="branchId" v-model="branchId">
                <option value="" disabled>{{ $t("select") }}</option>
                <option v-for="branch in branches" :key="branch.id" :value="branch.id">
                  <span v-if="$i18n.locale === 'fi'">{{ branch.nameFi }}</span>
                  <span v-if="$i18n.locale === 'sv'">{{ branch.nameSv }}</span>
                </option>
              </select>
              <div class="form-input-hint" v-if="submitted && !$v.branchId.required">{{ $t("formError.required") }}</div>
            </div>

            <label v-if="showDirectMarketing" class="form-checkbox" for="directMarketingAllowed">
              <input id="directMarketingAllowed" type="checkbox" name="directMarketingAllowed" v-model="directMarketingAllowed" :true-value="true" :false-value="false">
              <i class="form-icon"></i><span v-html="$t('form.directMarketingAllowed.label')"></span>
            </label>

            <label v-if="showAccountInformationUseAllowed" class="form-checkbox" for="accountInformationUseAllowed">
              <input id="accountInformationUseAllowed" type="checkbox" name="accountInformationUseAllowed" v-model="accountInformationUseAllowed" :true-value="true" :false-value="false">
              <i class="form-icon"></i><span v-html="$t('form.accountInformationUseAllowed.label')"></span>
            </label>

            <div class="form-group" :class="{ 'has-error': $v.generalTermsAccepted.$error }">
              <label class="form-checkbox" for="generalTermsAccepted">
                <input id="generalTermsAccepted" type="checkbox" name="generalTermsAccepted" v-model="generalTermsAccepted" :true-value="true" :false-value="false">
                <i class="form-icon"></i><span v-html="$t('form.generalTermsAccepted.label')"></span>
              </label>
              <div class="form-input-hint" v-if="submitted && !$v.generalTermsAccepted.required">{{ $t("formError.required") }}</div>
            </div>

            <Toast v-if="isSubmitError" error class="mt-8">
              <div class="row gx-4">
                <div class="col col-auto">
                  <Icon name="info" />
                </div>
                <div class="col">
                  {{ $t("formError.submitError") }}
                </div>
              </div>
            </Toast>

            <div class="text-center mt-8 mb-4">
              <button type="submit" id="summaryNext" class="btn btn-lg btn-primary btn-next" :class="{ 'loading': isSubmitting }">{{ $t("submit") }}</button>
            </div>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  </div>
</template>

<script>
import { Api } from '@/api'
import Form from '@/form'
import { required } from 'vuelidate/lib/validators'
import _ from 'lodash'

export default {
  name: 'summary-step',
  data() {
    return {
      isSubmitError: false,
      submitted: false,
      showDirectMarketing: false,
      showAccountInformationUseAllowed: false
    }
  },
  computed: {
    isSubmitting () {
      return this.$store.state.isSubmitting
    },
    isTwoApplicants() {
      return this.$store.getters.isTwoApplicants
    },
    isMortgage() {
      return this.$store.getters.isMortgage
    },
    isVacationHomeMortgage() {
      return this.$store.getters.isVacationHomeMortgage
    },
    isInvestmentPropertyMortgage() {
      return this.$store.getters.isInvestmentPropertyMortgage
    },
    isConstructionMortgage() {
      return this.$store.getters.isConstructionMortgage
    },
    isRefinance() {
      return this.$store.getters.isRefinance
    },
    isLoanTargetKnown() {
      return this.$store.state.isLoanTargetKnown
    },
    isApartment() {
      return this.$store.getters.isApartment
    },
    isRealEstate() {
      return this.$store.getters.isRealEstate
    },
    isRightOfOccupancy() {
      return this.$store.getters.isRightOfOccupancy
    },
    mortgageType() {
      return this.$store.state.mortgageType
    },
    streetAddress() {
      return this.$store.state.loanTarget.address.streetAddress
    },
    zip() {
      return this.$store.state.loanTarget.address.zip
    },
    city() {
      return this.$store.state.loanTarget.address.city
    },
    loanAmount() {
      return this.$store.getters.loanAmount
    },
    desiredLoanTime() {
      return this.$store.state.desiredLoanTime
    },
    firstApplicantName() {
      return `${this.$store.state.firstApplicant.firstName} ${this.$store.state.firstApplicant.lastName}`
    },
    firstApplicantPhoneNumber() {
      return this.$store.state.firstApplicant.phoneNumber
    },
    firstApplicantSsn() {
      return this.$store.state.firstApplicant.ssn
    },
    secondApplicantName() {
      return `${this.$store.state.secondApplicant.firstName} ${this.$store.state.secondApplicant.lastName}`
    },
    secondApplicantSsn() {
      return this.$store.state.secondApplicant.ssn
    },
    totalExpenses() {
      return this.$store.getters.totalExpenses
    },
    branches() {
      if (this.$i18n.locale === 'sv') {
        return _.orderBy(this.$store.state.branches, ['nameSv'], ['asc'])
      } else {
        return _.orderBy(this.$store.state.branches, ['nameFi'], ['asc'])
      }
    },
    branchId: {
      get() {
        return this.$store.state.branchId
      },
      set(value) {
        this.$store.commit('setBranchId', value)
      }
    },
    generalTermsAccepted: {
      get() {
        return this.$store.state.firstApplicant.generalTermsAccepted
      },
      set(value) {
        this.$store.commit('setGeneralTermsAccepted', { value, applicant: 'firstApplicant' })
      }
    },
    directMarketingAllowed: {
      get() {
        return this.$store.state.firstApplicant.consents['ELECTRONIC_DIRECT_MARKETING_ALLOWED']
      },
      set(value) {
        const key = 'ELECTRONIC_DIRECT_MARKETING_ALLOWED'
        this.$store.commit('setConsentItem', { value, key, applicant: 'firstApplicant' })
      }
    },
    accountInformationUseAllowed: {
      get() {
        return this.$store.state.firstApplicant.consents['ACCOUNT_INFORMATION_USE_ALLOWED']
      },
      set(value) {
        const key = 'ACCOUNT_INFORMATION_USE_ALLOWED'
        this.$store.commit('setConsentItem', { value, key, applicant: 'firstApplicant' })
      }
    }
  },
  validations: {
    branchId: {
      required
    },
    generalTermsAccepted: {
      required: value => value
    }
  },
  created() {
    this.$store.commit('setIsSubmitting', false)
    this.$store.commit('setIsSummaryStepSubmitted', false)
    Api.getConsents().then(({ data }) => {
      const value  = data.consents
      this.$store.commit('setConsents', {value, applicant: 'firstApplicant' })
      if (data.consents['ELECTRONIC_DIRECT_MARKETING_ALLOWED'] !== undefined) {
        this.showDirectMarketing = true
      }
      if (data.consents['ACCOUNT_INFORMATION_USE_ALLOWED'] !== undefined) {
        this.showAccountInformationUseAllowed = true
      }
    })
  },
  methods: {
    handleSubmit() {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        const formData = Form.getFormData()

        this.isSubmitError = false
        this.$store.commit('setIsSubmitting', true)
        this.$store.commit('setIsSummaryStepSubmitted', true)

        Api.submitApplication(formData, this.mortgageType)
          .then(() => {
            if (this.isTwoApplicants) {
              this.$router.push({ name: 'thanksInitial', query: { lang: this.$i18n.locale } })
            } else {
              this.$router.push({ name: 'thanksFinal', query: { lang: this.$i18n.locale } })
            }
          })
          .catch((err) => {
            if (err && err.response && err.response.status === 401) {
              this.$store.commit('resetStore')
              this.$router.push({ name: 'login' })
            }

            this.isSubmitError = true
          })
          .finally(() => {
            this.$store.commit('setIsSubmitting', false)
          })
      }
    }
  }
}
</script>
