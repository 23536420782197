<template>
  <div id="main" class="error">
    <div class="hero bg-light">
      <div class="container container-lg text-center">
        <div class="col-12 col-sm-10 col-md-8 col-mx-auto">
          <h1 class="hero-title heading heading-lg mb-4">{{ $t("error.title") }}</h1>
          <p class="hero-text text-xl mb-8">{{ $t("error.text") }}</p>
          <router-link id="errorGoHome" to="/1" class="btn btn-primary">
            {{ $t("goHome") }}
          </router-link>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: 'error-view'
}
</script>
