var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col col-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"loan-payment-input"}},[_vm._v(" "+_vm._s(_vm.$t('mortgageCalculator.label.monthlyInstallment'))+" ")])]),_c('div',{staticClass:"col col-12"},[_c('NumberInput',{directives:[{name:"text-select",rawName:"v-text-select"},{name:"min-number",rawName:"v-min-number",value:(100),expression:"100"},{name:"max-number",rawName:"v-max-number",value:(10000),expression:"10000"}],attrs:{"id":"loan-payment-input","value":_vm.value,"hasError":_vm.hasError,"unitRight":_vm.$t('eurosPerMonth')},on:{"input":_vm.onInput}}),_c('FormInputHints',{attrs:{"hasError":_vm.hasError,"rules":_vm.rules}})],1),_c('div',{staticClass:"col col-12 d-none"},[_c('Slider',{ref:"loan-payment-slider",staticClass:"mt-6 mb-8",attrs:{"id":"loan-payment-slider","config":{
        step: 100,
        connect: 'lower',
        range: {
          min: 100,
          max: 10000
        },
        format: _vm.$wNumb({ decimals: 0 }),
        pips: {
          mode: 'values',
          values: [ 10, 10000 ],
          density: 8,
          format: {
            to: function(val) {
              return _vm.$options.filters.currency(val)
            }
          }
        }
      },"values":[ _vm.value ]},on:{"input":_vm.onSliderInput}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }