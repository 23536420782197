<template>
  <div class="form-group row">
    <div class="col col-12">
      <label :for="`loan-amount-input`" class="form-label">
        {{ $t('mortgageCalculator.label.loanAmount') }}
      </label>
    </div>

    <div class="col col-12">
      <NumberInput
        :id="`loan-amount-input`"
        :value="value"
        :hasError="hasError"
        :unitRight="'€'"
        @input="onInput"
        v-text-select
        v-min-number="10000"
        v-max-number="2000000"
      />

      <FormInputHints :hasError="hasError" :rules="rules"></FormInputHints>
    </div>

    <div class="col col-12 d-none">
      <Slider
        class="mt-6 mb-8"
        :ref="`loan-amount-slider`"
        :id="`loan-amount-slider`"
        :config="{
          step: 1000,
          connect: 'lower',
          range: {
            'min': [ 10000 ],
            '25%': [ 150000, 1000 ],
            '50%': [ 500000, 1000 ],
            '75%': [ 1000000, 1000 ],
            'max': [ 2000000 ]
          },
          format: $wNumb({ decimals: 0 }),
          pips: {
            mode: 'values',
            values: [ 10000, 2000000 ],
            density: 8,
            format: {
              to: function(val) {
                return $options.filters.currency(val)
              }
            }
          }
        }"
        :values="[ value ]"
        @input="onSliderInput"
      />
    </div>
  </div>
</template>

<script>
import FormInputHints from '@/components/Forms/FormInputHints.vue'
import NumberInput from '@/components/Forms/NumberInput.vue'
import Slider from '@/components/Slider.vue'

export default {
  components: {
    FormInputHints,
    NumberInput,
    Slider
  },
  props: {
    value: {
      type: [ Number, String ],
      default: 0
    },
    rules: {
      type: Array,
      default: () => []
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(val) {
      this.$refs[`loan-amount-slider`].updateValue(val)
      this.$emit('input', val)
    },
    onSliderInput(val) {
      this.$emit('input', val)
    }
  }
}
</script>
