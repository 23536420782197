import Vue from 'vue'
import { Utils } from '@/utils/utils'

// Currency filter
Vue.filter('currency', (value, decimals = 0, suffix = ' €') => {
  if (Utils.isEmpty(value)) return
  const trimmedValue = value.toString().trim().split(' ').join('').replace(',', '.')
  const numberValue = Utils.toNumber(trimmedValue)
  const currencyFormat = window.wNumb({ decimals: decimals, suffix: suffix, mark: ',', thousand: ' ' })
  return Utils.isFinite(numberValue) ? currencyFormat.to(numberValue) : ''
})
