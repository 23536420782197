<template>
<div id="calculator">
  <div class="container">
    <h1 class="mb-4">{{ $t("mortgageCalculator.title") }}</h1>

    <Tabs>
      <Tab :title="$t('mortgageCalculator.tab.loanPayment')">
        <div class="row mt-8">
          <div class="col col-12 col-sm-5">
            <LoanAmountSlider
              class="mb-5"
              v-model.number="$v.loanAmount.$model"
              :hasError="$v.loanAmount.$error"
              :rules="loanAmountRules"
            />

            <LoanTimeSlider
              class="mb-5"
              v-model.number="$v.loanTime.$model"
              :hasError="$v.loanTime.$error"
              :rules="loanTimeRules"
            />

            <LoanInterestSlider
              v-model.number="$v.loanInterest.$model"
              :hasError="$v.loanInterest.$error"
              :rules="loanInterestRules"
            />
          </div>

          <div class="col col-12 col-sm-7">
            <Summary
              :calculationType="'CALCULATE_LOAN_PAYMENT'"
              :loanAmount="loanAmount"
              :loanPayment="loanPayment"
              :loanTime="loanTime"
              :loanInterest="loanInterest"
              :numberOfPaymentsPerYear="numberOfPaymentsPerYear"
              :originationFee="originationFee"
              :serviceFee="serviceFee"
              :hasError="$v.$anyError"
            />
          </div>
        </div>
      </Tab>
      <Tab :title="$t('mortgageCalculator.tab.loanAmount')">
        <div class="row mt-8">
          <div class="col col-12 col-sm-5">
            <LoanPaymentSlider
              class="mb-5"
              v-model.number="$v.loanPayment.$model"
              :hasError="$v.loanPayment.$error"
              :rules="loanPaymentRules"
            />

            <LoanTimeSlider
              class="mb-5"
              v-model.number="$v.loanTime.$model"
              :hasError="$v.loanTime.$error"
              :rules="loanTimeRules"
            />

            <LoanInterestSlider
              v-model.number="$v.loanInterest.$model"
              :hasError="$v.loanInterest.$error"
              :rules="loanInterestRules"
            />
          </div>

          <div class="col col-12 col-sm-7">
            <Summary
              :calculationType="'CALCULATE_LOAN_AMOUNT'"
              :loanAmount="loanAmount"
              :loanPayment="loanPayment"
              :loanTime="loanTime"
              :loanInterest="loanInterest"
              :numberOfPaymentsPerYear="numberOfPaymentsPerYear"
              :originationFee="originationFee"
              :serviceFee="serviceFee"
              :hasError="$v.$anyError"
            />
          </div>
        </div>
      </Tab>
    </Tabs>

    <div class="divider mt-8 mb-4"></div>
    <div class="disclaimer text-gray text-justify" v-html="$t('mortgageCalculator.disclaimer')"></div>
  </div>
</div>
</template>

<script>
import LoanAmountSlider from '@/components/Calculator/Sliders/LoanAmountSlider.vue'
import LoanPaymentSlider from '@/components/Calculator/Sliders/LoanPaymentSlider.vue'
import LoanTimeSlider from '@/components/Calculator/Sliders/LoanTimeSlider.vue'
import LoanInterestSlider from '@/components/Calculator/Sliders/LoanInterestSlider.vue'
import Summary from '@/components/Calculator/Summary/Summary.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Tab from '@/components/Tabs/Tab.vue'
import sendSetHeightToParentMixin from '@/components/Calculator/sendSetHeightToParentMixin.js'
import { between, required } from 'vuelidate/lib/validators'
import * as common from '@/common'

export default {
  name: 'mortgage-calculator',
  data() {
    return {
      loanAmount: 100000,
      loanPayment: 600,
      loanInterest: common.kLoanInterestDefault,
      loanTime: 20,
      numberOfPaymentsPerYear: 12,
      originationFee: 480,
      serviceFee: 2.3
    }
  },
  components: {
    LoanAmountSlider,
    LoanPaymentSlider,
    LoanTimeSlider,
    LoanInterestSlider,
    Summary,
    Tabs,
    Tab
  },
  mixins: [ sendSetHeightToParentMixin ],
  mounted() {
    if (/\?lang=sv/.test(window.location.href)) {
      this.$i18n.locale = 'sv'
    } else {
      this.$i18n.locale = 'fi'
    }
  },
  computed: {
    loanAmountRules() {
      const betweenHint = this.$t('formError.between', {
        min: this.$options.filters.currency(this.$v.loanAmount.$params.between.min, 0, ''),
        max: this.$options.filters.currency(this.$v.loanAmount.$params.between.max)
      })

      return [
        (!this.$v.loanAmount.required || !this.$v.loanAmount.between) && betweenHint
      ]
    },
    loanPaymentRules() {
      const betweenHint = this.$t('formError.between', {
        min: this.$options.filters.currency(this.$v.loanPayment.$params.between.min, 0, ''),
        max: this.$options.filters.currency(this.$v.loanPayment.$params.between.max)
      })

      return [
        (!this.$v.loanPayment.required || !this.$v.loanPayment.between) && betweenHint
      ]
    },
    loanTimeRules() {
      const betweenHint = this.$t('formError.between', {
        min: this.$v.loanTime.$params.between.min,
        max: `${this.$v.loanTime.$params.between.max} ${this.$t('years')}`
      })

      return [
        (!this.$v.loanTime.required || !this.$v.loanTime.between) && betweenHint
      ]
    },
    loanInterestRules() {
      const betweenHint = this.$t('formError.between', {
        min: this.$options.filters.currency(this.$v.loanInterest.$params.between.min, 1, ''),
        max: this.$options.filters.currency(this.$v.loanInterest.$params.between.max, 0, '%')
      })

      return [
        (!this.$v.loanInterest.required || !this.$v.loanInterest.between) && betweenHint
      ]
    }
  },
  validations() {
    return {
      loanAmount: {
        between: between(10000, 2000000),
        required
      },
      loanPayment: {
        between: between(100, 10000),
        required
      },
      loanTime: {
        between: between(1, 25),
        required
      },
      loanInterest: {
        between: between(.1, 10),
        required
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

#calculator {
  background: $color-white;

  .container {
    max-width: 665px;
  }

  h1 {
    @media (max-width: $size-sm) {
      font-size: 2rem;
    }
  }

  .disclaimer {
    font-size: .875rem;
  }
}
</style>
