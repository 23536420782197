import axios from 'axios'
import store from '@/store'

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.withCredentials = true

axios.interceptors.request.use(config => {
  config.headers['Accept-Language'] = store.state.lang
  return config
})

export default {

  getIdentityProviders() {
    return axios({
      method: 'GET',
      url: `${API_BASE_URL}/ftn/handlers`
    })
  },

  /**
   * Login user
   *
   * @param {String="AUTHENTICATION","EDIT_TOKEN"} reason - Reason for login is either initial authentication or editing application
   * @param {String="aktia","alandsbanken","danske","handelsbanken","nordea","poppankki","saastopankki","spankki","omasp","osuuspankki","mobileid","ftn-portal","dummy"} idpid - Identity provider
   * @param {String} callbackUrl - URL to redirect after login
   * @param {String} editToken - If reason is 'EDIT_TOKEN' editToken must be provided
   *
   * @return Promise
   */
  login(reason = 'AUTHENTICATION', idpId,  callbackUrl, editToken) {
    return axios({
      method: 'POST',
      url: `${API_BASE_URL}/ftn/authorize`,
      params: {
        reason: reason,
        idpId: idpId,
        verifiableId: editToken
      },
      data: {
        callbackContext: callbackUrl
      }
    })
  },

  /**
   * Logout user
   *
   * @return Promise
   */
  logout() {
    return axios({
      method: 'DELETE',
      url: `${API_BASE_URL}/sessions`
    })
  },

  getUser() {
    return axios({
      method: 'GET',
      url: `${API_BASE_URL}/sessions`
    })
  }

}
