<template>
<div id="app">
  <header class="navbar" :class="{ 'navbar-inverse': $route.name === 'firstStep' }">
    <section class="navbar-section">
      <router-link to="/" class="navbar-brand">
        <Logo /><span class="text-assistive">Aktia</span>
      </router-link>
      <h1 class="navbar-title">{{ $t("title") }}</h1>
    </section>
    <section class="navbar-section">
      <a v-if="$i18n.locale === 'fi'" href="#" id="lang" class="text-nowrap" @click.prevent="setLang('sv')">
        {{ $t("lang") }}
      </a>
      <a v-else href="#" id="lang" class="text-nowrap" @click.prevent="setLang('fi')">
        {{ $t("lang") }}
      </a>
      <a v-if="isAuthenticated" href="#" id="logout" class="text-nowrap" @click.prevent="openLogoutModal()">
        <Icon class="mr-1" name="lock-open" />{{ $t("auth.logout") }}
      </a>
    </section>
  </header>

  <router-view />

  <Modal ref="logoutModal" id="logoutModal">
    <div class="text-center pt-8">
      <h3 class="mb-8">{{ $t("modal.logout.title") }}</h3>
      <button class="btn btn-primary" @click.prevent="logout()">{{ $t("auth.logout") }}</button>
      <br>
      <button class="btn btn-link mt-2" @click.prevent="closeLogoutModal()">{{ $t("cancel") }}</button>
    </div>
  </Modal>
</div>
</template>

<style lang="scss">
  @import '@/scss/aktia.scss'
</style>

<script>
import Logo from '@/components/Logo.vue'
import Modal from '@/components/Modal.vue'
import { Utils } from '@/utils/utils'

export default {
  name: 'app',
  components: {
    Logo,
    Modal
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    lang() {
      return this.$store.state.lang
    },
    isConstructionMortgage() {
      return this.$store.getters.isConstructionMortgage
    },
    isRefinance() {
      return this.$store.getters.isRefinance
    },
    isLoanTargetKnownApplied() {
      return this.$store.getters.isLoanTargetKnownApplied
    },
    isLoanTargetKnown() {
      return this.$store.state.isLoanTargetKnown
    },
    mortgageType() {
      return this.$store.state.mortgageType
    },
    loanAmount() {
      return this.$store.getters.loanAmount
    },
    desiredLoanTime() {
      return this.$store.getters.desiredLoanTime
    },
    loanPayment() {
      return this.$store.getters.loanPayment
    },
    isBuildingGroundRented() {
      return this.$store.state.loanTarget.isBuildingGroundRented
    }
  },
  watch: {
    '$route'(to) {
      if (to && to.meta && to.meta.analytics && to.meta.analytics.descName) {
        const { descName } = to.meta.analytics

        if (descName === 'loan-details') {
          this.sendAnalytics('apartment-details')
          this.sendAnalytics(descName)
        } else {
          this.sendAnalytics(descName)
        }
      }
    }
  },
  created() {
    this.initMaintenance()
    this.initAnalytics()
    this.initLang()
    this.initCampaignId()
    this.getBranches()
  },
  methods: {
    initMaintenance() {
      this.$store.commit('setIsMaintenance', this.$isMaintenance)

      if (this.$isMaintenance) {
        const maintenanceUrl = `${window.location.origin}/#/huolto`
        window.location.replace(maintenanceUrl)
      }
    },
    initAnalytics() {
      if (
        this.$route &&
        this.$route.meta &&
        this.$route.meta.analytics &&
        this.$route.meta.analytics.descName
      ) {
        const { descName } = this.$route.meta.analytics

        window.addEventListener('CookiebotOnAccept', () => {
          if (window.Cookiebot.consent.statistics && descName) {
            this.sendAnalytics(descName)
          }
        }, false)
      }
    },
    initLang() {
      const lang = Utils.getURLQueryParam('lang')
      this.setLang(lang)
    },
    setLang(lang) {
      if ([ 'fi', 'sv' ].includes(lang)) {
        this.$store.commit('setLang', lang)
      }
    },
    initCampaignId() {
      const campaignId = Utils.getURLQueryParam('cid') || window.sessionStorage.getItem('aa-cid')

      if (campaignId) {
        this.$store.commit('setCampaignId', campaignId)
      }
    },
    openLogoutModal() {
      this.$refs.logoutModal.open()
    },
    closeLogoutModal() {
      this.$refs.logoutModal.close()
    },
    logout() {
      this.$store.dispatch('logout')
        .then(() => {
          this.closeLogoutModal()
          this.$router.push({ name: 'firstStep' })
        })
    },
    getBranches() {
      if (process.env.VUE_APP_ENV !== 'local') {
        this.$store.dispatch('getBranches')
      }
    },
    sendAnalytics(descName) {
      if (descName) {
        const digitalData = window.digitalData || []

        const isLoanTargetKnown = () => {
          return (this.isLoanTargetKnownApplied && this.isLoanTargetKnown) ||
            this.isConstructionMortgage ||
            this.isRefinance
        }

        const houseType = () => {
          if (this.isConstructionMortgage) {
            return 'REAL_ESTATE'
          }

          if ((this.isLoanTargetKnownApplied && this.isLoanTargetKnown) || this.isRefinance) {
            return this.$store.state.loanTarget.type
          }
        }

        const plotType = () => {
          if (this.isConstructionMortgage) {
            return this.$store.state.loanTarget.isBuildingGroundRented ? 'rented' : 'owned'
          }
        }

        const authSource = () => {
          if (this.isAuthenticated) {
            return this.$store.state.authSource
          }
        }

        const authIdp = () => {
          if (this.isAuthenticated && this.$store.state.authSource === 'ftn') {
            return this.$store.state.authIdp
          }
        }

        digitalData.push({
          'event': `mortgage-${descName}`,
          pageDetails: {
            'pageName': 'mortgage application',
            'section': 'lainat'
          },
          appDetails: {
            'appName': 'mortgage',
            'language': this.lang,
            'loan-type': this.mortgageType,
            'step': descName,
            'logged-in': this.isAuthenticated,
            'house-details-given': isLoanTargetKnown(),
            'mortgage-amount': this.loanAmount,
            'mortgage-time': this.desiredLoanTime,
            'monthly-payment': this.loanPayment,
            'house-type': houseType(),
            'plot-type': plotType(),
            'auth-source': authSource(),
            'auth-idp': authIdp()
          },
          customerDetails: { }
        })
      }
    }
  }
}
</script>
