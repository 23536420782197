<template>
  <div class="stepper">
    <div class="stepper-title">{{ $t("applicationProgress") }}</div>

    <div class="stepper-list">
      <!-- First step -->
      <div
        v-if="isVisible('firstStep')"
        :class="[
          'stepper-item',
          isActive('firstStep') ? 'active': '',
          isDisabled('firstStep') ? 'disabled': ''
        ]"
      >
        <div class="stepper-item-icon"></div>
        <div class="stepper-item-body">
          <span class="stepper-item-title">{{ $t("firstStep.title") }}</span>
        </div>
      </div>

      <!-- Second step -->
      <div
        v-if="isVisible('secondStep')"
        :class="[
          'stepper-item',
          isActive('secondStep') ? 'active': '',
          isDisabled('secondStep') ? 'disabled': ''
        ]"
      >
        <div class="stepper-item-icon"></div>
        <div class="stepper-item-body">
          <span class="stepper-item-title">{{ $t("secondStep.title") }}</span>
        </div>
      </div>

      <!-- Third step -->
      <div
        v-if="isVisible('thirdStep')"
        :class="[
          'stepper-item',
          isActive('thirdStep') ? 'active': '',
          isDisabled('thirdStep') ? 'disabled': ''
        ]"
      >
        <div class="stepper-item-icon"></div>
        <div class="stepper-item-body">
          <span class="stepper-item-title">{{ $t("thirdStep.title") }}</span>
        </div>
      </div>

      <!-- Fourth step -->
      <div
        v-if="isVisible('fourthStepOne') || isVisible('fourthStepTwo')"
        :class="[
          'stepper-item',
          (isActive('fourthStepOne') || isActive('fourthStepTwo')) ? 'active': '',
          (isDisabled('fourthStepOne') && isDisabled('fourthStepTwo')) ? 'disabled': ''
        ]"
      >
        <div class="stepper-item-icon"></div>
        <div class="stepper-item-body">
          <span class="stepper-item-title">{{ $t("fourthStep.title") }}</span>
        </div>
      </div>

      <!-- Fifth step -->
      <div
        v-if="isVisible('fifthStep')"
        :class="[
          'stepper-item',
          isActive('fifthStep') ? 'active': '',
          isDisabled('fifthStep') ? 'disabled': ''
        ]"
      >
        <div class="stepper-item-icon"></div>
        <div class="stepper-item-body">
          <span class="stepper-item-title">{{ $t("fifthStep.title") }}</span>
        </div>
      </div>

      <!-- Summary step -->
      <div
        v-if="isVisible('summaryStep')"
        :class="[
          'stepper-item',
          isActive('summaryStep') ? 'active': '',
          isDisabled('summaryStep') ? 'disabled': ''
        ]"
      >
        <div class="stepper-item-icon"></div>
        <div class="stepper-item-body">
          <span class="stepper-item-title">{{ $t("summaryStep.title") }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { NavGuard } from '@/router/nav-guard'

export default {
  name: 'stepper',
  computed: {
    currentStep() {
      return this.$route
    },
    steps() {
      return this.$router.options.routes
    }
  },
  methods: {
    findStepByName(stepName) {
      return this.steps.find(step => step.name === stepName)
    },
    isActive(stepName) {
      return this.currentStep.name === stepName
    },
    isDisabled(stepName) {
      const step = this.findStepByName(stepName)
      return NavGuard.isRouteAllowed(step) ? false : true
    },
    isVisible(stepName) {
      const step = this.findStepByName(stepName)
      return !step.meta.skip(this.$store)
    }
  }
}
</script>
