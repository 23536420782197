<template>
  <div :class="{ 'has-error': hasError }">
    <template v-for="rule in rules">
      <div v-if="rule" :key="rule" class="form-input-hint">
        {{ rule }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    hasError: {
      type: Boolean,
      default: false
    }
  }
}
</script>
