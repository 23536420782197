<template>
  <Calculator />
</template>

<script>
import Calculator from '@/components/Calculator/Calculator.vue'

export default {
  components: {
    Calculator
  }
}
</script>
