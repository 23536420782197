<template>
  <div id="main" class="fourth" :class="{ 'pt-12': !isTwoApplicants }">

    <div v-if="isTwoApplicants" class="hero hero-sm">
      <div class="container container-lg">
        <h1 class="hero-title heading heading-lg mb-4">{{ $t("fourthStep.hero.title_1") }}</h1>
        <p class="hero-text text-xl">{{ $t("fourthStep.hero.text_1") }}</p>
      </div>
    </div>

    <div class="container container-lg">
      <div class="row">
        <div class="col col-12 col-md-9">
          <form class="page" @submit.prevent="handleSubmit" autocomplete="off">
            <Toast v-if="psd2DataSuccess" success class="mb-8">
              {{ $t("fourthStep.psd2Data.success") }}
            </Toast>

            <Toast v-if="psd2DataError" error class="mb-8">
              {{ $t("fourthStep.psd2Data.error") }}
            </Toast>

            <h2 class="h3 heading-underline heading-underline-lg mb-8">{{ $t("fourthStep.personalDetails.title") }}</h2>

            <!-- Oletko ensiasunnon ostaja? -->
            <div v-if="isFirstOwnerApplied" class="form-group row gx-4" :class="{ 'has-error': $v.firstOwner.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-2">{{ $t("form.firstOwner.label") }}</label>
              </div>
              <div class="col col-12 col-sm-6">
                <label class="form-button" for="firstOwner-true">
                  <input id="firstOwner-true" type="radio" name="firstOwner" :value="true" v-model="firstOwner">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.firstOwner.opts.yesIAm") }}</span>
                </label>
                <label class="form-button" for="firstOwner-false">
                  <input id="firstOwner-false" type="radio" name="firstOwner" :value="false" v-model="firstOwner">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.firstOwner.opts.noIAmNot") }}</span>
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.firstOwner.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <!-- Etunimi -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.firstName.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="firstName">{{ $t("form.firstName.label") }}</label>
              </div>
              <div class="col col-12 col-sm-6">
                <input id="firstName" class="form-input" type="text" name="firstName" :readonly="isAuthenticated" v-model.trim="firstName">
                <div class="form-input-hint" v-if="submitted && !$v.firstName.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.firstName.maxLength">{{ $t("formError.maxLength") }}</div>
              </div>
            </div>

            <!-- Sukunimi -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.lastName.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="lastName">{{ $t("form.lastName.label") }}</label>
              </div>
              <div class="col col-12 col-sm-6">
                <input id="lastName" class="form-input" type="text" name="lastName" :readonly="isAuthenticated" v-model.trim="lastName">
                <div class="form-input-hint" v-if="submitted && !$v.lastName.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.lastName.maxLength">{{ $t("formError.maxLength") }}</div>
              </div>
            </div>

            <!-- Henkilötunnus -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.ssn.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="ssn">{{ $t("form.ssn.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4">
                <input id="ssn" class="form-input" type="text" name="ssn" :readonly="isAuthenticated" v-model.trim="ssn">
                <div class="form-input-hint" v-if="submitted && !$v.ssn.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.ssn.ssn">{{ $t("formError.ssn") }}</div>
              </div>
            </div>

            <!-- Katusoite -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.streetAddress.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="streetAddress">{{ $t("form.streetAddress.label") }}</label>
              </div>
              <div class="col col-12 col-sm-6">
                <input id="streetAddress" class="form-input" type="text" name="streetAddress" v-model.trim="streetAddress">
                <div class="form-input-hint" v-if="submitted && !$v.streetAddress.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.streetAddress.maxLength">{{ $t("formError.maxLength") }}</div>
              </div>
            </div>

            <!-- Postinumero -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.zip.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="zip">{{ $t("form.zip.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3">
                <input id="zip" class="form-input" type="text" name="zip" v-model.trim="zip">
                <div class="form-input-hint" v-if="submitted && !$v.zip.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.zip.zip">{{ $t("formError.zip") }}</div>
              </div>
            </div>

            <!-- Postitoimipaikka -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.city.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="city">{{ $t("form.city.label") }}</label>
              </div>
              <div class="col col-12 col-sm-5">
                <input id="city" class="form-input" type="text" name="city" v-model.trim="city">
                <div class="form-input-hint" v-if="submitted && !$v.city.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.city.maxLength">{{ $t("formError.maxLength") }}</div>
              </div>
            </div>

            <!-- Puhelinnumero -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.phoneNumber.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="phoneNumber">
                  {{ $t("form.phoneNumber.label") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.phoneNumber.info')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-6">
                <input id="phoneNumber" class="form-input" type="text" name="phoneNumber" placeholder="+358501234567" v-model.trim="phoneNumber">
                <div class="form-input-hint" v-if="submitted && !$v.phoneNumber.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.phoneNumber.phoneNumber">{{ $t("formError.phoneNumber") }}</div>
              </div>
            </div>

            <!-- Sähköposti -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.email.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="email">{{ $t("form.email.label") }}</label>
              </div>
              <div class="col col-12 col-sm-6">
                <input id="email" class="form-input" type="text" name="email" v-model.trim="email">
                <div class="form-input-hint" v-if="submitted && !$v.email.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.email.email">{{ $t("formError.email") }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12 col-sm-4"></div>
              <div class="col col-12 col-sm-6">
                <div class="divider mb-6"></div>
              </div>
            </div>

            <!-- Koulutus -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.education.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-2">{{ $t("form.education.label") }}</label>
              </div>
              <div class="col col-12 col-sm-7">
                <label class="form-button" for="education-comprehensiveSchool">
                  <input id="education-comprehensiveSchool" type="radio" name="education" value="COMPREHENSIVE_SCHOOL" v-model="education">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.education.opts.comprehensiveSchool") }}</span>
                </label>
                <label class="form-button" for="education-careerSchool">
                  <input id="education-careerSchool" type="radio" name="education" value="CAREER_SCHOOL" v-model="education">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.education.opts.careerSchool") }}</span>
                </label>
                <label class="form-button" for="education-upperSecondaryGeneral">
                  <input id="education-upperSecondaryGeneral" type="radio" name="education" value="UPPER_SECONDARY_GENERAL" v-model="education">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.education.opts.upperSecondaryGeneral") }}</span>
                </label>
                <label class="form-button" for="education-polytechnic">
                  <input id="education-polytechnic" type="radio" name="education" value="POLYTECHNIC" v-model="education">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.education.opts.polytechnic") }}</span>
                </label>
                <label class="form-button" for="education-universityBachelor">
                  <input id="education-universityBachelor" type="radio" name="education" value="UNIVERSITY_BACHELOR" v-model="education">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.education.opts.universityBachelor") }}</span>
                </label>
                <label class="form-button" for="education-universityMaster">
                  <input id="education-universityMaster" type="radio" name="education" value="UNIVERSITY_MASTER" v-model="education">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.education.opts.universityMaster") }}</span>
                </label>
                <label class="form-button" for="education-other">
                  <input id="education-other" type="radio" name="education" value="OTHER" v-model="education">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.education.opts.other") }}</span>
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.education.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12 col-sm-4"></div>
              <div class="col col-12 col-sm-6">
                <div class="divider mb-6"></div>
              </div>
            </div>

            <!-- Siviilisääty -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.maritalStatus.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-2">{{ $t("form.maritalStatus.label") }}</label>
              </div>
              <div class="col col-12 col-sm-7">
                <label class="form-button" for="maritalStatus-single">
                  <input id="maritalStatus-single" type="radio" name="maritalStatus" value="SINGLE" v-model="maritalStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.maritalStatus.opts.single") }}</span>
                </label>
                <label class="form-button" for="maritalStatus-cohabitation">
                  <input id="maritalStatus-cohabitation" type="radio" name="maritalStatus" value="COHABITATION" v-model="maritalStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.maritalStatus.opts.cohabitation") }}</span>
                </label>
                <label class="form-button" for="maritalStatus-marriageOrRegisteredPartnership">
                  <input id="maritalStatus-marriageOrRegisteredPartnership" type="radio" name="maritalStatus" value="MARRIAGE_OR_REGISTERED_PARTNERSHIP" v-model="maritalStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.maritalStatus.opts.marriageOrRegisteredPartnership") }}</span>
                </label>
                <label class="form-button" for="maritalStatus-divorced">
                  <input id="maritalStatus-divorced" type="radio" name="maritalStatus" value="DIVORCED" v-model="maritalStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.maritalStatus.opts.divorced") }}</span>
                </label>
                <label class="form-button" for="maritalStatus-singleParent">
                  <input id="maritalStatus-singleParent" type="radio" name="maritalStatus" value="SINGLE_PARENT" v-model="maritalStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.maritalStatus.opts.singleParent") }}</span>
                </label>
                <label class="form-button" for="maritalStatus-widow">
                  <input id="maritalStatus-widow" type="radio" name="maritalStatus" value="WIDOW" v-model="maritalStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.maritalStatus.opts.widow") }}</span>
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.maritalStatus.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12 col-sm-4"></div>
              <div class="col col-12 col-sm-6">
                <div class="divider mb-6"></div>
              </div>
            </div>

            <!-- Asumismuoto -->
            <div  class="form-group row gx-4" :class="{ 'has-error': $v.statusOfHousing.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-2">{{ $t("form.statusOfHousing.label") }}</label>
              </div>
              <div class="col col-12 col-sm-7">
                <label class="form-button" for="statusOfHousing-rentedFlat">
                  <input id="statusOfHousing-rentedFlat" type="radio" name="statusOfHousing" value="RENTED_FLAT" v-model="statusOfHousing">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.statusOfHousing.opts.rentedFlat") }}</span>
                </label>
                <label class="form-button" for="statusOfHousing-subtenantFlat">
                  <input id="statusOfHousing-subtenantFlat" type="radio" name="statusOfHousing" value="SUBTENANT_FLAT" v-model="statusOfHousing">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.statusOfHousing.opts.subtenantFlat") }}</span>
                </label>
                <label class="form-button" for="statusOfHousing-ownerOccupiedFlat">
                  <input id="statusOfHousing-ownerOccupiedFlat" type="radio" name="statusOfHousing" value="OWNER_OCCUPIED_FLAT" v-model="statusOfHousing">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.statusOfHousing.opts.ownerOccupiedFlat") }}</span>
                </label>
                <label class="form-button" for="statusOfHousing-jobRelatedFlat">
                  <input id="statusOfHousing-jobRelatedFlat" type="radio" name="statusOfHousing" value="JOB_RELATED_FLAT" v-model="statusOfHousing">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.statusOfHousing.opts.jobRelatedFlat") }}</span>
                </label>
                <label class="form-button" for="statusOfHousing-rightOfResidenceFlat">
                  <input id="statusOfHousing-rightOfResidenceFlat" type="radio" name="statusOfHousing" value="RIGHT_OF_RESIDENCE_FLAT" v-model="statusOfHousing">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.statusOfHousing.opts.rightOfResidenceFlat") }}</span>
                </label>
                <label class="form-button" for="statusOfHousing-partiallyOwnedFlat">
                  <input id="statusOfHousing-partiallyOwnedFlat" type="radio" name="statusOfHousing" value="PARTIALLY_OWNED_FLAT" v-model="statusOfHousing">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.statusOfHousing.opts.partiallyOwnedFlat") }}</span>
                </label>
                <label class="form-button" for="statusOfHousing-livesWithParents">
                  <input id="statusOfHousing-livesWithParents" type="radio" name="statusOfHousing" value="LIVES_WITH_PARENTS" v-model="statusOfHousing">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.statusOfHousing.opts.livesWithParents") }}</span>
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.statusOfHousing.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-8">{{ $t("fourthStep.household.title") }}</h2>

            <!-- Aikuisten määrä taloudessa -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.numberOfAdults.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="numberOfAdults">{{ $t("form.numberOfAdults.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4 col-lg-3">
                <NumberInput id="numberOfAdults" name="numberOfAdults" :min="$v.numberOfAdults.$params.between.min" :max="$v.numberOfAdults.$params.between.max" :step="1" v-model.number="numberOfAdults" />
                <div class="form-input-hint" v-if="submitted && !$v.numberOfAdults.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.numberOfAdults.between">{{ $t("formError.between", { min: $v.numberOfAdults.$params.between.min, max: $v.numberOfAdults.$params.between.max }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.numberOfAdults.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Lasten määrä taloudessa -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.numberOfChildren.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="numberOfChildren">{{ $t("form.numberOfChildren.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4 col-lg-3">
                <NumberInput id="numberOfChildren" name="numberOfChildren" :min="$v.numberOfChildren.$params.between.min" :max="$v.numberOfChildren.$params.between.max" :step="1" v-model.number="numberOfChildren" />
                <div class="form-input-hint" v-if="submitted && !$v.numberOfChildren.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.numberOfChildren.between">{{ $t("formError.between", { min: $v.numberOfAdults.$params.between.min, max: $v.numberOfChildren.$params.between.max }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.numberOfChildren.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Autojen lukumäärä -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.numberOfCars.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="numberOfCars">{{ $t("form.numberOfCars.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4 col-lg-3">
                <NumberInput id="numberOfCars" name="numberOfCars" :min="$v.numberOfCars.$params.between.min" :max="$v.numberOfCars.$params.between.max" :step="1" v-model.number="numberOfCars" />
                <div class="form-input-hint" v-if="submitted && !$v.numberOfCars.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.numberOfCars.between">{{ $t("formError.between", { min: $v.numberOfCars.$params.between.min, max: $v.numberOfCars.$params.between.max }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.numberOfCars.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-8">{{ $t("fourthStep.employmentInfo.title") }}</h2>

            <!-- Sosioekonominen asema -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.socioeconomicStatus.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-2" for="socioeconomicStatus">{{ $t("form.socioeconomicStatus.label") }}</label>
              </div>
              <div class="col col-12 col-sm-7">
                <label class="form-button" for="socioeconomicStatus-employee">
                  <input id="socioeconomicStatus-employee" type="radio" name="socioeconomicStatus" value="EMPLOYEE" v-model="socioeconomicStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.socioeconomicStatus.opts.employee") }}</span>
                </label>
                <label class="form-button" for="socioeconomicStatus-lowerOfficial">
                  <input id="socioeconomicStatus-lowerOfficial" type="radio" name="socioeconomicStatus" value="LOWER_OFFICIAL" v-model="socioeconomicStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.socioeconomicStatus.opts.lowerOfficial") }}</span>
                </label>
                <label class="form-button" for="socioeconomicStatus-upperOfficial">
                  <input id="socioeconomicStatus-upperOfficial" type="radio" name="socioeconomicStatus" value="UPPER_OFFICIAL" v-model="socioeconomicStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.socioeconomicStatus.opts.upperOfficial") }}</span>
                </label>
                <label class="form-button" for="socioeconomicStatus-entrepreneur">
                  <input id="socioeconomicStatus-entrepreneur" type="radio" name="socioeconomicStatus" value="ENTREPRENEUR" v-model="socioeconomicStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.socioeconomicStatus.opts.entrepreneur") }}</span>
                </label>
                <label class="form-button" for="socioeconomicStatus-student">
                  <input id="socioeconomicStatus-student" type="radio" name="socioeconomicStatus" value="STUDENT" v-model="socioeconomicStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.socioeconomicStatus.opts.student") }}</span>
                </label>
                <label class="form-button" for="socioeconomicStatus-pensioner">
                  <input id="socioeconomicStatus-pensioner" type="radio" name="socioeconomicStatus" value="PENSIONER" v-model="socioeconomicStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.socioeconomicStatus.opts.pensioner") }}</span>
                </label>
                <label class="form-button" for="socioeconomicStatus-unemployed">
                  <input id="socioeconomicStatus-unemployed" type="radio" name="socioeconomicStatus" value="UNEMPLOYED" v-model="socioeconomicStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.socioeconomicStatus.opts.unemployed") }}</span>
                </label>
                <label class="form-button" for="socioeconomicStatus-longTermUnemployed">
                  <input id="socioeconomicStatus-longTermUnemployed" type="radio" name="socioeconomicStatus" value="LONGTERM_UNEMPLOYED" v-model="socioeconomicStatus">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.socioeconomicStatus.opts.longTermUnemployed") }}</span>
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.socioeconomicStatus.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <div v-if="isEmploymentApplied">
              <div class="row">
                <div class="col col-12 col-sm-4"></div>
                <div class="col col-12 col-sm-6">
                  <div class="divider mb-6"></div>
                </div>
              </div>

              <!-- Työsuhde -->
              <div class="form-group row gx-4" :class="{ 'has-error': $v.employment.$error }">
                <div class="col col-12 col-sm-4 col-form-label">
                  <label class="form-label mb-2">{{ $t("form.employment.label") }}</label>
                </div>
                <div class="col col-12 col-sm-7">
                  <label class="form-button" for="employment-regularFulltime">
                    <input id="employment-regularFulltime" type="radio" name="employment" value="REGULAR_FULLTIME" v-model="employment">
                    <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.employment.opts.regularFulltime") }}</span>
                  </label>
                  <label class="form-button" for="employment-regularParttime">
                    <input id="employment-regularParttime" type="radio" name="employment" value="REGULAR_PARTTIME" v-model="employment">
                    <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.employment.opts.regularParttime") }}</span>
                  </label>
                  <label class="form-button" for="employment-temporaryFulltime">
                    <input id="employment-temporaryFulltime" type="radio" name="employment" value="TEMPORARY_FULLTIME" v-model="employment">
                    <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.employment.opts.temporaryFulltime") }}</span>
                  </label>
                  <label class="form-button" for="employment-temporaryParttime">
                    <input id="employment-temporaryParttime" type="radio" name="employment" value="TEMPORARY_PARTTIME" v-model="employment">
                    <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.employment.opts.temporaryParttime") }}</span>
                  </label>
                  <label class="form-button" for="employment-interrupted">
                    <input id="employment-interrupted" type="radio" name="employment" value="INTERRUPTED" v-model="employment">
                    <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.employment.opts.interrupted") }}</span>
                  </label>
                  <div class="form-input-hint" v-if="submitted && !$v.employment.required">{{ $t("formError.required") }}</div>
                </div>
              </div>

              <div class="row">
                <div class="col col-12 col-sm-4"></div>
                <div class="col col-12 col-sm-6">
                  <div class="divider mb-6"></div>
                </div>
              </div>

              <!-- Työnantaja -->
              <div class="form-group row gx-4" :class="{ 'has-error': $v.employer.$error }">
                <div class="col col-12 col-sm-4 col-form-label">
                  <label class="form-label" for="employer">{{ $t("form.employer.label") }}</label>
                </div>
                <div class="col col-12 col-sm-6">
                  <input id="employer" class="form-input" type="text" name="employer" v-model.trim="employer" autocomplete="off">
                  <div class="form-input-hint" v-if="submitted && !$v.employer.required">{{ $t("formError.required") }}</div>
                  <div class="form-input-hint" v-if="submitted && !$v.employer.maxLength">{{ $t("formError.maxLength") }}</div>
                </div>
              </div>

              <!-- Ammatti -->
              <div class="form-group row gx-4" :class="{ 'has-error': $v.occupation.$error }">
                <div class="col col-12 col-sm-4 col-form-label">
                  <label class="form-label" for="occupation">{{ $t("form.occupation.label") }}</label>
                </div>
                <div class="col col-12 col-sm-6">
                  <input id="occupation" class="form-input" type="text" name="occupation" v-model.trim="occupation" autocomplete="off">
                  <div class="form-input-hint" v-if="submitted && !$v.occupation.required">{{ $t("formError.required") }}</div>
                  <div class="form-input-hint" v-if="submitted && !$v.occupation.maxLength">{{ $t("formError.maxLength") }}</div>
                </div>
              </div>

              <!-- Työsuhde alkanut -->
              <div class="row gx-4" role="group" aria-labelledby="employmentStartLabel">
                <div class="col col-12 col-sm-4 col-form-label">
                  <label id="employmentStartLabel" class="form-label">{{ $t("form.employmentStart.label") }}</label>
                </div>
                <div class="col col-12 col-sm-6">
                  <div class="row gx-4">
                    <div class="col col-7">
                      <div class="form-group" :class="{ 'has-error': $v.employmentStartMonth.$error | $v.employmentStartDate.$error }">
                        <select id="employmentStartMonth" class="form-select" name="employmentStartMonth" :aria-label="$t('month')" v-model="employmentStartMonth">
                          <option value="" disabled>{{ $t("month") }}</option>
                          <option :value="0">{{ $t("january") }}</option>
                          <option :value="1">{{ $t("february") }}</option>
                          <option :value="2">{{ $t("march") }}</option>
                          <option :value="3">{{ $t("april") }}</option>
                          <option :value="4">{{ $t("may") }}</option>
                          <option :value="5">{{ $t("june") }}</option>
                          <option :value="6">{{ $t("july") }}</option>
                          <option :value="7">{{ $t("august") }}</option>
                          <option :value="8">{{ $t("september") }}</option>
                          <option :value="9">{{ $t("october") }}</option>
                          <option :value="10">{{ $t("november") }}</option>
                          <option :value="11">{{ $t("december") }}</option>
                        </select>
                        <div class="form-input-hint" v-if="submitted && !$v.employmentStartMonth.required">{{ $t("formError.required") }}</div>
                        <div class="form-input-hint" v-if="submitted && !$v.employmentStartDate.pastDate">{{ $t("formError.pastDate") }}</div>
                      </div>
                    </div>
                    <div class="col col-5">
                      <div class="form-group" :class="{ 'has-error': $v.employmentStartYear.$error | $v.employmentStartDate.$error }">
                        <select id="employmentStartYear" class="form-select" name="employmentStartYear" :aria-label="$t('year')" v-model="employmentStartYear">
                          <option value="" disabled>{{ $t("year") }}</option>
                          <option v-for="year in pastYears" :key="year" :value="year">{{year}}</option>
                        </select>
                        <div class="form-input-hint" v-if="submitted && !$v.employmentStartYear.required">{{ $t("formError.required") }}</div>
                        <div class="form-input-hint" v-if="submitted && !$v.employmentStartDate.pastDate">{{ $t("formError.pastDate") }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Työsuhde päättyy -->
              <div v-if="isEmploymentEndApplied" class="row gx-4" role="group" aria-labelledby="employmentEndLabel">
                <div class="col col-12 col-sm-4 col-form-label">
                  <label id="employmentEndLabel" class="form-label">{{ $t("form.employmentEnd.label") }}</label>
                </div>
                <div class="col col-12 col-sm-6">
                  <div class="row gx-4">
                    <div class="col col-7">
                      <div class="form-group" :class="{ 'has-error': $v.employmentEndMonth.$error | $v.employmentEndDate.$error }">
                        <select id="employmentEndMonth" class="form-select" name="employmentEndMonth" :aria-label="$t('month')" v-model="employmentEndMonth">
                          <option value="" disabled>{{ $t("month") }}</option>
                          <option :value="0">{{ $t("january") }}</option>
                          <option :value="1">{{ $t("february") }}</option>
                          <option :value="2">{{ $t("march") }}</option>
                          <option :value="3">{{ $t("april") }}</option>
                          <option :value="4">{{ $t("may") }}</option>
                          <option :value="5">{{ $t("june") }}</option>
                          <option :value="6">{{ $t("july") }}</option>
                          <option :value="7">{{ $t("august") }}</option>
                          <option :value="8">{{ $t("september") }}</option>
                          <option :value="9">{{ $t("october") }}</option>
                          <option :value="10">{{ $t("november") }}</option>
                          <option :value="11">{{ $t("december") }}</option>
                        </select>
                        <div class="form-input-hint" v-if="submitted && !$v.employmentEndMonth.required">{{ $t("formError.required") }}</div>
                        <div class="form-input-hint" v-if="submitted && !$v.employmentEndDate.futureDate">{{ $t("formError.futureDate") }}</div>
                      </div>
                    </div>
                    <div class="col col-5">
                      <div class="form-group" :class="{ 'has-error': $v.employmentEndYear.$error || $v.employmentEndDate.$error }">
                        <select class="form-select" name="employmentEnd" :aria-label="$t('year')" v-model="employmentEndYear">
                          <option value="" disabled>{{ $t("year") }}</option>
                          <option v-for="year in futureYears" :key="year" :value="year">{{year}}</option>
                        </select>
                        <div class="form-input-hint" v-if="submitted && !$v.employmentEndYear.required">{{ $t("formError.required") }}</div>
                        <div class="form-input-hint" v-if="submitted && !$v.employmentEndDate.futureDate">{{ $t("formError.futureDate") }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-8">{{ $t("fourthStep.income.title") }}</h2>

            <!-- Bruttotulot €/kk -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.grossIncome.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="grossIncome">{{ $t("form.grossIncome.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="grossIncome" class="form-input" type="text" name="grossIncome" v-model.number="grossIncome">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.grossIncome.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.grossIncome.between">{{ $t("formError.between", { min: $options.filters.currency($v.grossIncome.$params.between.min, 0, ''), max: $options.filters.currency($v.grossIncome.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.grossIncome.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Nettotulot €/kk -->
            <div class="form-group row gx-4 mb-12" :class="{ 'has-error': $v.netIncome.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="netIncome">{{ $t("form.netIncome.label") }}</label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="netIncome" class="form-input" type="text" name="netIncome" v-model.number="netIncome">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.netIncome.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.netIncome.between">{{ $t("formError.between", { min: $options.filters.currency($v.netIncome.$params.between.min, 0, ''), max: $options.filters.currency($v.netIncome.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.netIncome.lessThanOrEqual">{{ $t("formError.netIncomeLessThanOrEqual") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.netIncome.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <p>{{ $t("fourthStep.psd2Data.text") }} <a href="#" @click.prevent="getPsd2DataUrl">{{ $t("fourthStep.psd2Data.cta") }}</a></p>

            <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-8">{{ $t("fourthStep.capitalIncome.title") }}</h2>

            <!-- Osinkotulo €/kk -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.dividendIncome.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="dividendIncome">{{ $t("form.dividendIncome.label") }}</label>
                <div class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="dividendIncome" class="form-input" type="text" name="dividendIncome" v-model.number="dividendIncome">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.dividendIncome.between">{{ $t("formError.between", { min: $options.filters.currency($v.dividendIncome.$params.between.min, 0, ''), max: $options.filters.currency($v.dividendIncome.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.dividendIncome.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Vuokratulo €/kk -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.rentalIncome.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="rentalIncome">{{ $t("form.rentalIncome.label") }}</label>
                <div class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="rentalIncome" class="form-input" type="text" name="rentalIncome" v-model.number="rentalIncome">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.rentalIncome.between">{{ $t("formError.between", { min: $options.filters.currency($v.rentalIncome.$params.between.min, 0, ''), max: $options.filters.currency($v.rentalIncome.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.rentalIncome.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Muu pääomatulo €/kk -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.otherCapitalIncome.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="otherCapitalIncome">{{ $t("form.otherCapitalIncome.label") }}</label>
                <div class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="otherCapitalIncome" class="form-input" type="text" name="otherCapitalIncome" v-model.number="otherCapitalIncome">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.otherCapitalIncome.between">{{ $t("formError.between", { min: $options.filters.currency($v.otherCapitalIncome.$params.between.min, 0, ''), max: $options.filters.currency($v.otherCapitalIncome.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.otherCapitalIncome.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-8">{{ $t("fourthStep.otherIncome.title") }}</h2>

            <!-- Lapsilisä €/kk -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.childBenefitIncome.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="childBenefitIncome">{{ $t("form.childBenefitIncome.label") }}</label>
                <div class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="childBenefitIncome" class="form-input" type="text" name="childBenefitIncome" v-model.number="childBenefitIncome">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.childBenefitIncome.between">{{ $t("formError.between", { min: $options.filters.currency($v.childBenefitIncome.$params.between.min, 0, ''), max: $options.filters.currency($v.childBenefitIncome.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.childBenefitIncome.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Elatustuki €/kk -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.childSupportIncome.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="childSupportIncome">{{ $t("form.childSupportIncome.label") }}</label>
                <div class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="childSupportIncome" class="form-input" type="text" name="childSupportIncome" v-model.number="childSupportIncome">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.childSupportIncome.between">{{ $t("formError.between", { min: $options.filters.currency($v.childSupportIncome.$params.between.min, 0, ''), max: $options.filters.currency($v.childSupportIncome.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.childSupportIncome.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Muut tulot €/kk -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.otherIncome.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="otherIncome">{{ $t("form.otherIncome.label") }}</label>
                <div class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="otherIncome" class="form-input" type="text" name="otherIncome" v-model.number="otherIncome">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.otherIncome.between">{{ $t("formError.between", { min: $options.filters.currency($v.otherIncome.$params.between.min, 0, ''), max: $options.filters.currency($v.otherIncome.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.otherIncome.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-8">Varallisuus</h2>

            <!-- Onko sinulla muuta varallisuutta kuin oma asunto? -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.hasAssets.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label">{{ $t("form.hasAssets.label") }}</label>
              </div>
              <div class="col col-12 col-sm-7">
                <label class="form-button" for="hasAssets-true">
                  <input id="hasAssets-true" type="radio" name="hasAssets" :value="true" v-model="hasAssets" @change="resetAssets">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("yes") }}</span>
                </label>
                <label class="form-button" for="hasAssets-false">
                  <input id="hasAssets-false" type="radio" name="hasAssets" :value="false" v-model="hasAssets" @change="resetAssets">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("no") }}</span>
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.hasAssets.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <!-- Lisää uusi varallisuuserä -->
            <div class="row" v-if="isAssetsApplied">
              <div class="col col-12 col-sm-4 col-form-label pt-0">
                <label class="form-label">{{ $t("form.asset.label") }}</label>
              </div>

              <div class="col col-12 col-sm-7">
                <div class="bg-gray p-5 s-rounded mb-4" v-for="(item, key) in assets" :key="key">

                  <!-- Varallisuuserän tyyppi -->
                  <div class="form-group" :class="{ 'has-error': submitted && $v.assets.$each[key].type.$invalid }">
                    <label class="form-label" :for="'assetType-' + key">{{ $t("form.assetType.label") }}</label>
                    <select :id="'assetType-' + key" class="form-select" :value="item.type" @change="setAssetItemType($event.target.value, key)">
                      <option value="" disabled>{{ $t("select") }}</option>
                      <option value="APARTMENT">{{ $t("form.assetType.opts.apartment") }}</option>
                      <option value="REAL_ESTATE">{{ $t("form.assetType.opts.realEstate") }}</option>
                      <option value="VACATION_HOME">{{ $t("form.assetType.opts.vacationHome") }}</option>
                      <option value="SECURITIES">{{ $t("form.assetType.opts.securities") }}</option>
                      <option value="FOREST_OR_LAND_AREA">{{ $t("form.assetType.opts.forestOrLandArea") }}</option>
                      <option value="BANK_SAVINGS">{{ $t("form.assetType.opts.bankSavings") }}</option>
                    </select>
                    <div class="form-input-hint" v-if="submitted && !$v.assets.$each[key].type.required">{{ $t("formError.required") }}</div>
                  </div>

                  <!-- Varallisuuden määrä -->
                  <div class="row">
                    <div class="col col-12 col-sm-8 form-group" :class="{ 'has-error': submitted && $v.assets.$each[key].amount.$invalid }">
                      <label class="form-label" :for="'assetAmount-' + key">{{ $t("form.assetAmount.label") }}</label>
                      <div class="has-unit-right">
                        <input :id="'assetAmount-' + key" class="form-input" type="text" :value="item.amount" @input="setAssetItemAmount($event.target.value, key)">
                        <span class="form-unit">&euro;</span>
                      </div>
                      <div class="form-input-hint" v-if="submitted && !$v.assets.$each[key].amount.required">{{ $t("formError.required") }}</div>
                      <div class="form-input-hint" v-if="submitted && !$v.assets.$each[key].amount.between">{{ $t("formError.between", { min: $options.filters.currency($v.assets.$each[key].amount.$params.between.min, 0, ''), max: $options.filters.currency($v.assets.$each[key].amount.$params.between.max) }) }}</div>
                      <div class="form-input-hint" v-if="submitted && !$v.assets.$each[key].amount.integer">{{ $t("formError.integer") }}</div>
                    </div>
                  </div>

                  <!-- Poista -->
                  <div class="text-right">
                    <button :id="'assetDelete-' + key" class="btn btn-sm btn-outline-primary" @click.prevent="removeAssetItem(item)">
                      {{ $t("delete") }}
                    </button>
                  </div>
                </div>

                <!-- Lisää uusi -->
                <button id="assetAddNew" class="btn btn-sm btn-primary mt-2" @click.prevent="addAssetItem">
                  <Icon class="mr-1" name="add" />{{ $t("addNew") }}
                </button>
              </div>
            </div>

            <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-4">{{ $t("fourthStep.loans.title") }}</h2>
            <p class="mb-8">{{ $t("fourthStep.loans.text") }}</p>

            <!-- Onko sinulla olemassa olevia lainoja? -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.hasLoans.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label">{{ $t("form.hasLoans.label") }}</label>
              </div>
              <div class="col col-12 col-sm-7">
                <label class="form-button" for="hasLoans-true">
                  <input id="hasLoans-true" type="radio" name="hasLoans" :value="true" v-model="hasLoans" @change="resetLoans">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("yes") }}</span>
                </label>
                <label class="form-button" for="hasLoans-false">
                  <input id="hasLoans-false" type="radio" name="hasLoans" :value="false" v-model="hasLoans" @change="resetLoans">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("no") }}</span>
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.hasLoans.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <!-- Lisää uusi laina -->
            <div class="row" v-if="isLoansApplied">
              <div class="col col-12 col-sm-4 col-form-label pt-0">
                <label class="form-label">{{ $t("form.loan.label") }}</label>
              </div>

              <div class="col col-12 col-sm-8">
                <div class="bg-gray p-5 s-rounded mb-4" v-for="(item, key) in loans" :key="key">

                  <!-- Lainan tyyppi -->
                  <div class="form-group" :class="{ 'has-error': submitted && $v.loans.$each[key].type.$invalid }">
                    <label class="form-label" :for="'loanType-' + key">{{ $t("form.loanType.label") }}</label>
                    <select :id="'loanType-' + key" class="form-select" :value="item.type" @change="setLoanItemType($event.target.value, key)">
                      <option value="" disabled>{{ $t("select") }}</option>
                      <option value="MORTGAGE">{{ $t("form.loanType.opts.mortgage") }}</option>
                      <option value="VACATION_HOME_LOAN">{{ $t("form.loanType.opts.vacationHomeLoan") }}</option>
                      <option value="INVESTMENT_PROPERTY_LOAN">{{ $t("form.loanType.opts.investmentPropertyLoan") }}</option>
                      <option value="STUDENT_LOAN">{{ $t("form.loanType.opts.studentLoan") }}</option>
                      <option value="CAR">{{ $t("form.loanType.opts.car") }}</option>
                      <option value="CREDIT_CARD">{{ $t("form.loanType.opts.creditCard") }}</option>
                      <option value="CONSUMER_LOAN">{{ $t("form.loanType.opts.consumerLoan") }}</option>
                      <option value="INSTALMENT_LOAN">{{ $t("form.loanType.opts.installmentLoan") }}</option>
                      <option value="CREDIT_ACCOUNT">{{ $t("form.loanType.opts.creditAccount") }}</option>
                      <option value="HOUSING_COMPANY_LOAN">{{ $t("form.loanType.opts.housingCompanyLoan") }}</option>
                    </select>
                    <div class="form-input-hint" v-if="submitted && !$v.loans.$each[key].type.required">{{ $t("formError.required") }}</div>
                  </div>

                  <div class="row">
                    <!-- Lainan kuukausierä -->
                    <div class="col col-12 col-sm-6 form-group" :class="{ 'has-error': submitted && $v.loans.$each[key].monthlyInstallment.$invalid }">
                      <label class="form-label" :for="'loanMonthlyInstallment-' + key">{{ $t("form.loanMonthlyInstallment.label") }}</label>
                      <div class="has-unit-right">
                        <input :id="'loanMonthlyInstallment-' + key" class="form-input" type="text" :value="item.monthlyInstallment" @input="setLoanItemMonthlyInstallment($event.target.value, key)">
                        <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                      </div>
                      <div class="form-input-hint" v-if="submitted && !$v.loans.$each[key].monthlyInstallment.required">{{ $t("formError.required") }}</div>
                      <div class="form-input-hint" v-if="submitted && !$v.loans.$each[key].monthlyInstallment.between">{{ $t("formError.between", { min: $options.filters.currency($v.loans.$each[key].monthlyInstallment.$params.between.min, 0, ''), max: $options.filters.currency($v.loans.$each[key].monthlyInstallment.$params.between.max) }) }}</div>
                      <div class="form-input-hint" v-if="submitted && !$v.loans.$each[key].monthlyInstallment.integer">{{ $t("formError.integer") }}</div>
                      <div class="form-input-hint" v-if="submitted && !$v.loans.$each[key].monthlyInstallment.lessThanOrEqual">{{ $t("formError.monthlyInstallmentLessThanOrEqual") }}</div>
                    </div>

                    <!-- Lainan kokonaismäärä -->
                    <div class="col col-12 col-sm-6 form-group" :class="{ 'has-error': submitted && $v.loans.$each[key].amount.$invalid }">
                      <label class="form-label" :for="'loanAmount-' + key">{{ $t("form.loanTotalAmount.label") }}</label>
                      <div class="has-unit-right">
                        <input :id="'loanAmount-' + key" class="form-input" type="text" :value="item.amount" @input="setLoanItemAmount($event.target.value, key)">
                        <span class="form-unit">&euro;</span>
                      </div>
                      <div class="form-input-hint" v-if="submitted && !$v.loans.$each[key].amount.required">{{ $t("formError.required") }}</div>
                      <div class="form-input-hint" v-if="submitted && !$v.loans.$each[key].amount.between">{{ $t("formError.between", { min: $options.filters.currency($v.loans.$each[key].amount.$params.between.min, 0, ''), max: $options.filters.currency($v.loans.$each[key].amount.$params.between.max) }) }}</div>
                      <div class="form-input-hint" v-if="submitted && !$v.loans.$each[key].amount.integer">{{ $t("formError.integer") }}</div>
                    </div>
                  </div>

                  <!-- Laina maksetaan pois uudella lainalla -->
                  <div class="form-group">
                    <label class="form-checkbox" :for="'loanPaidOffWithAppliedLoan-' + key">
                      <input :id="'loanPaidOffWithAppliedLoan-' + key" type="checkbox" :name="'loanPaidOffWithAppliedLoan-' + key" :checked="item.paidOffWithAppliedLoan" @change="setLoanItemPaidOffWithAppliedLoan($event.target.checked, key)">
                      <i class="form-icon"></i>{{ $t("form.loanPaidOffWithAppliedLoan.label") }}
                    </label>
                  </div>

                  <!-- Lainan kilpailutus -->
                  <div class="form-group">
                    <label class="form-checkbox" :for="'loanRequestOffer-' + key">
                      <input :id="'loanRequestOffer-' + key" type="checkbox" :name="'loanRequestOffer-' + key" :checked="item.requestOffer" @change="setLoanItemRequestOffer($event.target.checked, key)">
                      <i class="form-icon"></i>{{ $t("form.loanRequestOffer.label") }}
                    </label>
                  </div>

                  <!-- Poista -->
                  <div class="text-right">
                    <button :id="'loanDelete-' + key" class="btn btn-sm btn-outline-primary" @click.prevent="removeLoanItem(item)">
                      {{ $t("delete") }}
                    </button>
                  </div>
                </div>

                <!-- Lisää uusi -->
                <button id="loanAddNew" class="btn btn-sm btn-primary mt-2" @click.prevent="addLoanItem">
                  <Icon class="mr-1" name="add" />{{ $t("addNew") }}
                </button>
              </div>
            </div>

            <h2 class="h3 heading-underline heading-underline-lg mt-12 mb-4">{{ $t("fourthStep.additionalInformation.title") }}</h2>
            <p class="mb-8">{{ $t("fourthStep.additionalInformation.text") }}</p>

            <!-- Lisätiedot -->
            <div class="form-group" :class="{ 'has-error': $v.additionalInformation.$error }">
              <label class="form-label" for="additionalInformation">{{ $t("form.additionalInformation.label") }}</label>
              <div class="form-label-hint">{{ $t("formHint.optional") }}</div>
              <textarea id="additionalInformation" class="form-input" name="additionalInformation" rows="4" :placeholder="$t('form.additionalInformation.placeholder')" v-model.trim="additionalInformation"></textarea>
              <div class="form-input-hint clearfix">
                <span v-if="submitted && !$v.additionalInformation.maxLength" class="float-left">{{ $t("formError.maxLength") }}</span>
                <span class="float-right">{{ $v.additionalInformation.$model.length}} / {{ $v.additionalInformation.$params.maxLength.max }}</span>
              </div>
            </div>

            <Toast v-if="submitted && $v.$invalid" error class="mt-8">
              <div class="row gx-4">
                <div class="col col-auto">
                  <Icon name="info" />
                </div>
                <div class="col">
                  {{ $t("formError.anyError") }}
                </div>
              </div>
            </Toast>

            <div class="d-flex justify-content-between mt-12">
              <router-link to="/3" id="fourthStepOnePrev" class="btn btn-lg btn-prev">
                {{ $t("prev") }}
              </router-link>
              <button id="fourthStepOneNext" type="submit" class="btn btn-lg btn-primary btn-next">
                {{ $t("next") }}<Icon class="ml-1" name="arrow-forward" />
              </button>
            </div>
          </form>

          <Footer />
        </div>

        <!-- Sidebar -->
        <div class="col col-12 col-md-3">
          <Stepper />
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { Api } from '@/api'
import NumberInput from '@/components/NumberInput.vue'
import Stepper from '@/components/Stepper.vue'
import moment from 'moment'
import { between, integer, maxLength, required, requiredIf } from 'vuelidate/lib/validators'
import { email, futureDate, lessThanOrEqual, pastDate, phoneNumber, zip } from '@/validators'

export default {
  name: 'fourth-step',
  components: {
    NumberInput,
    Stepper
  },
  data() {
    return {
      psd2DataSuccess: false,
      psd2DataError: false,
      submitted: false
    }
  },
  computed: {
    futureYears() {
      const currentYear = new Date().getFullYear()
      const startYear = currentYear
      const endYear = currentYear + 20
      return Array.from({length: endYear - (startYear - 1)}, (value, index) => startYear + index)
    },
    pastYears() {
      const currentYear = new Date().getFullYear()
      const startYear = currentYear - 60
      const endYear = currentYear
      return Array.from({length: endYear - (startYear - 1)}, (value, index) => startYear + index).reverse()
    },
    isAuthenticated() {
      return this.$store.state.isFirstApplicantAuthenticated
    },
    isTwoApplicants() {
      return this.$store.getters.isTwoApplicants
    },
    isFirstOwnerApplied() {
      return this.$store.getters.isFirstOwnerApplied
    },
    isEmploymentApplied() {
      return this.$store.getters.isEmploymentApplied.firstApplicant
    },
    isEmploymentEndApplied() {
      return this.$store.getters.isEmploymentEndApplied.firstApplicant
    },
    isAssetsApplied() {
      return this.$store.getters.isAssetsApplied.firstApplicant
    },
    isLoansApplied() {
      return this.$store.getters.isLoansApplied.firstApplicant
    },
    firstOwner: {
      get() {
        return this.$store.state.firstApplicant.firstOwner
      },
      set(value) {
        this.$store.commit('setFirstOwner', { value, applicant: 'firstApplicant' })
      }
    },
    ssn: {
      get() {
        return this.$store.state.firstApplicant.ssn
      },
      set(value) {
        this.$store.commit('setSSN', { value, applicant: 'firstApplicant' })
      }
    },
    firstName: {
      get() {
        return this.$store.state.firstApplicant.firstName
      },
      set(value) {
        this.$store.commit('setFirstName', { value, applicant: 'firstApplicant' })
      }
    },
    lastName: {
      get() {
        return this.$store.state.firstApplicant.lastName
      },
      set(value) {
        this.$store.commit('setLastName', { value, applicant: 'firstApplicant' })
      }
    },
    streetAddress: {
      get() {
        return this.$store.state.firstApplicant.address.streetAddress
      },
      set(value) {
        this.$store.commit('setStreetAddress', { value, applicant: 'firstApplicant' })
      }
    },
    zip: {
      get() {
        return this.$store.state.firstApplicant.address.zip
      },
      set(value) {
        this.$store.commit('setZip', { value, applicant: 'firstApplicant' })
      }
    },
    city: {
      get() {
        return this.$store.state.firstApplicant.address.city
      },
      set(value) {
        this.$store.commit('setCity', { value, applicant: 'firstApplicant' })
      }
    },
    phoneNumber: {
      get() {
        return this.$store.state.firstApplicant.phoneNumber
      },
      set(value) {
        this.$store.commit('setPhoneNumber', { value, applicant: 'firstApplicant' })
      }
    },
    email: {
      get() {
        return this.$store.state.firstApplicant.email
      },
      set(value) {
        this.$store.commit('setEmail', { value, applicant: 'firstApplicant' })
      }
    },
    maritalStatus: {
      get() {
        return this.$store.state.firstApplicant.maritalStatus
      },
      set(value) {
        this.$store.commit('setMaritalStatus', { value, applicant: 'firstApplicant' })
      }
    },
    statusOfHousing: {
      get() {
        return this.$store.state.firstApplicant.statusOfHousing
      },
      set(value) {
        this.$store.commit('setStatusOfHousing', { value, applicant: 'firstApplicant' })
      }
    },
    education: {
      get() {
        return this.$store.state.firstApplicant.education
      },
      set(value) {
        this.$store.commit('setEducation', { value, applicant: 'firstApplicant' })
      }
    },
    employment: {
      get() {
        return this.$store.state.firstApplicant.employment
      },
      set(value) {
        this.$store.commit('setEmployment', { value, applicant: 'firstApplicant' })
      }
    },
    employer: {
      get() {
        return this.$store.state.firstApplicant.employer
      },
      set(value) {
        this.$store.commit('setEmployer', { value, applicant: 'firstApplicant' })
      }
    },
    occupation: {
      get() {
        return this.$store.state.firstApplicant.occupation
      },
      set(value) {
        this.$store.commit('setOccupation', { value, applicant: 'firstApplicant' })
      }
    },
    socioeconomicStatus: {
      get() {
        return this.$store.state.firstApplicant.socioeconomicStatus
      },
      set(value) {
        this.$store.commit('setSocioeconomicStatus', { value, applicant: 'firstApplicant' })
      }
    },
    employmentStartDate() {
      return this.$store.getters.firstApplicantEmploymentStartDate
    },
    employmentStartMonth: {
      get() {
        return this.$store.state.firstApplicant.employmentStartMonth
      },
      set(value) {
        this.$store.commit('setEmploymentStartMonth', { value, applicant: 'firstApplicant' })
      }
    },
    employmentStartYear: {
      get() {
        return this.$store.state.firstApplicant.employmentStartYear
      },
      set(value) {
        this.$store.commit('setEmploymentStartYear', { value, applicant: 'firstApplicant' })
      }
    },
    employmentEndDate() {
      return this.$store.getters.firstApplicantEmploymentEndDate
    },
    employmentEndMonth: {
      get() {
        return this.$store.state.firstApplicant.employmentEndMonth
      },
      set(value) {
        this.$store.commit('setEmploymentEndMonth', { value, applicant: 'firstApplicant' })
      }
    },
    employmentEndYear: {
      get() {
        return this.$store.state.firstApplicant.employmentEndYear
      },
      set(value) {
        this.$store.commit('setEmploymentEndYear', { value, applicant: 'firstApplicant' })
      }
    },
    numberOfAdults: {
      get() {
        return this.$store.state.numberOfAdults
      },
      set(value) {
        this.$store.commit('setNumberOfAdults', value)
      }
    },
    numberOfChildren: {
      get() {
        return this.$store.state.numberOfChildren
      },
      set(value) {
        this.$store.commit('setNumberOfChildren', value)
      }
    },
    numberOfCars: {
      get() {
        return this.$store.state.numberOfCars
      },
      set(value) {
        this.$store.commit('setNumberOfCars', value)
      }
    },
    grossIncome: {
      get() {
        return this.$store.state.firstApplicant.grossIncome
      },
      set(value) {
        this.$store.commit('setGrossIncome', { value, applicant: 'firstApplicant' })
      }
    },
    netIncome: {
      get() {
        return this.$store.state.firstApplicant.netIncome
      },
      set(value) {
        this.$store.commit('setNetIncome', { value, applicant: 'firstApplicant' })
      }
    },
    childBenefitIncome: {
      get() {
        return this.$store.state.firstApplicant.childBenefitIncome
      },
      set(value) {
        this.$store.commit('setChildBenefitIncome', { value, applicant: 'firstApplicant' })
      }
    },
    childSupportIncome: {
      get() {
        return this.$store.state.firstApplicant.childSupportIncome
      },
      set(value) {
        this.$store.commit('setChildSupportIncome', { value, applicant: 'firstApplicant' })
      }
    },
    otherIncome: {
      get() {
        return this.$store.state.firstApplicant.otherIncome
      },
      set(value) {
        this.$store.commit('setOtherIncome', { value, applicant: 'firstApplicant' })
      }
    },
    dividendIncome: {
      get() {
        return this.$store.state.firstApplicant.dividendIncome
      },
      set(value) {
        this.$store.commit('setDividendIncome', { value, applicant: 'firstApplicant' })
      }
    },
    rentalIncome: {
      get() {
        return this.$store.state.firstApplicant.rentalIncome
      },
      set(value) {
        this.$store.commit('setRentalIncome', { value, applicant: 'firstApplicant' })
      }
    },
    otherCapitalIncome: {
      get() {
        return this.$store.state.firstApplicant.otherCapitalIncome
      },
      set(value) {
        this.$store.commit('setOtherCapitalIncome', { value, applicant: 'firstApplicant' })
      }
    },
    hasAssets: {
      get() {
        return this.$store.state.firstApplicant.hasAssets
      },
      set(value) {
        this.$store.commit('setHasAssets', { value, applicant: 'firstApplicant' })
      }
    },
    assets() {
      return this.$store.state.firstApplicant.assets
    },
    hasLoans: {
      get() {
        return this.$store.state.firstApplicant.hasLoans
      },
      set(value) {
        this.$store.commit('setHasLoans', { value, applicant: 'firstApplicant' })
      }
    },
    loans() {
      return this.$store.state.firstApplicant.loans
    },
    additionalInformation: {
      get() {
        return this.$store.state.firstApplicant.additionalInformation
      },
      set(value) {
        this.$store.commit('setAdditionalInformation', { value, applicant: 'firstApplicant' })
      }
    }
  },
  validations() {
    return {
      firstOwner: {
        required: requiredIf(() => this.isFirstOwnerApplied)
      },
      firstName: {
        required,
        maxLength: maxLength(40)
      },
      lastName: {
        required,
        maxLength: maxLength(40)
      },
      ssn: {
        required
      },
      streetAddress: {
        required,
        maxLength: maxLength(100)
      },
      zip: {
        required,
        maxLength: maxLength(100),
        zip
      },
      city: {
        required,
        maxLength: maxLength(100)
      },
      phoneNumber: {
        required,
        phoneNumber
      },
      email: {
        required,
        email
      },
      maritalStatus: {
        required
      },
      statusOfHousing: {
        required
      },
      education: {
        required
      },
      employment: {
        required: requiredIf(() => this.isEmploymentApplied)
      },
      employer: {
        required: requiredIf(() => this.isEmploymentApplied),
        ...this.isEmploymentApplied && {
          maxLength: maxLength(50)
        }
      },
      occupation: {
        required: requiredIf(() => this.isEmploymentApplied),
        ...this.isEmploymentApplied && {
          maxLength: maxLength(50)
        }
      },
      socioeconomicStatus: {
        required
      },
      employmentStartDate: {
        ...this.isEmploymentApplied && {
          pastDate: pastDate(
            'YYYY-MM-DD', moment().endOf('month').format('YYYY-MM-DD')
          )
        }
      },
      employmentStartMonth: {
        required: requiredIf(() => this.isEmploymentApplied)
      },
      employmentStartYear: {
        required: requiredIf(() => this.isEmploymentApplied)
      },
      employmentEndDate: {
        ...this.isEmploymentEndApplied && {
          futureDate: futureDate(
            'YYYY-MM-DD', moment().startOf('month').format('YYYY-MM-DD')
          )
        }
      },
      employmentEndMonth: {
        required: requiredIf(() => this.isEmploymentEndApplied)
      },
      employmentEndYear: {
        required: requiredIf(() => this.isEmploymentEndApplied)
      },
      numberOfAdults: {
        integer,
        between: between(1, 2),
        required
      },
      numberOfChildren: {
        integer,
        between: between(0, 15),
        required
      },
      numberOfCars: {
        integer,
        between: between(0, 3),
        required
      },
      grossIncome: {
        integer,
        between: between(0, 100000),
        required
      },
      netIncome: {
        integer,
        lessThanOrEqual: lessThanOrEqual('grossIncome'),
        between: between(0, 100000),
        required
      },
      dividendIncome: {
        integer,
        between: between(0, 100000)
      },
      rentalIncome: {
        integer,
        between: between(0, 100000)
      },
      otherCapitalIncome: {
        integer,
        between: between(0, 100000)
      },
      childBenefitIncome: {
        integer,
        between: between(0, 100000)
      },
      childSupportIncome: {
        integer,
        between: between(0, 100000)
      },
      otherIncome: {
        integer,
        between: between(0, 100000)
      },
      hasAssets: {
        required
      },
      assets: {
        $each: {
          type: {
            required: requiredIf(() => this.isAssetsApplied)
          },
          amount: {
            required: requiredIf(() => this.isAssetsApplied),
            ...this.isAssetsApplied && {
              integer,
              between: between(1, 2000000)
            }
          }
        }
      },
      hasLoans: {
        required
      },
      loans: {
        $each: {
          type: {
            required: requiredIf(() => this.isLoansApplied)
          },
          monthlyInstallment: {
            required: requiredIf(() => this.isLoansApplied),
            ...this.isLoansApplied && {
              integer,
              between: between(0, 100000),
              lessThanOrEqual: lessThanOrEqual('amount')
            }
          },
          amount: {
            required: requiredIf(() => this.isLoansApplied),
            ...this.isLoansApplied && {
              integer,
              between: between(1, 2000000)
            }
          }
        }
      },
      additionalInformation: {
        maxLength: maxLength(5000)
      }
    }
  },
  created() {
    this.$store.commit('setIsFourthStepOneSubmitted', false)

    if (this.$route.query.psd2Success) {
      this.psd2DataSuccess = true
      this.psd2DataError = false
    }

    if (this.$route.query.psd2Error) {
      this.psd2DataSuccess = false
      this.psd2DataError = true
    }
  },
  methods: {
    setAssetItemType(value, key) {
      this.$store.commit('setAssetItemType', {
        value,
        key,
        applicant: 'firstApplicant'
      })
    },
    setAssetItemAmount(value, key) {
      this.$store.commit('setAssetItemAmount', {
        value,
        key,
        applicant: 'firstApplicant'
      })
    },
    // Add new asset item
    addAssetItem() {
      this.$store.commit('addAssetItem', 'firstApplicant')
    },
    // Remove asset item
    removeAssetItem(item) {
      this.$store.commit('removeAssetItem', { value: item, applicant: 'firstApplicant' })
    },
    // Reset asset
    resetAssets() {
      this.$store.commit('resetAssets', 'firstApplicant')
    },
    setLoanItemType(value, key) {
      this.$store.commit('setLoanItemType', {
        value,
        key,
        applicant: 'firstApplicant'
      })
    },
    setLoanItemMonthlyInstallment(value, key) {
      this.$store.commit('setLoanItemMonthlyInstallment', {
        value,
        key,
        applicant: 'firstApplicant'
      })
    },
    setLoanItemAmount(value, key) {
      this.$store.commit('setLoanItemAmount', {
        value,
        key,
        applicant: 'firstApplicant'
      })
    },
    setLoanItemPaidOffWithAppliedLoan(value, key) {
      this.$store.commit('setLoanItemPaidOffWithAppliedLoan', {
        value,
        key,
        applicant: 'firstApplicant'
      })
    },
    setLoanItemRequestOffer(value, key) {
      this.$store.commit('setLoanItemRequestOffer', {
        value,
        key,
        applicant: 'firstApplicant'
      })
    },
    // Add new loan item
    addLoanItem() {
      this.$store.commit('addLoanItem', 'firstApplicant')
    },
    // Remove loan item
    removeLoanItem(item) {
      this.$store.commit('removeLoanItem', { value: item, applicant: 'firstApplicant' })
    },
    // Reset loans
    resetLoans() {
      this.$store.commit('resetLoans', 'firstApplicant')
    },
    getPsd2DataUrl() {
      Api.getPsd2DataUrl()
        .then((res) => {
          window.location.href = res.data.url
        })
    },
    handleSubmit() {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$store.commit('setIsFourthStepOneSubmitted', true)

        if (this.isTwoApplicants) {
          this.$router.push('4-2')
        } else {
          this.$router.push('5')
        }
      }
    }
  }
}
</script>
