<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    width="24px"
    viewBox="0 0 24 24"
    fill="currentColor"
    class="icon"
    role="img"
    :aria-label="name"
  >
    <component :is="`icon-${name}`" />
  </svg>
</template>

<script>
import IconAdd from'@/components/Icons/IconAdd.vue'
import IconArrowBack from'@/components/Icons/IconArrowBack.vue'
import IconArrowDownward from'@/components/Icons/IconArrowDownward.vue'
import IconArrowForward from'@/components/Icons/IconArrowForward.vue'
import IconArrowUpward from'@/components/Icons/IconArrowUpward.vue'
import IconCheck from'@/components/Icons/IconCheck.vue'
import IconClear from'@/components/Icons/IconClear.vue'
import IconHelp from'@/components/Icons/IconHelp.vue'
import IconInfo from'@/components/Icons/IconInfo.vue'
import IconLaunch from'@/components/Icons/IconLaunch.vue'
import IconLock from'@/components/Icons/IconLock.vue'
import IconLockOpen from'@/components/Icons/IconLockOpen.vue'
import IconRemove from'@/components/Icons/IconRemove.vue'
import IconSettings from'@/components/Icons/IconSettings.vue'
import IconTaskAlt from'@/components/Icons/IconTaskAlt.vue'

export default {
  name: 'icon',
  components: {
    IconAdd,
    IconArrowBack,
    IconArrowDownward,
    IconArrowForward,
    IconArrowUpward,
    IconCheck,
    IconClear,
    IconHelp,
    IconInfo,
    IconLaunch,
    IconLock,
    IconLockOpen,
    IconRemove,
    IconSettings,
    IconTaskAlt
  },
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>
