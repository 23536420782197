<template>
  <div id="main" class="fifth pt-12">
    <div class="container container-lg">
      <div class="row">
        <div class="col col-12 col-md-9">
          <form class="page" @submit.prevent="handleSubmit" autocomplete="off">

            <h2 class="h3 heading-underline heading-underline-lg mb-4">{{ $t("fifthStep.expenses.title") }}</h2>
            <p class="mb-8">{{ $t("fifthStep.expenses.text_1") }}</p>

            <div v-if="!isExpensesVisible" class="bg-gray p-4 s-rounded text-right mb-4">
              <div class="row">
                <div class="col col-12 col-sm-4"></div>
                <div class="col col-12 col-sm-8">
                  <h5>{{ $t("fifthStep.expensesDetails.title_1", { value: $options.filters.currency(totalExpensesDefault, 0, ` ${$t("eurosPerMonth")}`) }) }}</h5>
                  <p class="text-gray text-sm">{{ $t("fifthStep.expensesDetails.text_1") }}</p>
                  <button @click.prevent="showExpenses" class="btn btn-primary btn-sm">{{ $t("edit") }}</button>
                </div>
              </div>
            </div>

            <div v-if="isExpensesVisible" class="bg-gray p-4 s-rounded">
              <h5 class="mb-4">{{ $t("fifthStep.expensesDetails.title_2") }}</h5>

              <!-- Asumiskulut -->
              <div v-if="isExpensesVisible && isHousingExpensesApplied" class="p-3 bg-dark">
                <div class="form-group row gx-4 mb-0" :class="{ 'has-error': $v.housingExpenses.$error }">
                  <div class="col col-12 col-sm-9 col-form-label">
                    <label class="form-label" for="housingExpenses">
                      {{ $t("form.housingExpenses.label") }}
                      <span class="tooltip tooltip-info" :data-tooltip="$t('form.housingExpenses.info')">
                        <Icon name="help" />
                      </span>
                    </label>
                  </div>
                  <div class="col col-12 col-sm-3">
                    <div class="has-unit-right">
                      <input id="housingExpenses" class="form-input" type="text" name="housingExpenses" v-model.number="housingExpenses">
                      <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                    </div>
                    <div class="form-input-hint" v-if="submitted && !$v.housingExpenses.required">{{ $t("formError.required") }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.housingExpenses.between">{{ $t("formError.between", { min: $options.filters.currency($v.housingExpenses.$params.between.min, 0, ''), max: $options.filters.currency($v.housingExpenses.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.housingExpenses.integer">{{ $t("formError.integer") }}</div>
                  </div>
                </div>
              </div>

              <!-- Ajoneuvo- ja matkakulut -->
              <div v-if="isExpensesVisible" class="p-3">
                <div class="form-group row gx-4 mb-0" :class="{ 'has-error': $v.transportationExpenses.$error }">
                  <div class="col col-12 col-sm-9 col-form-label">
                    <label class="form-label" for="transportationExpenses">
                      {{ $t("form.transportationExpenses.label") }}
                      <span class="tooltip tooltip-info" :data-tooltip="$t('form.transportationExpenses.info')">
                        <Icon name="help" />
                      </span>
                    </label>
                  </div>
                  <div class="col col-12 col-sm-3">
                    <div class="has-unit-right">
                      <input id="transportationExpenses" class="form-input" type="text" name="transportationExpenses" v-model.number="transportationExpenses">
                      <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                    </div>
                    <div class="form-input-hint" v-if="submitted && !$v.transportationExpenses.required">{{ $t("formError.required") }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.transportationExpenses.between">{{ $t("formError.between", { min: $options.filters.currency($v.transportationExpenses.$params.between.min, 0, ''), max: $options.filters.currency($v.transportationExpenses.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.transportationExpenses.integer">{{ $t("formError.integer") }}</div>
                  </div>
                </div>
              </div>

              <!-- Ruoka- ja ruokailukulut -->
              <div v-if="isExpensesVisible" class="p-3 bg-dark">
                <div class="form-group row gx-4 mb-0" :class="{ 'has-error': $v.foodExpenses.$error }">
                  <div class="col col-12 col-sm-9 col-form-label">
                    <label class="form-label" for="foodExpenses">
                      {{ $t("form.foodExpenses.label") }}
                      <span class="tooltip tooltip-info" :data-tooltip="$t('form.foodExpenses.info')">
                        <Icon name="help" />
                      </span>
                    </label>
                  </div>
                  <div class="col col-12 col-sm-3">
                    <div class="has-unit-right">
                      <input id="foodExpenses" class="form-input" type="text" name="foodExpenses" v-model.number="foodExpenses">
                      <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                    </div>
                    <div class="form-input-hint" v-if="submitted && !$v.foodExpenses.required">{{ $t("formError.required") }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.foodExpenses.between">{{ $t("formError.between", { min: $options.filters.currency($v.foodExpenses.$params.between.min, 0, ''), max: $options.filters.currency($v.foodExpenses.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.foodExpenses.integer">{{ $t("formError.integer") }}</div>
                  </div>
                </div>
              </div>

              <!-- Lapset -->
              <div v-if="isExpensesVisible" class="p-3">
                <div class="form-group row gx-4 mb-0" :class="{ 'has-error': $v.childrenExpenses.$error }">
                  <div class="col col-12 col-sm-9 col-form-label">
                    <label class="form-label" for="childrenExpenses">
                      {{ $t("form.childrenExpenses.label") }}
                      <span class="tooltip tooltip-info" :data-tooltip="$t('form.childrenExpenses.info')">
                        <Icon name="help" />
                      </span>
                    </label>
                  </div>
                  <div class="col col-12 col-sm-3">
                    <div class="has-unit-right">
                      <input id="childrenExpenses" class="form-input" type="text" name="childrenExpenses" v-model.number="childrenExpenses">
                      <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                    </div>
                    <div class="form-input-hint" v-if="submitted && !$v.childrenExpenses.required">{{ $t("formError.required") }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.childrenExpenses.between">{{ $t("formError.between", { min: $options.filters.currency($v.childrenExpenses.$params.between.min, 0, ''), max: $options.filters.currency($v.childrenExpenses.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.childrenExpenses.integer">{{ $t("formError.integer") }}</div>
                  </div>
                </div>
              </div>

              <!-- Vapaa-aika ja harrastukset -->
              <div v-if="isExpensesVisible" class="p-3 bg-dark">
                <div class="form-group row gx-4 mb-0" :class="{ 'has-error': $v.leisureExpenses.$error }">
                  <div class="col col-12 col-sm-9 col-form-label">
                    <label class="form-label" for="leisureExpenses">
                      {{ $t("form.leisureExpenses.label") }}
                      <span class="tooltip tooltip-info" :data-tooltip="$t('form.leisureExpenses.info')">
                        <Icon name="help" />
                      </span>
                    </label>
                  </div>
                  <div class="col col-12 col-sm-3">
                    <div class="has-unit-right">
                      <input id="leisureExpenses" class="form-input" type="text" name="leisureExpenses" v-model.number="leisureExpenses">
                      <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                    </div>
                    <div class="form-input-hint" v-if="submitted && !$v.leisureExpenses.required">{{ $t("formError.required") }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.leisureExpenses.between">{{ $t("formError.between", { min: $options.filters.currency($v.leisureExpenses.$params.between.min, 0, ''), max: $options.filters.currency($v.leisureExpenses.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.leisureExpenses.integer">{{ $t("formError.integer") }}</div>
                  </div>
                </div>
              </div>

              <!-- Vaatteet ja jalkineet -->
              <div v-if="isExpensesVisible" class="p-3">
                <div class="form-group row gx-4 mb-0" :class="{ 'has-error': $v.clothingExpenses.$error }">
                  <div class="col col-12 col-sm-9 col-form-label">
                    <label class="form-label" for="clothingExpenses">
                      {{ $t("form.clothingExpenses.label") }}
                      <span class="tooltip tooltip-info" :data-tooltip="$t('form.clothingExpenses.info')">
                        <Icon name="help" />
                      </span>
                    </label>
                  </div>
                  <div class="col col-12 col-sm-3">
                    <div class="has-unit-right">
                      <input id="clothingExpenses" class="form-input" type="text" name="clothingExpenses" v-model.number="clothingExpenses">
                      <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                    </div>
                    <div class="form-input-hint" v-if="submitted && !$v.clothingExpenses.required">{{ $t("formError.required") }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.clothingExpenses.between">{{ $t("formError.between", { min: $options.filters.currency($v.clothingExpenses.$params.between.min, 0, ''), max: $options.filters.currency($v.clothingExpenses.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.clothingExpenses.integer">{{ $t("formError.integer") }}</div>
                  </div>
                </div>
              </div>

              <!-- Terveyden- ja kauneudenhoito -->
              <div v-if="isExpensesVisible" class="p-3 bg-dark">
                <div class="form-group row gx-4 mb-0" :class="{ 'has-error': $v.healthExpenses.$error }">
                  <div class="col col-12 col-sm-9 col-form-label">
                    <label class="form-label" for="healthExpenses">
                      {{ $t("form.healthExpenses.label") }}
                      <span class="tooltip tooltip-info" :data-tooltip="$t('form.healthExpenses.info')">
                        <Icon name="help" />
                      </span>
                    </label>
                  </div>
                  <div class="col col-12 col-sm-3">
                    <div class="has-unit-right">
                      <input id="healthExpenses" class="form-input" type="text" name="healthExpenses" v-model.number="healthExpenses">
                      <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                    </div>
                    <div class="form-input-hint" v-if="submitted && !$v.healthExpenses.required">{{ $t("formError.required") }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.healthExpenses.between">{{ $t("formError.between", { min: $options.filters.currency($v.healthExpenses.$params.between.min, 0, ''), max: $options.filters.currency($v.healthExpenses.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.healthExpenses.integer">{{ $t("formError.integer") }}</div>
                  </div>
                </div>
              </div>

              <!-- Vakuutukset -->
              <div v-if="isExpensesVisible" class="p-3">
                <div class="form-group row gx-4 mb-0" :class="{ 'has-error': $v.insuranceExpenses.$error }">
                  <div class="col col-12 col-sm-9 col-form-label">
                    <label class="form-label" for="insuranceExpenses">
                      {{ $t("form.insuranceExpenses.label") }}
                      <span class="tooltip tooltip-info" :data-tooltip="$t('form.insuranceExpenses.info')">
                        <Icon name="help" />
                      </span>
                    </label>
                  </div>
                  <div class="col col-12 col-sm-3">
                    <div class="has-unit-right">
                      <input id="insuranceExpenses" class="form-input" type="text" name="insuranceExpenses" v-model.number="insuranceExpenses">
                      <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                    </div>
                    <div class="form-input-hint" v-if="submitted && !$v.insuranceExpenses.required">{{ $t("formError.required") }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.insuranceExpenses.between">{{ $t("formError.between", { min: $options.filters.currency($v.insuranceExpenses.$params.between.min, 0, ''), max: $options.filters.currency($v.insuranceExpenses.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.insuranceExpenses.integer">{{ $t("formError.integer") }}</div>
                  </div>
                </div>
              </div>

              <!-- Muut menot -->
              <div v-if="isExpensesVisible" class="p-3 bg-dark">
                <div class="form-group row gx-4 mb-0" :class="{ 'has-error': $v.otherExpenses.$error }">
                  <div class="col col-12 col-sm-9 col-form-label">
                    <label class="form-label" for="otherExpenses">
                      {{ $t("form.otherExpenses.label") }}
                      <span class="tooltip tooltip-info" :data-tooltip="$t('form.otherExpenses.info')">
                        <Icon name="help" />
                      </span>
                    </label>
                  </div>
                  <div class="col col-12 col-sm-3">
                    <div class="has-unit-right">
                      <input id="otherExpenses" class="form-input" type="text" name="otherExpenses" v-model.number="otherExpenses">
                      <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                    </div>
                    <div class="form-input-hint" v-if="submitted && !$v.otherExpenses.required">{{ $t("formError.required") }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.otherExpenses.between">{{ $t("formError.between", { min: $options.filters.currency($v.otherExpenses.$params.between.min, 0, ''), max: $options.filters.currency($v.otherExpenses.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                    <div class="form-input-hint" v-if="submitted && !$v.otherExpenses.integer">{{ $t("formError.integer") }}</div>
                  </div>
                </div>
              </div>

              <div class="bg-white py-4 px-3 text-sm-right border-top border-lg border-black">
                <!-- Yhteensä -->
                <div class="row mb-1">
                  <div class="col col-12 col-sm-9">
                    <div class="h4 text-weight-semibold">{{ $t("altogether") }}</div>
                  </div>
                  <div class="col col-12 col-sm-3">
                    <div class="h4 text-weight-semibold">{{ totalExpenses | currency(0, ` ${$t("eurosPerMonth")}`) }}</div>
                  </div>
                </div>

                <!-- Yhteensä (alkuperäinen) -->
                <div class="row mb-1">
                  <div class="col col-12 col-sm-9">
                    {{ $t("fifthStep.expensesDetails.text_6") }}
                  </div>
                  <div class="col col-12 col-sm-3">
                    {{ totalExpensesDefault | currency(0, ` ${$t("eurosPerMonth")}`) }}
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col col-12 col-sm-4"></div>
                <div class="col col-12 col-sm-8">
                  <div class="text-gray text-sm text-sm-right">{{ $t("fifthStep.expensesDetails.text_1") }}</div>
                </div>
              </div>
            </div>

            <Toast v-if="submitted && $v.$invalid" error class="mt-8">
              <div class="row gx-4">
                <div class="col col-auto">
                  <Icon name="info" />
                </div>
                <div class="col">
                  {{ $t("formError.anyError") }}
                </div>
              </div>
            </Toast>

            <div class="d-flex justify-content-between mt-12">
              <router-link v-if="isTwoApplicants" to="/4-2" id="fifthStepPrev" class="btn btn-lg btn-prev">
                {{ $t("prev") }}
              </router-link>
              <router-link v-else to="/4-1" id="fifthStepPrev" class="btn btn-lg btn-prev">
                {{ $t("prev") }}
              </router-link>
              <button id="fifthStepNext" type="submit" class="btn btn-lg btn-primary btn-next">
                {{ $t("next") }}<Icon class="ml-1" name="arrow-forward" />
              </button>
            </div>
          </form>

          <Footer />
        </div>

        <!-- Sidebar -->
        <div class="col col-12 col-md-3">
          <Stepper />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Stepper from '@/components/Stepper.vue'
import { between, integer, required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'fifth-step',
  components: {
    Stepper
  },
  data() {
    return {
      isExpensesVisible: false,
      submitted: false
    }
  },
  computed: {
    isTwoApplicants() {
      return this.$store.getters.isTwoApplicants
    },
    isHousingExpensesApplied() {
      return this.$store.getters.isHousingExpensesApplied
    },
    housingExpensesDefault() {
      return this.$store.getters.housingExpensesDefault
    },
    transportationExpensesDefault() {
      return this.$store.getters.transportationExpensesDefault
    },
    foodExpensesDefault() {
      return this.$store.getters.foodExpensesDefault
    },
    childrenExpensesDefault() {
      return this.$store.getters.childrenExpensesDefault
    },
    leisureExpensesDefault() {
      return this.$store.getters.leisureExpensesDefault
    },
    clothingExpensesDefault() {
      return this.$store.getters.clothingExpensesDefault
    },
    healthExpensesDefault() {
      return this.$store.getters.healthExpensesDefault
    },
    insuranceExpensesDefault() {
      return this.$store.getters.insuranceExpensesDefault
    },
    otherExpensesDefault() {
      return this.$store.getters.otherExpensesDefault
    },
    totalExpensesDefault() {
      return this.$store.getters.totalExpensesDefault
    },
    totalExpenses() {
      return this.$store.getters.totalExpenses
    },
    numberOfAdults: {
      get() {
        return this.$store.state.numberOfAdults
      },
      set(value) {
        this.$store.commit('setNumberOfAdults', value)
      }
    },
    numberOfChildren: {
      get() {
        return this.$store.state.numberOfChildren
      },
      set(value) {
        this.$store.commit('setNumberOfChildren', value)
      }
    },
    numberOfCars: {
      get() {
        return this.$store.state.numberOfCars
      },
      set(value) {
        this.$store.commit('setNumberOfCars', value)
      }
    },
    housingExpenses: {
      get() {
        return this.$store.state.housingExpenses
      },
      set(value) {
        this.$store.commit('setHousingExpenses', value)
      }
    },
    transportationExpenses: {
      get() {
        return this.$store.state.transportationExpenses
      },
      set(value) {
        this.$store.commit('setTransportationExpenses', value)
      }
    },
    foodExpenses: {
      get() {
        return this.$store.state.foodExpenses
      },
      set(value) {
        this.$store.commit('setFoodExpenses', value)
      }
    },
    childrenExpenses: {
      get() {
        return this.$store.state.childrenExpenses
      },
      set(value) {
        this.$store.commit('setChildrenExpenses', value)
      }
    },
    leisureExpenses: {
      get() {
        return this.$store.state.leisureExpenses
      },
      set(value) {
        this.$store.commit('setLeisureExpenses', value)
      }
    },
    clothingExpenses: {
      get() {
        return this.$store.state.clothingExpenses
      },
      set(value) {
        this.$store.commit('setClothingExpenses', value)
      }
    },
    healthExpenses: {
      get() {
        return this.$store.state.healthExpenses
      },
      set(value) {
        this.$store.commit('setHealthExpenses', value)
      }
    },
    insuranceExpenses: {
      get() {
        return this.$store.state.insuranceExpenses
      },
      set(value) {
        this.$store.commit('setInsuranceExpenses', value)
      }
    },
    otherExpenses: {
      get() {
        return this.$store.state.otherExpenses
      },
      set(value) {
        this.$store.commit('setOtherExpenses', value)
      }
    }
  },
  validations() {
    return {
      numberOfAdults: {
        integer,
        between: between(1, 2),
        required
      },
      numberOfChildren: {
        integer,
        between: between(0, 15),
        required
      },
      numberOfCars: {
        integer,
        between: between(0, 3),
        required
      },
      housingExpenses: {
        required: requiredIf(() => this.isHousingExpensesApplied),
        ...this.isHousingExpensesApplied && {
          integer,
          between: between(0, 10000)
        }
      },
      transportationExpenses: {
        required,
        integer,
        between: between(0, 10000)
      },
      foodExpenses: {
        required,
        integer,
        between: between(0, 10000)
      },
      childrenExpenses: {
        required,
        integer,
        between: between(0, 10000)
      },
      leisureExpenses: {
        required,
        integer,
        between: between(0, 10000)
      },
      clothingExpenses: {
        required,
        integer,
        between: between(0, 10000)
      },
      healthExpenses: {
        required,
        integer,
        between: between(0, 10000)
      },
      insuranceExpenses: {
        required,
        integer,
        between: between(0, 10000)
      },
      otherExpenses: {
        required,
        integer,
        between: between(0, 10000)
      }
    }
  },
  created() {
    this.$store.commit('setIsFifthStepSubmitted', false)

    if (this.housingExpenses == null && this.isHousingExpensesApplied) {
      this.housingExpenses = this.housingExpensesDefault
    }
    if (this.transportationExpenses == null) {
      this.transportationExpenses = this.transportationExpensesDefault
    }
    if (this.foodExpenses == null) {
      this.foodExpenses = this.foodExpensesDefault
    }
    if (this.clothingExpenses == null) {
      this.clothingExpenses = this.clothingExpensesDefault
    }
    if (this.healthExpenses == null) {
      this.healthExpenses = this.healthExpensesDefault
    }
    if (this.leisureExpenses == null) {
      this.leisureExpenses = this.leisureExpensesDefault
    }
    if (this.childrenExpenses == null) {
      this.childrenExpenses = this.childrenExpensesDefault
    }
    if (this.insuranceExpenses == null) {
      this.insuranceExpenses = this.insuranceExpensesDefault
    }
    if (this.otherExpenses == null) {
      this.otherExpenses = this.otherExpensesDefault
    }
  },
  methods: {
    showExpenses() {
      this.isExpensesVisible = true
    },
    handleSubmit() {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$store.commit('setIsFifthStepSubmitted', true)
        this.$router.push('yhteenveto')
      }
    }
  }
}
</script>
