<template>
  <div id="main" class="fourth">

    <div class="hero hero-sm">
      <div class="container container-lg">
        <h1 class="hero-title heading heading-lg mb-4">{{ $t("fourthStep.hero.title_2") }}</h1>
        <p class="col-12 col-md-9 hero-text text-xl">{{ $t("fourthStep.hero.text_2") }}</p>
      </div>
    </div>

    <div class="container container-lg">
      <div class="row">
        <div class="col col-12 col-md-9">
          <form @submit.prevent="handleSubmit" autocomplete="off">
            <div class="page mb-8">
              <h2 class="h3 heading-underline heading-underline-lg mb-8">{{ $t("fourthStep.personalDetails.title") }}</h2>

              <!-- Etunimi -->
              <div class="form-group row gx-4" :class="{ 'has-error': $v.firstName.$error }">
                <div class="col col-12 col-sm-4 col-form-label">
                  <label class="form-label" for="firstName">{{ $t("form.firstName.label") }}</label>
                </div>
                <div class="col col-12 col-sm-6">
                  <input id="firstName" class="form-input" type="text" name="firstName" v-model.trim="firstName">
                  <div class="form-input-hint" v-if="submitted && !$v.firstName.required">{{ $t("formError.required") }}</div>
                  <div class="form-input-hint" v-if="submitted && !$v.firstName.maxLength">{{ $t("formError.maxLength") }}</div>
                </div>
              </div>

              <!-- Sukunimi -->
              <div class="form-group row gx-4" :class="{ 'has-error': $v.lastName.$error }">
                <div class="col col-12 col-sm-4 col-form-label">
                  <label class="form-label" for="lastName">{{ $t("form.lastName.label") }}</label>
                </div>
                <div class="col col-12 col-sm-6">
                  <input id="lastName" class="form-input" type="text" name="lastName" v-model.trim="lastName">
                  <div class="form-input-hint" v-if="submitted && !$v.lastName.required">{{ $t("formError.required") }}</div>
                  <div class="form-input-hint" v-if="submitted && !$v.lastName.maxLength">{{ $t("formError.maxLength") }}</div>
                </div>
              </div>

              <!-- Henkilötunnus -->
              <div class="form-group row gx-4" :class="{ 'has-error': $v.ssn.$error }">
                <div class="col col-12 col-sm-4 col-form-label">
                  <label class="form-label" for="ssn">{{ $t("form.ssn.label") }}</label>
                </div>
                <div class="col col-12 col-sm-4">
                  <input id="ssn" class="form-input" type="text" name="ssn" v-model.trim="ssn">
                  <div class="form-input-hint" v-if="submitted && !$v.ssn.required">{{ $t("formError.required") }}</div>
                  <div class="form-input-hint" v-if="submitted && !$v.ssn.ssn">{{ $t("formError.ssn") }}</div>
                  <div class="form-input-hint" v-if="submitted && !$v.ssn.ssnNotSameAs">{{ $t("formError.ssnNotSameAs") }}</div>
                </div>
              </div>

              <!-- Puhelinnumero -->
              <div class="form-group row gx-4" :class="{ 'has-error': $v.phoneNumber.$error }">
                <div class="col col-12 col-sm-4 col-form-label">
                  <label class="form-label" for="phoneNumber">
                    {{ $t("form.phoneNumber.label") }}
                    <span class="tooltip tooltip-info" :data-tooltip="$t('form.phoneNumber.info')">
                      <Icon name="help" />
                    </span>
                  </label>
                </div>
                <div class="col col-12 col-sm-6">
                  <input id="phoneNumber" class="form-input" type="text" name="phoneNumber" placeholder="+358501234567" v-model.trim="phoneNumber">
                  <div class="form-input-hint" v-if="submitted && !$v.phoneNumber.required">{{ $t("formError.required") }}</div>
                  <div class="form-input-hint" v-if="submitted && !$v.phoneNumber.phoneNumber">{{ $t("formError.phoneNumber") }}</div>
                </div>
              </div>

              <!-- Sähköposti -->
              <div class="form-group row gx-4" :class="{ 'has-error': $v.email.$error }">
                <div class="col col-12 col-sm-4 col-form-label">
                  <label class="form-label" for="email">{{ $t("form.email.label") }}</label>
                </div>
                <div class="col col-12 col-sm-6">
                  <input id="email" class="form-input" type="text" name="email" v-model.trim="email">
                  <div class="form-input-hint" v-if="submitted && !$v.email.required">{{ $t("formError.required") }}</div>
                  <div class="form-input-hint" v-if="submitted && !$v.email.email">{{ $t("formError.email") }}</div>
                </div>
              </div>

              <Toast v-if="submitted && $v.$invalid" error class="mt-8">
                <div class="row gx-4">
                  <div class="col col-auto">
                    <Icon name="info" />
                  </div>
                  <div class="col">
                    {{ $t("formError.anyError") }}
                  </div>
                </div>
              </Toast>

              <div class="d-flex justify-content-between mt-12">
                <router-link to="/4-1" id="fourthStepTwoPrev" class="btn btn-lg btn-prev">
                  {{ $t("prev") }}
                </router-link>
                <button id="fourthStepTwoNext" type="submit" class="btn btn-lg btn-primary btn-next">
                  {{ $t("next") }}<Icon class="ml-1" name="arrow-forward" />
                </button>
              </div>
            </div>
          </form>

          <Footer />
        </div>

        <!-- Sidebar -->
        <div class="col col-12 col-md-3">
          <Stepper />
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Stepper from '@/components/Stepper.vue'
import { maxLength, not, required, sameAs } from 'vuelidate/lib/validators'
import {email, phoneNumber, ssn} from '@/validators'

export default {
  name: 'second-applicant-step',
  components: {
    Stepper
  },
  data() {
    return {
      isSubmitError: false,
      submitted: false
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.isSecondApplicantAuthenticated
    },
    ssn: {
      get() {
        return this.$store.state.secondApplicant.ssn
      },
      set(value) {
        this.$store.commit('setSSN', { value, applicant: 'secondApplicant' })
      }
    },
    firstName: {
      get() {
        return this.$store.state.secondApplicant.firstName
      },
      set(value) {
        this.$store.commit('setFirstName', { value, applicant: 'secondApplicant' })
      }
    },
    lastName: {
      get() {
        return this.$store.state.secondApplicant.lastName
      },
      set(value) {
        this.$store.commit('setLastName', { value, applicant: 'secondApplicant' })
      }
    },
    phoneNumber: {
      get() {
        return this.$store.state.secondApplicant.phoneNumber
      },
      set(value) {
        this.$store.commit('setPhoneNumber', { value, applicant: 'secondApplicant' })
      }
    },
    email: {
      get() {
        return this.$store.state.secondApplicant.email
      },
      set(value) {
        this.$store.commit('setEmail', { value, applicant: 'secondApplicant' })
      }
    }
  },
  validations() {
    return {
      firstName: {
        required,
        maxLength: maxLength(40)
      },
      lastName: {
        required,
        maxLength: maxLength(40)
      },
      ssn: {
        required,
        ssn,
        ssnNotSameAs: not(sameAs(() => this.$store.state.firstApplicant.ssn))
      },
      phoneNumber: {
        required,
        phoneNumber
      },
      email: {
        required,
        email
      }
    }
  },
  created() {
    this.$store.commit('setIsSubmitting', false)
    this.$store.commit('setIsFourthStepTwoSubmitted', false)
  },
  methods: {
    handleSubmit() {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$store.commit('setIsFourthStepTwoSubmitted', true)
        this.$router.push('5')
      }
    }
  }
}
</script>
