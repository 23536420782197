<template>
  <div id="main" class="login">
    <div class="hero hero-sm text-center">
      <div class="container container-lg">
        <h1 class="hero-title heading heading-lg mb-4" v-html="$t('loginStep.hero.title')"></h1>
        <p class="hero-text text-xl">{{ $t("loginStep.hero.text") }}</p>
      </div>
    </div>

    <div class="container container-lg">
      <div class="page">
        <div class="row g-4">
          <div class="col col-6 col-sm-4 col-lg-3" v-for="(idp, index) in identityProviders" :key="index">
            <a @click.prevent="login(idp)" href="#" :id="idp.name" class="identity">
              <figure class="identity-img">
                <img :src="getImgUrl(idp.name)" :alt="$t(`auth.identityProvider.${idp.name}`)">
              </figure>
              <div class="identity-text">{{ $t(`auth.identityProvider.${idp.name}`) }}</div>
            </a>
          </div>
        </div>
      </div>

      <div class="text-center my-8">
        <router-link id="loginGoBack" :to="prevPath">
          {{ $t('goBack') }}
        </router-link>
      </div>

      <Footer />

    </div>
  </div>
</template>

<script>
import { Auth } from '@/api'
import { Utils } from '@/utils/utils'

export default {
  name: 'login-view',
  data() {
    return {
      isProduction: process.env.VUE_APP_ENV === 'prod',
      identityProviders: [],
      nextPath: '/',
      prevPath: '/',
      reason: '',
      editToken: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevPath = from.path
      vm.nextPath = to.query.nextPath || from.path
      vm.reason = to.query.reason
      vm.editToken = to.query.editToken
    })
  },
  created() {
    Auth.getIdentityProviders()
      .then(({ data }) => {
        this.identityProviders = this.isProduction ?
          data.filter(idp => ![ 'ftn-portal', 'dummy' ].includes(idp.name)) :
          data
      })
  },
  methods: {
    getImgUrl(idpName) {
      try {
        return require(`@/assets/img/idp/${idpName}.png`)
      } catch (err) {
        return require(`@/assets/img/idp/fallback.png`)
      }
    },
    login(idp) {
      const reason = this.reason
      const idpId = idp.id
      const callbackUrl = `${window.location.origin}/#${this.nextPath}`
      const editToken = this.editToken

      Auth.login(reason, idpId, callbackUrl, editToken)
        .then(({ data }) => {
          this.$store.commit('setAuthSource', 'ftn')
          this.$store.commit('setAuthIdp', idp.name)
          const form = document.createElement('form')
          form.method = 'POST'
          form.action = `${data.url}?${Utils.encodeURLQueryString(data.parameters)}`
          document.body.appendChild(form)
          form.submit()
        })
        .catch(() => {
          this.$router.push({ name: 'error' })
        })
    }
  }
}
</script>
