<template>
  <div id="main" class="intro">

    <div class="hero bg-light">
      <div class="container container-lg">
        <h1 class="hero-title heading heading-lg mb-4 text-center col-12 col-sm-9 col-mx-auto">{{ $t("introStep.hero.title") }}</h1>
        <p class="hero-text text-xl text-center col-12 col-sm-9 col-mx-auto">{{ $t("introStep.hero.text") }}</p>

        <div v-if="isAuthenticated" class="text-center mt-12">
          <button @click="handleSubmit" id="introNext" type="button" class="btn btn-lg btn-primary">
            {{ $t("next") }}<Icon class="ml-1" name="arrow-forward" />
          </button>
        </div>

        <div v-else class="text-center mt-12">
          <button @click="handleSubmitAndLogin" id="introNextAuth" type="button" class="btn btn-lg btn-primary">
            {{ $t("auth.loginAndContinue") }}<Icon class="ml-1" name="arrow-forward" />
          </button>
        </div>
      </div>
    </div>

    <div class="container container-lg">
      <Footer />
    </div>
  </div>
</template>

<script>
import { Utils } from '@/utils/utils'

export default {
  name: 'intro-view',
  computed: {
    isAuthenticated() {
      return this.$store.state.isSecondApplicantAuthenticated
    },
    editToken: {
      get() {
        return this.$store.state.editToken
      }
    }
  },
  created() {
    if (Utils.isEmptyString(this.editToken)) {
      const editToken = Utils.getURLQueryParam('editToken')
      this.$store.commit('setEditToken', editToken)
      this.$store.dispatch('getApplicationId')
    }
  },
  methods: {
    handleSubmitAndLogin() {
      this.$router.push({
        name: 'login',
        query: {
          nextPath: '/vahvistus',
          reason: 'EDIT_TOKEN',
          editToken: this.editToken
        }
      })
    },
    handleSubmit() {
      this.$router.push({ name: 'confirmationStep' })
    }
  }
}
</script>
