<template>
  <div class="form-group row">
    <div class="col col-12">
      <label :for="`loan-time-input`" class="form-label">
        {{ $t('mortgageCalculator.label.loanTime') }}
      </label>
    </div>

    <div class="col col-12">
      <NumberInput
        :id="`loan-time-input`"
        :value="value"
        :hasError="hasError"
        :unitRight="$t('years')"
        @input="onInput"
        v-text-select
        v-min-number="1"
        v-max-number="25"
      />

      <FormInputHints :hasError="hasError" :rules="rules"></FormInputHints>
    </div>

    <div class="col col-12 d-none">
      <Slider
        class="mt-6 mb-8"
        :ref="`loan-time-slider`"
        :id="`loan-time-slider`"
        :config="{
          step: 1,
          connect: 'lower',
          range: {
            min: 1,
            max: 25
          },
          format: $wNumb({ decimals: 0 }),
          pips: {
            mode: 'values',
            values: [ 1, 25 ],
            density: 8,
            format: {
              to: function(val) {
                return `${val} ${$t('yearsAbbr')}`
              }
            }
          }
        }"
        :values="[ value ]"
        @input="onSliderInput"
      />
    </div>
  </div>
</template>

<script>
import FormInputHints from '@/components/Forms/FormInputHints.vue'
import NumberInput from '@/components/Forms/NumberInput.vue'
import Slider from '@/components/Slider.vue'

export default {
  components: {
    FormInputHints,
    NumberInput,
    Slider
  },
  props: {
    value: {
      type: [ Number, String ],
      default: 0
    },
    rules: {
      type: Array,
      default: () => []
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(val) {
      this.$refs[`loan-time-slider`].updateValue(val)
      this.$emit('input', val)
    },
    onSliderInput(val) {
      this.$emit('input', val)
    }
  }
}
</script>
