var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col col-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"loan-time-input"}},[_vm._v(" "+_vm._s(_vm.$t('mortgageCalculator.label.loanTime'))+" ")])]),_c('div',{staticClass:"col col-12"},[_c('NumberInput',{directives:[{name:"text-select",rawName:"v-text-select"},{name:"min-number",rawName:"v-min-number",value:(1),expression:"1"},{name:"max-number",rawName:"v-max-number",value:(25),expression:"25"}],attrs:{"id":"loan-time-input","value":_vm.value,"hasError":_vm.hasError,"unitRight":_vm.$t('years')},on:{"input":_vm.onInput}}),_c('FormInputHints',{attrs:{"hasError":_vm.hasError,"rules":_vm.rules}})],1),_c('div',{staticClass:"col col-12 d-none"},[_c('Slider',{ref:"loan-time-slider",staticClass:"mt-6 mb-8",attrs:{"id":"loan-time-slider","config":{
        step: 1,
        connect: 'lower',
        range: {
          min: 1,
          max: 25
        },
        format: _vm.$wNumb({ decimals: 0 }),
        pips: {
          mode: 'values',
          values: [ 1, 25 ],
          density: 8,
          format: {
            to: function(val) {
              return (val + " " + (_vm.$t('yearsAbbr')))
            }
          }
        }
      },"values":[ _vm.value ]},on:{"input":_vm.onSliderInput}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }