var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col col-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"loan-amount-input"}},[_vm._v(" "+_vm._s(_vm.$t('mortgageCalculator.label.loanAmount'))+" ")])]),_c('div',{staticClass:"col col-12"},[_c('NumberInput',{directives:[{name:"text-select",rawName:"v-text-select"},{name:"min-number",rawName:"v-min-number",value:(10000),expression:"10000"},{name:"max-number",rawName:"v-max-number",value:(2000000),expression:"2000000"}],attrs:{"id":"loan-amount-input","value":_vm.value,"hasError":_vm.hasError,"unitRight":'€'},on:{"input":_vm.onInput}}),_c('FormInputHints',{attrs:{"hasError":_vm.hasError,"rules":_vm.rules}})],1),_c('div',{staticClass:"col col-12 d-none"},[_c('Slider',{ref:"loan-amount-slider",staticClass:"mt-6 mb-8",attrs:{"id":"loan-amount-slider","config":{
        step: 1000,
        connect: 'lower',
        range: {
          'min': [ 10000 ],
          '25%': [ 150000, 1000 ],
          '50%': [ 500000, 1000 ],
          '75%': [ 1000000, 1000 ],
          'max': [ 2000000 ]
        },
        format: _vm.$wNumb({ decimals: 0 }),
        pips: {
          mode: 'values',
          values: [ 10000, 2000000 ],
          density: 8,
          format: {
            to: function(val) {
              return _vm.$options.filters.currency(val)
            }
          }
        }
      },"values":[ _vm.value ]},on:{"input":_vm.onSliderInput}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }