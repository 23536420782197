<template>
  <div :ref="id" :id="id" :class="classes" role="dialog" :data-dismiss="id" @keydown="onKeydown">
    <div class="modal-dialog">
      <div class="modal-content">
        <button type="button" class="btn btn-clear modal-close" :data-dismiss="id"></button>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal',
  props: {
    id: {
      type: String,
      required: true
    },
    sm: Boolean,
    lg: Boolean
  },
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    classes() {
      return {
        'modal':  true,
        'modal-sm': this.sm,
        'modal-lg': this.lg,
        'active': this.isActive
      }
    }
  },
  mounted() {
    this.$refs[this.id].addEventListener('click', e => {
      if (e.target.getAttribute('data-dismiss') === this.id) {
        this.close()
      }
    })
  },
  methods: {
    open() {
      this.isActive = true
      document.body.classList.add('modal-open')
      document.addEventListener('keydown', this.onKeydown, true)
    },
    close() {
      this.isActive = false
      document.body.classList.remove('modal-open')
      document.removeEventListener('keydown', this.onKeydown, true)
    },
    onKeydown(e) {
      if (e.keyCode === 27) {
        this.close()
      }
    }
  }
}
</script>
