<template>
  <div id="main" class="thanks">
    <div class="hero bg-light">
      <div class="container container-lg text-center">
        <div class="hero-title heading heading-lg text-success">
          <Icon name="task-alt" />
        </div>
        <h1 class="hero-title heading heading-lg mb-4">{{ $t("thanksInitialStep.hero.title") }}</h1>
        <p class="hero-text text-xl col-sm-12 col-md-9 col-mx-auto mb-8">{{ $t("thanksInitialStep.hero.text") }}</p>
        <p>{{ $t("thanksInitialStep.logout.text") }}</p>
      </div>
    </div>
    <div class="container container-lg">
      <Footer />
    </div>
  </div>
</template>

<script>
export default {
  name: 'thanks-initial-view',
  created() {
    this.$store.dispatch('logout')
  }
}
</script>
