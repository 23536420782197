import _ from 'lodash'
import i18n from '@/i18n'
import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import { Api, Auth } from '@/api'
import { LoanCalculator } from '@/utils/loan-calculator'
import { Utils } from '@/utils/utils'
import fallbackBranches from '@/data/branches.json'
import * as common from '@/common'

Vue.use(Vuex)

const localStoragePlugin = store => {
  store.subscribe((mutation, state) => {
    if (mutation.type !== 'initStore') {
      window.sessionStorage.setItem('store', JSON.stringify(state))
    }
  })
}

const languagePlugin = store => {
  store.subscribe((mutation, state) => {
    if (mutation.type === 'setLang' || mutation.type === 'initStore') {
      i18n.locale = state.lang
    }
  })
}

class CollateralInfoItem {
  constructor(type = '', amount = null, ownedByThirdParty = false, assetPledged = false) {
    this.type = type
    this.amount = amount
    this.ownedByThirdParty = ownedByThirdParty
    this.assetPledged = assetPledged
  }
}

class AssetItem {
  constructor(type = '', amount = null) {
    this.type = type
    this.amount = amount
  }
}

class LoanItem {
  constructor(type = '', monthlyInstallment = null, amount = null, paidOffWithAppliedLoan = false, requestOffer = false) {
    this.type = type
    this.monthlyInstallment = monthlyInstallment
    this.amount = amount
    this.paidOffWithAppliedLoan = paidOffWithAppliedLoan
    this.requestOffer = requestOffer
  }
}

class Address {
  constructor(streetAddress = '', zip = '', city = '') {
    this.streetAddress = streetAddress
    this.zip = zip
    this.city = city
  }
}

const Applicant = {
  firstOwner: null,
  firstName: '',
  lastName: '',
  ssn: '',
  phoneNumber: '',
  email: '',
  address: new Address(),
  education: '',
  maritalStatus: '',
  statusOfHousing: '',
  socioeconomicStatus: '',
  employment: '',
  employer: '',
  occupation: '',
  employmentStartMonth: '',
  employmentStartYear: '',
  employmentEndMonth: '',
  employmentEndYear: '',
  grossIncome: null,
  netIncome: null,
  dividendIncome: null,
  rentalIncome: null,
  otherCapitalIncome: null,
  childBenefitIncome: null,
  childSupportIncome: null,
  otherIncome: null,
  hasAssets: null,
  assets: [ new AssetItem() ],
  hasLoans: null,
  consents: null,
  loans: [ new LoanItem() ],
  additionalInformation: '',
  generalTermsAccepted: false
}

const defaultState = () => {
  return {
    applicationId: '',
    editToken: '',

    isMaintenance: false,
    isSubmitting: false,

    isFirstApplicantAuthenticated: false,
    isSecondApplicantAuthenticated: false,

    authSource: '',
    authIdp: '',

    isFirstStepSubmitted: false,
    isSecondStepSubmitted: false,
    isThirdStepSubmitted: false,
    isFourthStepOneSubmitted: false,
    isFourthStepTwoSubmitted: false,
    isFifthStepSubmitted: false,
    isSummaryStepSubmitted: false,

    transactionId: null,
    campaignId: null,

    // Kieli
    lang: 'fi',
    // Lainan tyyppi
    mortgageType: 'MORTGAGE',
    // Onko ostettava asunto tiedossa?
    isLoanTargetKnown: null,
    // Hakijoiden lkm
    numberOfApplicants: null,
    // Onko ensiasunnonostaja? (jos yksi hakija)
    firstOwnerOneApplicant: null,
    // Onko ensiasunnonostaja? (jos kaksi hakijaa)
    firstOwnerTwoApplicants: null,

    loanTarget: {
      // Asunnon tyyppi
      type: 'APARTMENT',
      // Osoite
      address: new Address(),
      // Velaton hinta
      debtFreePrice: null,
      // Myynthinta
      sellingPrice: null,
      // Alkuperäinen myyntihinta
      originalSellingPrice: null,
      // Taloyhtiölaina
      housingCompanyLoan: null,
      // Asumisoikeusmaksu
      rightOfOccupancySellingPrice: null,
      // Alkuperäinen asumisoikeusmaksu
      originalRightOfOccupancyFee: null,
      // Rakentamisen kustannusarvio
      buildingCostEstimate: null,
      // Onko tontti vuokrattu?
      isBuildingGroundRented: false,
      // Tontin myyntihinta
      buildingGroundPrice: null,
      // Tontin vuokra
      buildingGroundRentAmount: null,
      // Tontin vuokra-aika
      buildingGroundLeaseEndDate: '',
      // Myynti-ilmoitus
      advertisementUrl: '',
      // Hankinta vuosi
      purchaseYear: null,
      // Hoitovastike
      maintenanceCost: null,
      // Rahoitusvastike
      financingCost: null,
      // Käyttövastike
      residenceCharge: null,
      // Muut asumiskustannukset (sähkö, vesi jne.)
      otherHousingExpenses: null
    },

    // Nykyinen asuntolaina
    currentMortgage: {
      // Lainan määrä
      amount: null,
      // Lainan myöntäjä
      bank: '',
      // Lainan marginaali
      margin: null
    },

    // Asunnon hankintahinta (jos kohde ei tiedossa)
    purchasePrice: 175000,

    // Lainaan lisättävät muut kulut
    additionalLoanAmount: null,

    // Sisältyykö asunnon velaton hinta lainan määrään
    isDebtFreePriceIncluded: true,

    // Sisältyykö taloyhtiölaina lainan määrään
    isHousingCompanyLoanIncluded: null,

    // Sisältyykö tontin hinta lainan määrään
    isBuildingGroundPriceIncluded: null,

    // Sisältyykö varallisuusvero lainan määrään
    isTransferTaxIncluded: null,

    // Edellisestä asunnosta saatava myyntivoitto
    apartmentSalesProfit: null,

    // ASP-säästöt
    aspSavings: null,

    // Omat säästöt
    otherSavings: null,

    // Toivottu laina-aika
    desiredLoanTime: 25,

    // Vakuudet
    collaterals: [ ],

    // Vakuudet: tarkemmat tiedot lisävakuuksista
    collateralInfos: [ new CollateralInfoItem() ],

    // Ensimmäinen hakija
    firstApplicant: Applicant,

    // Toinen hakija
    secondApplicant: Applicant,

    // Aikuisten lkm taloudessa
    numberOfAdults: 1,

    // Lasten lkm taloudessa
    numberOfChildren: 0,

    // Autojen lkm taloudessa
    numberOfCars: 0,

    // Kotitalouden menot
    housingExpenses: null,
    foodExpenses: null,
    transportationExpenses: null,
    clothingExpenses: null,
    healthExpenses: null,
    leisureExpenses: null,
    childrenExpenses: null,
    insuranceExpenses: null,
    otherExpenses: null,

    branchId: '',
    branches: fallbackBranches,

    summary: {
      loanAmount: null,
      desiredLoanTime: null,
      totalExpenses: null,
      firstApplicantName: '',
      firstApplicantSsn: ''
    }
  }
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: defaultState(),
  mutations: {
    initStore(state) {
      const storage = window.sessionStorage.getItem('store')

      if (storage) {
        this.replaceState(Object.assign(state, JSON.parse(storage)))
      }
    },
    resetStore(state) {
      this.replaceState(Object.assign({}, defaultState(), {
        lang: state.lang,
        campaignId: state.campaignId
      }))
    },
    setApplicationId(state, value) {
      state.applicationId = value
    },
    setEditToken(state, value) {
      state.editToken = value
    },
    setIsMaintenance(state, value) {
      state.isMaintenance = value
    },
    setIsSubmitting(state, value) {
      state.isSubmitting = value
    },
    setIsFirstApplicantAuthenticated(state, value) {
      state.isFirstApplicantAuthenticated = value
    },
    setIsSecondApplicantAuthenticated(state, value) {
      state.isSecondApplicantAuthenticated = value
    },
    setAuthSource(state, value) {
      state.authSource = value
    },
    setAuthIdp(state, value) {
      state.authIdp = value
    },

    setIsFirstStepSubmitted(state, value) {
      state.isFirstStepSubmitted = value
    },
    setIsSecondStepSubmitted(state, value) {
      state.isSecondStepSubmitted = value
    },
    setIsThirdStepSubmitted(state, value) {
      state.isThirdStepSubmitted = value
    },
    setIsFourthStepOneSubmitted(state, value) {
      state.isFourthStepOneSubmitted = value
    },
    setIsFourthStepTwoSubmitted(state, value) {
      state.isFourthStepTwoSubmitted = value
    },
    setIsFifthStepSubmitted(state, value) {
      state.isFifthStepSubmitted = value
    },
    setIsSummaryStepSubmitted(state, value) {
      state.isSummaryStepSubmitted = value
    },

    setTransactionId(state, value) {
      state.transactionId = value
    },
    setCampaignId(state, value) {
      state.campaignId = value
    },

    setLang(state, value) {
      state.lang = value
    },
    setMortgageType(state, value) {
      state.mortgageType = value
    },
    setIsLoanTargetKnown(state, value) {
      state.isLoanTargetKnown = value
    },
    setNumberOfApplicants(state, value) {
      state.numberOfApplicants = value
    },
    setFirstOwnerOneApplicant(state, value) {
      state.firstOwnerOneApplicant = value
    },
    setFirstOwnerTwoApplicants(state, value) {
      state.firstOwnerTwoApplicants = value
    },

    setLoanTargetType(state, value) {
      state.loanTarget.type = value
    },
    setLoanTargetStreetAddress(state, value) {
      state.loanTarget.address.streetAddress = value
    },
    setLoanTargetZip(state, value) {
      state.loanTarget.address.zip = value
    },
    setLoanTargetCity(state, value) {
      state.loanTarget.address.city = value
    },

    setDebtFreePrice(state, value) {
      state.loanTarget.debtFreePrice = value
    },
    setSellingPrice(state, value) {
      state.loanTarget.sellingPrice = value
    },
    setOriginalSellingPrice(state, value) {
      state.loanTarget.originalSellingPrice = value
    },
    setHousingCompanyLoan(state, value) {
      state.loanTarget.housingCompanyLoan = value
    },

    setRightOfOccupancySellingPrice(state, value) {
      state.loanTarget.rightOfOccupancySellingPrice = value
    },
    setOriginalRightOfOccupancyFee(state, value) {
      state.loanTarget.originalRightOfOccupancyFee = value
    },

    setBuildingCostEstimate(state, value) {
      state.loanTarget.buildingCostEstimate = value
    },
    setIsBuildingGroundRented(state, value) {
      state.loanTarget.isBuildingGroundRented = value
    },
    setBuildingGroundPrice(state, value) {
      state.loanTarget.buildingGroundPrice = value
    },
    setBuildingGroundRentAmount(state, value) {
      state.loanTarget.buildingGroundRentAmount = value
    },
    setBuildingGroundLeaseEndDate(state, value) {
      state.loanTarget.buildingGroundLeaseEndDate = value
    },

    setAdvertisementUrl(state, value) {
      state.loanTarget.advertisementUrl = value
    },
    setPurchaseYear(state, value) {
      state.loanTarget.purchaseYear = value
    },

    setMaintenanceCost(state, value) {
      state.loanTarget.maintenanceCost = value
    },
    setFinancingCost(state, value) {
      state.loanTarget.financingCost = value
    },
    setResidenceCharge(state, value) {
      state.loanTarget.residenceCharge = value
    },
    setOtherHousingExpenses(state, value) {
      state.loanTarget.otherHousingExpenses = value
    },

    setCurrentMortgageAmount(state, value) {
      state.currentMortgage.amount = value
    },
    setCurrentMortgageBank(state, value) {
      state.currentMortgage.bank = value
    },
    setCurrentMortgageMargin(state, value) {
      state.currentMortgage.margin = value
    },

    setPurchasePrice(state, value) {
      state.purchasePrice = value
    },
    setAdditionalLoanAmount(state, value) {
      state.additionalLoanAmount = value
    },
    setIsDebtFreePriceIncluded(state, value) {
      state.isDebtFreePriceIncluded = value
    },
    setIsHousingCompanyLoanIncluded(state, value) {
      state.isHousingCompanyLoanIncluded = value
    },
    setIsTransferTaxIncluded(state, value) {
      state.isTransferTaxIncluded = value
    },
    setIsBuildingGroundPriceIncluded(state, value) {
      state.isBuildingGroundPriceIncluded = value
    },
    setApartmentSalesProfit(state, value) {
      state.apartmentSalesProfit = value
    },
    setAspSavings(state, value) {
      state.aspSavings = value
    },
    setOtherSavings(state, value) {
      state.otherSavings = value
    },
    setDesiredLoanTime(state, value) {
      state.desiredLoanTime = value
    },
    setCollaterals(state, value) {
      state.collaterals = value
    },
    resetCollateralsOnRightOfOccupancy(state) {
      state.collaterals = state.collaterals.filter(value => value !== 'AKTIA_WARRANTY' && value !== 'GOVERNMENT_WARRANTY')
    },
    setCollateralInfoItemType(state, { value, key }) {
      state.collateralInfos[key].type = value
    },
    setCollateralInfoItemAmount(state, { value, key }) {
      state.collateralInfos[key].amount = value
    },
    setCollateralInfoItemOwnedByThirdParty(state, { value, key }) {
      state.collateralInfos[key].ownedByThirdParty = value
    },
    setCollateralInfoItemAssetPledged(state, { value, key }) {
      state.collateralInfos[key].assetPledged = value
    },
    addCollateralInfoItem(state) {
      state.collateralInfos.push( new CollateralInfoItem() )
    },
    removeCollateralInfoItem(state, value) {
      const index = state.collateralInfos.indexOf(value)
      if (index > -1) {
        state.collateralInfos.splice(index, 1)
      }
      if (state.collateralInfos.length === 0) {
        state.collaterals = state.collaterals.filter(value => value !== 'OTHER_WEALTH')
      }
    },
    resetCollateralInfos(state) {
      if (state.collateralInfos.length === 0) {
        state.collateralInfos = [ new CollateralInfoItem() ]
      }
    },
    setFirstOwner(state, { value, applicant }) {
      state[applicant].firstOwner = value
    },
    setFirstName(state, { value, applicant }) {
      state[applicant].firstName = value
    },
    setLastName(state, { value, applicant }) {
      state[applicant].lastName = value
    },
    setSSN(state, { value, applicant }) {
      state[applicant].ssn = value
    },
    setStreetAddress(state, { value, applicant }) {
      state[applicant].address.streetAddress = value
    },
    setZip(state, { value, applicant }) {
      state[applicant].address.zip = value
    },
    setCity(state, { value, applicant }) {
      state[applicant].address.city = value
    },
    setPhoneNumber(state, { value, applicant }) {
      state[applicant].phoneNumber = value
    },
    setEmail(state, { value, applicant }) {
      state[applicant].email = value
    },
    setMaritalStatus(state, { value, applicant }) {
      state[applicant].maritalStatus = value
    },
    setStatusOfHousing(state, { value, applicant }) {
      state[applicant].statusOfHousing = value
    },
    setEducation(state, { value, applicant }) {
      state[applicant].education = value
    },
    setEmployment(state, { value, applicant }) {
      state[applicant].employment = value
    },
    setEmployer(state, { value, applicant }) {
      state[applicant].employer = value
    },
    setOccupation(state, { value, applicant }) {
      state[applicant].occupation = value
    },
    setSocioeconomicStatus(state, { value, applicant }) {
      state[applicant].socioeconomicStatus = value
    },
    setEmploymentStartMonth(state, { value, applicant }) {
      state[applicant].employmentStartMonth = value
    },
    setEmploymentStartYear(state, { value, applicant }) {
      state[applicant].employmentStartYear = value
    },
    setEmploymentEndMonth(state, { value, applicant }) {
      state[applicant].employmentEndMonth = value
    },
    setEmploymentEndYear(state, { value, applicant }) {
      state[applicant].employmentEndYear = value
    },
    setGrossIncome(state, { value, applicant }) {
      state[applicant].grossIncome = value
    },
    setNetIncome(state, { value, applicant }) {
      state[applicant].netIncome = value
    },
    setDividendIncome(state, { value, applicant }) {
      state[applicant].dividendIncome = value
    },
    setRentalIncome(state, { value, applicant }) {
      state[applicant].rentalIncome = value
    },
    setOtherCapitalIncome(state, { value, applicant }) {
      state[applicant].otherCapitalIncome = value
    },
    setChildBenefitIncome(state, { value, applicant }) {
      state[applicant].childBenefitIncome = value
    },
    setChildSupportIncome(state, { value, applicant }) {
      state[applicant].childSupportIncome = value
    },
    setOtherIncome(state, { value, applicant }) {
      state[applicant].otherIncome = value
    },

    setHasAssets(state, { value, applicant }) {
      state[applicant].hasAssets = value
    },
    setAssetItemType(state, { value, key, applicant }) {
      state[applicant].assets[key].type = value
    },
    setAssetItemAmount(state, { value, key, applicant }) {
      state[applicant].assets[key].amount = value
    },
    addAssetItem(state, applicant) {
      state[applicant].assets.push( new AssetItem() )
    },
    removeAssetItem(state, { value, applicant }) {
      const index = state[applicant].assets.indexOf(value)
      if (index > -1) {
        state[applicant].assets.splice(index, 1)
      }
      if (state[applicant].assets.length === 0) {
        state[applicant].hasAssets = false
      }
    },
    resetAssets(state, applicant) {
      if (state[applicant].assets.length === 0) {
        state[applicant].assets = [ new AssetItem() ]
      }
    },

    setHasLoans(state, { value, applicant }) {
      state[applicant].hasLoans = value
    },
    setConsents(state, { value, applicant }) {
      state[applicant].consents = value
    },
    setConsentItem(state, { value, key, applicant }) {
      state[applicant].consents[key] = value
    }
    ,
    setLoanItemType(state, { value, key, applicant }) {
      state[applicant].loans[key].type = value
    },
    setLoanItemMonthlyInstallment(state, { value, key, applicant }) {
      state[applicant].loans[key].monthlyInstallment = value
    },
    setLoanItemAmount(state, { value, key, applicant }) {
      state[applicant].loans[key].amount = value
    },
    setLoanItemPaidOffWithAppliedLoan(state, { value, key, applicant }) {
      state[applicant].loans[key].paidOffWithAppliedLoan = value
    },
    setLoanItemRequestOffer(state, { value, key, applicant }) {
      state[applicant].loans[key].requestOffer = value
    },
    addLoanItem(state, applicant) {
      state[applicant].loans.push( new LoanItem() )
    },
    removeLoanItem(state, { value, applicant }) {
      const index = state[applicant].loans.indexOf(value)
      if (index > -1) {
        state[applicant].loans.splice(index, 1)
      }
      if (state[applicant].loans.length === 0) {
        state[applicant].hasLoans = false
      }
    },
    resetLoans(state, applicant) {
      if (state[applicant].loans.length === 0) {
        state[applicant].loans = [ new LoanItem() ]
      }
    },

    setAdditionalInformation(state, { value, applicant }) {
      state[applicant].additionalInformation = value
    },

    setGeneralTermsAccepted(state, { value, applicant }) {
      state[applicant].generalTermsAccepted = value
    },

    setNumberOfAdults(state, value) {
      state.numberOfAdults = value
    },
    setNumberOfChildren(state, value) {
      state.numberOfChildren = value
    },
    setNumberOfCars(state, value) {
      state.numberOfCars = value
    },
    setHousingExpenses(state, value) {
      state.housingExpenses = value
    },
    setFoodExpenses(state, value) {
      state.foodExpenses = value
    },
    setTransportationExpenses(state, value) {
      state.transportationExpenses = value
    },
    setClothingExpenses(state, value) {
      state.clothingExpenses = value
    },
    setHealthExpenses(state, value) {
      state.healthExpenses = value
    },
    setLeisureExpenses(state, value) {
      state.leisureExpenses = value
    },
    setChildrenExpenses(state, value) {
      state.childrenExpenses = value
    },
    setInsuranceExpenses(state, value) {
      state.insuranceExpenses = value
    },
    setOtherExpenses(state, value) {
      state.otherExpenses = value
    },
    setBranches(state, value) {
      state.branches = value
    },
    setBranchId(state, value) {
      state.branchId = value
    },

    setSummaryLoanAmount(state, value) {
      state.summary.loanAmount = value
    },
    setSummaryDesiredLoanTime(state, value) {
      state.summary.desiredLoanTime = value
    },
    setSummaryTotalExpenses(state, value) {
      state.summary.totalExpenses = value
    },
    setSummaryFirstApplicantName(state, value) {
      state.summary.firstApplicantName = value
    },
    setSummaryFirstApplicantSsn(state, value) {
      state.summary.firstApplicantSsn = value
    },
  },
  actions: {
    logout({ commit }) {
      Auth.logout()
        .then(() => {
          commit('resetStore')
        })
    },
    getApplicationId({ commit, state }) {
      return new Promise((resolve, reject) => {
        Api.getApplicationId(state.editToken)
          .then(({ data }) => {
            const { applicationId } = data
            commit('setApplicationId', applicationId)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getApplication({ commit, state }) {
      return new Promise((resolve, reject) => {
        Api.getApplication(state.applicationId)
          .then(({ data }) => {
            const firstApplicantName = `${data.firstApplicant.baseInfo.firstName} ${data.firstApplicant.baseInfo.lastName}`

            const expenses = [
              data.household.expenses.housingExpenses,
              data.household.expenses.transportationExpenses,
              data.household.expenses.foodExpenses,
              data.household.expenses.clothingExpenses,
              data.household.expenses.healthExpenses,
              data.household.expenses.leisureExpenses,
              data.household.expenses.childrenExpenses,
              data.household.expenses.insuranceExpenses,
              data.household.expenses.otherExpenses
            ]

            const totalExpenses =_.sumBy(expenses, value => {
              if (Utils.isFinite(value)) {
                return Utils.toNumber(value)
              }
            })

            commit('setMortgageType', data.loanType)
            commit('setIsLoanTargetKnown', data.loanTargetKnown)
            commit('setSummaryLoanAmount', data.loanInfo.loanAmount)
            commit('setSummaryDesiredLoanTime', data.loanInfo.desiredLoanTime)
            commit('setSummaryTotalExpenses', totalExpenses)
            commit('setSummaryFirstApplicantName', firstApplicantName)
            commit('setSummaryFirstApplicantSsn', data.firstApplicant.baseInfo.ssn)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getUser({ commit, state }) {
      return new Promise(resolve => {
        Auth.getUser()
          .then(({ data }) => {
            const { firstName, lastName, ssn, streetAddress, zip, city, role } = data
            let applicant

            if (role === 'FIRST_APPLICANT') {
              commit('setIsFirstApplicantAuthenticated', true)
              applicant = 'firstApplicant'
            }

            if (role === 'SECOND_APPLICANT') {
              commit('setIsSecondApplicantAuthenticated', true)
              applicant = 'secondApplicant'
            }

            if (applicant) {
              commit('setFirstName', { value: firstName, applicant: applicant })
              commit('setLastName', { value: lastName, applicant: applicant })
              commit('setSSN', { value: ssn, applicant: applicant })

              if (Utils.isEmpty(state[applicant].address.streetAddress) && !Utils.isEmpty(streetAddress)) {
                commit('setStreetAddress', { value: streetAddress, applicant: applicant })
              }

              if (Utils.isEmpty(state[applicant].address.zip) && !Utils.isEmpty(zip)) {
                commit('setZip', { value: zip, applicant: applicant })
              }

              if (Utils.isEmpty(state[applicant].address.city) && !Utils.isEmpty(city)) {
                commit('setCity', { value: city, applicant: applicant })
              }
            }

            resolve()
          })
          .catch(() => {
            commit('setIsFirstApplicantAuthenticated', false)
            commit('setIsSecondApplicantAuthenticated', false)
            resolve()
          })
      })
    },
    getBranches({ commit }) {
      Api.getBranches()
        .then(({ data }) => {
          commit('setBranches', data)
        })
        .catch(() => {
          commit('setBranches', fallbackBranches)
        })
    },
  },
  getters: {
    // Onko jompi kumpi hakija tunistautunut?
    isAuthenticated: state => {
      return state.isFirstApplicantAuthenticated || state.isSecondApplicantAuthenticated
    },

    // Onko yksi hakija?
    isOneApplicant: state => {
      return state.numberOfApplicants == 1
    },
    // Onko kaksi hakijaa?
    isTwoApplicants: state => {
      return state.numberOfApplicants == 2
    },

    // Onko oma asuntolaina?
    isMortgage: state => {
      return state.mortgageType === 'MORTGAGE'
    },
    // Onko vapaa-ajan asuntolaina?
    isVacationHomeMortgage: state => {
      return state.mortgageType === 'VACATION_HOME_LOAN'
    },
    // Onko sijoitusasuntolaina?
    isInvestmentPropertyMortgage: state => {
      return state.mortgageType === 'INVESTMENT_PROPERTY_LOAN'
    },
    // Onko rakennuslaina?
    isConstructionMortgage: state => {
      return state.mortgageType === 'CONSTRUCTION_LOAN'
    },
    // Onko asuntolainan kilpailutus?
    isRefinance: state => {
      return state.mortgageType === 'REFINANCE'
    },

    // Onko ostettava asunto asunto-osake?
    isApartment: state => {
      return state.loanTarget.type === 'APARTMENT'
    },
    // Onko ostettava asunto kiinteistö, esim. omakotitalo?
    isRealEstate: state => {
      return state.loanTarget.type === 'REAL_ESTATE'
    },
    // Onko ostettava asunto asumisoikeusasunto?
    isRightOfOccupancy: state => {
      return state.loanTarget.type === 'RIGHT_OF_OCCUPANCY'
    },

    // Kysytäänkö 'Onko ostettava asunto tiedossa?'
    isLoanTargetKnownApplied: (state, getters) => {
      return !getters.isConstructionMortgage && !getters.isRefinance
    },
    // Kysytäänkö 'Asunnon hankintahinta'?
    isPurchasePriceApplied: (state, getters) => {
      return getters.isLoanTargetKnownApplied && !state.isLoanTargetKnown
    },
    // Kysytäänkö 'Asunnon hankintahinta: Velaton hinta / Myyntihinta'?
    isDebtFreePriceIncludedApplied: (state, getters) => {
      if (getters.isLoanTargetKnownApplied && state.isLoanTargetKnown && getters.isApartment) {
        return true
      } else {
        return false
      }
    },
    // Kysytäänkö 'Tarvitsetko lainaa asunnon yhtiölainaosuutta varten?'
    isHousingCompanyLoanIncludedApplied: (state, getters) => {
      if (getters.isRefinance && getters.isApartment) {
        return true
      } else {
        return false
      }
    },
    // Kysytäänkö 'Tarvitsetko lainaa varainsiirtoveroa varten?'
    isTransferTaxIncludedApplied: (state, getters) => {
      if ((getters.isVacationHomeMortgage || getters.isInvestmentPropertyMortgage) && state.isLoanTargetKnown && !getters.isRightOfOccupancy) {
        return true
      } else if (getters.isLoanTargetKnownApplied && state.isLoanTargetKnown && !getters.isRightOfOccupancy) {
        if (getters.isOneApplicant) {
          return !state.firstOwnerOneApplicant
        }
        if (getters.isTwoApplicants) {
          return state.firstOwnerTwoApplicants !== 'BOTH'
        }
      } else if (getters.isConstructionMortgage && !state.loanTarget.isBuildingGroundRented) {
        return true
      } else {
        return false
      }
    },
    // Kysytäänkö 'Tarvitsetko lainaa tonttia varten?'
    isBuildingGroundPriceIncludedApplied: (state, getters) => {
      if (getters.isConstructionMortgage && !state.loanTarget.isBuildingGroundRented) {
        return true
      } else {
        return false
      }
    },
    // Kysytäänkö 'Edellisestä asunnosta saatava myyntivoitto'?
    isApartmentSalesProfitApplied: (state, getters) => {
      if (getters.isRefinance) {
        return false
      }
      if (getters.isVacationHomeMortgage || getters.isInvestmentPropertyMortgage) {
        return true
      }
      if (getters.isOneApplicant) {
        return !state.firstOwnerOneApplicant
      }
      if (getters.isTwoApplicants) {
        return state.firstOwnerTwoApplicants !== 'BOTH'
      }
    },
    // Kysytäänkö 'Hankintaan käytettävät ASP-säästöt'?
    isAspSavingsApplied: (state, getters) => {
      if ((getters.isLoanTargetKnownApplied && state.isLoanTargetKnown && getters.isRightOfOccupancy) || getters.isVacationHomeMortgage || getters.isInvestmentPropertyMortgage || getters.isRefinance) {
        return false
      } else {
        if (getters.isOneApplicant) {
          return state.firstOwnerOneApplicant
        }
        if (getters.isTwoApplicants) {
          return state.firstOwnerTwoApplicants !== 'NEITHER'
        }
      }
    },
    // Kysytäänkö 'Asumiskustannukset'?
    isHousingExpensesApplied: (state, getters) => {
      if (getters.isLoanTargetKnownApplied && !state.isLoanTargetKnown) {
        return true
      } else {
        return false
      }
    },
    // Sisältyykö asunnon hankintahinta lainan määrään
    isPurchasePriceIncluded: (state, getters) => {
      if (getters.isPurchasePriceApplied) {
        return true
      } else {
        return false
      }
    },
    // Sisältyykö asunnon velaton hinta lainan määrään
    isDebtFreePriceIncluded: (state, getters) => {
      if (getters.isDebtFreePriceIncludedApplied && state.isDebtFreePriceIncluded) {
        return true
      } else {
        return false
      }
    },
    // Sisältyykö asunnon myyntihinta lainan määrään
    isSellingPriceIncluded: (state, getters) => {
      if ((getters.isDebtFreePriceIncludedApplied && !state.isDebtFreePriceIncluded) || (getters.isLoanTargetKnownApplied && state.isLoanTargetKnown && getters.isRealEstate)) {
        return true
      } else {
        return false
      }
    },
    // Sisältyykö taloyhtiölaina lainan määrään
    isHousingCompanyLoanIncluded: (state, getters) => {
      if (getters.isHousingCompanyLoanIncludedApplied && state.isHousingCompanyLoanIncluded) {
        return true
      } else {
        return false
      }
    },
    // Sisältyykö asumisoikeusmaksu lainan määrään
    isRightOfOccupancySellingPriceIncluded: (state, getters) => {
      if (getters.isLoanTargetKnownApplied && state.isLoanTargetKnown && getters.isRightOfOccupancy) {
        return true
      } else {
        return false
      }
    },
    // Sisältyykö rakentamisen kustannusarvio lainan määrään
    isBuildingCostEstimateIncluded: (state, getters) => {
      if (getters.isConstructionMortgage) {
        return true
      } else {
        return false
      }
    },
    // Sisältyykö tontin hinta lainan määrään
    isBuildingGroundPriceIncluded: (state, getters) => {
      if (getters.isBuildingGroundPriceIncludedApplied && state.isBuildingGroundPriceIncluded) {
        return true
      } else {
        return false
      }
    },
    // Sisältyykö nykyinen asuntolaina lainan määrään
    isCurrentMortgageAmountIncluded: (state, getters) => {
      if (getters.isRefinance) {
        return true
      } else {
        return false
      }
    },
    // Sisältyykö varallisuusvero lainan määrään
    isTransferTaxIncluded: (state, getters) => {
      if (getters.isTransferTaxIncludedApplied && state.isTransferTaxIncluded) {
        return true
      } else {
        return false
      }
    },
    // Kysytäänkö lisätiedot lainan vakuuksista
    isCollateralInfosApplied: state => {
      return state.collaterals.includes('OTHER_WEALTH')
    },
    // Kysytäänkö 'Oletko ensiasunnon ostaja?' 'Hakijan tiedot' -sivulla
    isFirstOwnerApplied: (state, getters) => {
      return getters.isTwoApplicants && ['OTHER'].includes(state.firstOwnerTwoApplicants)
    },
    firstOwner: (state, getters) => {
      return {
        firstApplicant: getters.isFirstApplicantFirstOwner,
        secondApplicant: state.secondApplicant.firstOwner
      }
    },
    // Onko päähakija ensiasunnon ostaja?
    isFirstApplicantFirstOwner: (state, getters) => {
      if (getters.isVacationHomeMortgage || getters.isInvestmentPropertyMortgage) {
        return false
      } else if (getters.isFirstOwnerApplied) {
        return state.firstApplicant.firstOwner
      } else {
        if (getters.isOneApplicant) {
          return state.firstOwnerOneApplicant
        }
        if (getters.isTwoApplicants) {
          switch(state.firstOwnerTwoApplicants) {
            case 'BOTH':
              return true
            case 'NEITHER':
              return false
            case 'OTHER':
              return null
            default:
              return null
          }
        }
      }
    },
    // Onko kanssahakija ensiasunnon ostaja?
    /*
    isSecondApplicantFirstOwner: (state, getters) => {
      if (getters.isVacationHomeMortgage || getters.isInvestmentPropertyMortgage) {
        return false
      } else if (getters.isFirstOwnerApplied) {
        if (state.firstApplicant.firstOwner) {
          return false
        }
        return true
      } else {
        switch(state.firstOwnerTwoApplicants) {
          case 'BOTH':
            return true
          case 'NEITHER':
            return false
          case 'OTHER':
            return null
          default:
            return null
        }
      }
    },
    */
    // Kysytäänkö hakijan työsuhde, työnantaja, ammatti ja milloin työsuhde alkanut
    isEmploymentApplied: state => {
      return {
        firstApplicant: !['STUDENT', 'PENSIONER', 'LONGTERM_UNEMPLOYED', 'UNEMPLOYED'].includes(state.firstApplicant.socioeconomicStatus),
        secondApplicant: !['STUDENT', 'PENSIONER', 'LONGTERM_UNEMPLOYED', 'UNEMPLOYED'].includes(state.secondApplicant.socioeconomicStatus)
      }
    },
    // Kysytäänkö hakijan työsuhteen päättymispäivämäärä
    isEmploymentEndApplied: (state, getters) => {
      return {
        firstApplicant: getters.isEmploymentApplied.firstApplicant && ['TEMPORARY_FULLTIME', 'TEMPORARY_PARTTIME'].includes(state.firstApplicant.employment),
        secondApplicant: getters.isEmploymentApplied.secondApplicant && ['TEMPORARY_FULLTIME', 'TEMPORARY_PARTTIME'].includes(state.secondApplicant.employment)
      }
    },
    // Kysytäänkö hakijan varallisuus
    isAssetsApplied: state => {
      return {
        firstApplicant: state.firstApplicant.hasAssets,
        secondApplicant: state.secondApplicant.hasAssets
      }
    },
    // Kysytäänkö hakijan nykyiset lainat
    isLoansApplied: state => {
      return {
        firstApplicant: state.firstApplicant.hasLoans,
        secondApplicant: state.secondApplicant.hasLoans
      }
    },
    // Yhteenlaskettu pääomatulo
    capitalIncome: state => {
      return {
        firstApplicant: Utils.toNumber(state.firstApplicant.rentalIncome) + Utils.toNumber(state.firstApplicant.dividendIncome) + Utils.toNumber(state.firstApplicant.otherCapitalIncome),
        secondApplicant: Utils.toNumber(state.secondApplicant.rentalIncome) + Utils.toNumber(state.secondApplicant.dividendIncome) + Utils.toNumber(state.secondApplicant.otherCapitalIncome),
      }
    },
    buildingGroundLeaseEndDate: state => {
      const { buildingGroundLeaseEndDate: date } = state.loanTarget

      if (moment(date, 'D.M.YYYY', true).isValid()) {
        return moment(date, 'D.M.YYYY', true).format('YYYY-MM-DD')
      }
    },
    employmentStartDate: state => {
      const { employmentStartMonth: faMonth, employmentStartYear: faYear } = state.firstApplicant
      const { employmentStartMonth: saMonth, employmentStartYear: saYear } = state.secondApplicant
      const getDate = (year, month) => moment([ year, month ]).isValid() ? moment([ year, month ]).format('YYYY-MM-DD') : ''

      return {
        firstApplicant: getDate(faYear, faMonth),
        secondApplicant: getDate(saYear, saMonth),
      }
    },
    employmentEndDate: state => {
      const { employmentEndMonth: faMonth, employmentEndYear: faYear } = state.firstApplicant
      const { employmentEndMonth: saMonth, employmentEndYear: saYear } = state.secondApplicant
      const getDate = (year, month) => moment([ year, month ]).isValid() ? moment([ year, month ]).format('YYYY-MM-DD') : ''

      return {
        firstApplicant: getDate(faYear, faMonth),
        secondApplicant: getDate(saYear, saMonth),
      }
    },
    // Varainsiirtoveron määrä
    transferTax: (state, getters) => {
      let value = 0

      if (getters.isLoanTargetKnownApplied && state.isLoanTargetKnown) {
        if (getters.isApartment) {
          value += Utils.toNumber(state.loanTarget.debtFreePrice) * .02
        }
        if (getters.isRealEstate) {
          value += Utils.toNumber(state.loanTarget.sellingPrice) * .04
        }
      }

      if (getters.isConstructionMortgage && !state.isBuildingGroundRented) {
        value += Utils.toNumber(state.loanTarget.buildingGroundPrice) * .04
      }

      return value
    },
    // Lainahankkeen kokonaiskustannukset
    loanAmountTotalExpenses: (state, getters) => {
      let value = 0

      // Lisää asunnon velaton hinta
      if (getters.isDebtFreePriceIncluded) {
        value += Utils.toNumber(state.loanTarget.debtFreePrice)
      }

      // Lisää asunnon myyntihinta
      if (getters.isSellingPriceIncluded) {
        value += Utils.toNumber(state.loanTarget.sellingPrice)
      }

      // Lisää asunnon yhtiölainaosuus
      if (getters.isHousingCompanyLoanIncluded) {
        value += Utils.toNumber(state.loanTarget.housingCompanyLoan)
      }

      // Lisää asunnon hankintahinta
      if (getters.isPurchasePriceIncluded) {
        value += Utils.toNumber(state.purchasePrice)
      }

      // Lisää asumisoikeusmaksu
      if (getters.isRightOfOccupancySellingPriceIncluded) {
        value += Utils.toNumber(state.loanTarget.rightOfOccupancySellingPrice)
      }

      // Lisää rakentamisen kustannusarvio
      if (getters.isBuildingCostEstimateIncluded) {
        value += Utils.toNumber(state.loanTarget.buildingCostEstimate)
      }

      // Lisää tontin hinta
      if (getters.isBuildingGroundPriceIncluded) {
        value += Utils.toNumber(state.loanTarget.buildingGroundPrice)
      }

      // Lisää nykyisen asuntolainan määrä
      if (getters.isCurrentMortgageAmountIncluded) {
        value += Utils.toNumber(state.currentMortgage.amount)
      }

      // Lisää muut kulut
      if (state.additionalLoanAmount) {
        value += Utils.toNumber(state.additionalLoanAmount)
      }

      // Lisää varainsiirtovero
      if (getters.isTransferTaxIncluded) {
        value += Utils.toNumber(getters.transferTax)
      }

      return value
    },
    // Lainahankkkeen kokonaisssäästöt
    loanAmountTotalSavings: (state, getters) => {
      let value = 0

      // Lisää edellisen asunnon myyntivoitto
      if (getters.isApartmentSalesProfitApplied && state.apartmentSalesProfit) {
        value += Utils.toNumber(state.apartmentSalesProfit)
      }

      // Lisää ASP-säästöt
      if (getters.isAspSavingsApplied && state.aspSavings) {
        value += Utils.toNumber(state.aspSavings)
      }

      // Lisää omat säästöt
      if (state.otherSavings) {
        value += Utils.toNumber(state.otherSavings)
      }

      return value
    },
    // Lainahankkeen omarahoitusosuus (%)
    loanAmountSavingsPercentage: (state, getters) => {
      const value = getters.loanAmountTotalSavings / getters.loanAmountTotalExpenses
      return value >= 1 ? 1 : value
    },
    // Lainan määrä
    loanAmount: (state, getters) => {
      const value = getters.loanAmountTotalExpenses - getters.loanAmountTotalSavings
      return value
    },
    loanPayment: (state, getters) => {
      const loanAmount = getters.loanAmount
      const loanTimeInYears = state.desiredLoanTime
      const numberOfPaymentsPerYear = 12
      const loanInterestPerYear = common.kLoanInterestDefaultPercentage

      const _value = LoanCalculator.calculateLoanPayment(
        loanAmount,
        loanTimeInYears,
        numberOfPaymentsPerYear,
        loanInterestPerYear
      )

      const value = Math.ceil(_value)
      return Utils.isFinite(value) ? value : null
    },
    totalExpenses: (state, getters) => {
      let value = 0

      const expenses = [
        state.transportationExpenses,
        state.foodExpenses,
        state.clothingExpenses,
        state.healthExpenses,
        state.leisureExpenses,
        state.childrenExpenses,
        state.insuranceExpenses,
        state.otherExpenses
      ]

      if (getters.isHousingExpensesApplied) {
        expenses.push(state.housingExpenses)
      }

      value =_.sumBy(expenses, value => {
        if (Utils.isFinite(value)) {
          return Utils.toNumber(value)
        }
      })
      return value
    },
    totalExpensesDefault: (state, getters) => {
      let value = 0

      const expenses = [
        getters.transportationExpensesDefault,
        getters.foodExpensesDefault,
        getters.clothingExpensesDefault,
        getters.healthExpensesDefault,
        getters.leisureExpensesDefault,
        getters.childrenExpensesDefault,
        getters.insuranceExpensesDefault,
        getters.otherExpensesDefault
      ]

      if (getters.isHousingExpensesApplied) {
        expenses.push(getters.housingExpensesDefault)
      }

      value = _.sumBy(expenses, value => {
        if (Utils.isFinite(value)) {
          return Utils.toNumber(value)
        }
      })
      return value
    },
    housingExpensesCalculated: (state, getters) => {
      if ((!getters.isConstructionMortgage && state.isLoanTargetKnown) || getters.isRefinance) {
        if (getters.isApartment) {
          return _.sumBy([
            state.loanTarget.financingCost,
            state.loanTarget.maintenanceCost,
            state.loanTarget.otherHousingExpenses
          ], value => {
            if (Utils.isFinite(value)) {
              return Utils.toNumber(value)
            }
          })
        }

        if (getters.isRealEstate) {
          return Utils.toNumber(state.loanTarget.otherHousingExpenses)
        }

        if (getters.isRightOfOccupancy) {
          return _.sumBy([
            state.loanTarget.residenceCharge,
            state.loanTarget.otherHousingExpenses
          ], value => {
            if (Utils.isFinite(value)) {
              return Utils.toNumber(value)
            }
          })
        }
      } else {
        return Utils.toNumber(state.loanTarget.otherHousingExpenses)
      }
    },
    housingExpensesDefault: state => {
      let total = 0

      switch(state.numberOfAdults) {
        case 1:
          total = 385
          break
        case 2:
          total = 585
          break
        default:
          total = 385
      }

      total += state.numberOfChildren * 85
      return total
    },
    foodExpensesDefault: state => {
      let total = 0

      switch(state.numberOfAdults) {
        case 1:
          total = 380
          break
        case 2:
          total = 600
          break
        default:
          total = 380
      }

      total += state.numberOfChildren * 145
      return total
    },
    transportationExpensesDefault: state => {
      let total = 0

      total = state.numberOfCars ? state.numberOfCars * 250 : 50
      return total
    },
    clothingExpensesDefault: state => {
      let total = 0

      switch(state.numberOfAdults) {
        case 1:
          total = 80
          break
        case 2:
          total = 140
          break
        default:
          total = 80
      }

      total += state.numberOfChildren * 40
      return total
    },
    healthExpensesDefault: state => {
      let total = 0

      switch(state.numberOfAdults) {
        case 1:
          total = 110
          break
        case 2:
          total = 190
          break
        default:
          total = 110
      }

      total += state.numberOfChildren * 55
      return total
    },
    leisureExpensesDefault: state => {
      let total = 0

      switch(state.numberOfAdults) {
        case 1:
          total = 120
          break
        case 2:
          total = 200
          break
        default:
          total = 120
      }

      total += state.numberOfChildren * 60
      return total
    },
    childrenExpensesDefault: state => {
      let total = 0

      if (state.numberOfChildren == 1) {
        total = 290
      }

      if (state.numberOfChildren > 1) {
        total = 450
      }

      return total
    },
    insuranceExpensesDefault: state => {
      let total = 0

      switch(state.numberOfAdults) {
        case 1:
          total = 50
          break
        case 2:
          total = 70
          break
        default:
          total = 50
      }

      total += state.numberOfChildren * 22
      return total
    },
    otherExpensesDefault: state => {
      let total = 0

      switch(state.numberOfAdults) {
        case 1:
          total = 100
          break
        case 2:
          total = 200
          break
        default:
          total = 100
      }

      total += state.numberOfChildren * 20
      return total
    }
  },
  plugins: [ localStoragePlugin, languagePlugin ]
})
