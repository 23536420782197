import _ from 'lodash'

export class Utils {

  // Checks if value is null
  static isNull(value) {
    return value === null
  }

  // Checks if value is undefined
  static isUndefined(value) {
    return value === undefined
  }

  // Checks if value is null or undefined
  static isNil(value) {
    return value == null
  }

  // Checks if value is NaN
  static isNaN(value) {
    return Number.isNaN(value)
  }

  // Checks if value is finite primitive number
  static isFinite(value) {
    return Number.isFinite(value)
  }

  // Checks if value is an integer
  static isInteger(value) {
    return Number.isInteger(value)
  }

  // Checks if value is empty string or string with only whitespace
  static isEmptyString(value) {
    return typeof value === 'string' && value.trim().length === 0
  }

  // Checks if value is empty object
  static isEmptyObject(value) {
    return typeof value === 'object' && Object.keys(value).length === 0 && value.constructor === Object
  }

  // Checks if value is null, undefined, empty string, empty object or NaN
  static isEmpty(value) {
    return this.isNil(value) || this.isEmptyString(value) || this.isEmptyObject(value) || this.isNaN(value)
  }

  // Round number
  static round(value, decimals = 0) {
    return _.round(value, decimals)
  }

  // Round number to nearest multiple
  static roundToNearestMultiple(value, multiple = 1) {
    return _.round(value / multiple) * multiple
  }

  // Converts value to number
  static toNumber(value) {
    return _.toNumber(value)
  }

  // Converts value to integer
  static toInteger(value) {
    return _.toInteger(value)
  }

  // Converts value to boolean
  static toBoolean(value) {
    return value === 'true'
  }

  static getURLQueryString() {
    return window.location.search.split('?')[1]
  }

  static getURLQueryParam(param) {
    const urlQueryString = this.getURLQueryString()
    const urlQueryObject = this.decodeURLQueryString(urlQueryString)
    return urlQueryObject[param]
  }

  static encodeURLQueryString(queryObject) {
    return new URLSearchParams(queryObject).toString()
  }

  static decodeURLQueryString(queryString) {
    return Object.fromEntries(new URLSearchParams(queryString))
  }

}
