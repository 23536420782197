<template>
  <div id="main" class="second pt-12">
    <div class="container container-lg">
      <div class="row">

        <!-- Main content -->
        <div class="col col-12 col-md-9">
          <form class="page" @submit.prevent="handleSubmit" autocomplete="off">

            <h2 class="h3 heading-underline heading-underline-lg mb-8">{{ $t("secondStep.loanTarget.title") }}</h2>

            <!-- Asunnon tyyppi -->
            <div v-if="!isConstructionMortgage" class="form-group row gx-4" :class="{ 'has-error': $v.loanTargetType.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label">{{ $t("form.loanTargetType.label") }}</label>
              </div>
              <div class="col col-12 col-sm-7">
                <label class="form-button" for="loanTargetType-apartment">
                  <input id="loanTargetType-apartment" type="radio" name="loanTargetType" value="APARTMENT" v-model="loanTargetType">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.loanTargetType.opts.apartment") }}</span>
                </label>
                <label class="form-button" for="loanTargetType-realEstate">
                  <input id="loanTargetType-realEstate" type="radio" name="loanTargetType" value="REAL_ESTATE" v-model="loanTargetType">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.loanTargetType.opts.realEstate") }}</span>
                </label>
                <label class="form-button" for="loanTargetType-rightOfOccupancy">
                  <input id="loanTargetType-rightOfOccupancy" type="radio" name="loanTargetType" value="RIGHT_OF_OCCUPANCY" v-model="loanTargetType">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.loanTargetType.opts.rightOfOccupancy") }}</span>
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.loanTargetType.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <!-- Katuosoite -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.streetAddress.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="streetAddress">{{ $t("form.streetAddress.label") }}</label>
                <div v-if="isConstructionMortgage" class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-6">
                <input id="streetAddress" class="form-input" type="text" name="streetAddress" v-model.trim="streetAddress">
                <div class="form-input-hint" v-if="submitted && !$v.streetAddress.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.streetAddress.maxLength">{{ $t("formError.maxLength") }}</div>
              </div>
            </div>

           <!-- Postinumero -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.zip.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="zip">{{ $t("form.zip.label") }}</label>
                <div v-if="isConstructionMortgage" class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-3">
                <input id="zip" class="form-input" type="text" name="zip" v-white-space v-model.trim="zip">
                <div class="form-input-hint" v-if="submitted && !$v.zip.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.zip.zip">{{ $t("formError.zip") }}</div>
              </div>
            </div>

            <!-- Postitoimipaikka -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.city.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="city">{{ $t("form.city.label") }}</label>
                <div v-if="isConstructionMortgage" class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-5">
                <input id="city" class="form-input" type="text" name="city" v-model.trim="city">
                <div class="form-input-hint" v-if="submitted && !$v.city.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.city.maxLength">{{ $t("formError.maxLength") }}</div>
              </div>
            </div>

            <!-- Asunnon myynti-ilmoitus -->
            <div v-if="!isConstructionMortgage && !isRefinance" class="form-group row gx-4" :class="{ 'has-error': $v.advertisementUrl.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="advertisementUrl">{{ $t("form.advertisementUrl.label") }}</label>
                <div class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-7">
                <input id="advertisementUrl" class="form-input" type="text" name="advertisementUrl" :placeholder="$t('form.advertisementUrl.placeholder')" v-model.trim="advertisementUrl">
                <div class="form-input-hint text-gray">{{ $t("form.advertisementUrl.hint") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.advertisementUrl.maxLength">{{ $t("formError.maxLength") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.advertisementUrl.url">{{ $t("formError.url") }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12 col-sm-4"></div>
              <div class="col col-12 col-sm-7">
                <div class="divider mb-6"></div>
              </div>
            </div>

            <!-- Asunnon alkuperäinen myyntihinta -->
            <div v-if="(isRefinance && !isRightOfOccupancy) || (!isApartment && !isRealEstate && !isRightOfOccupancy)" class="form-group row gx-4" :class="{ 'has-error': $v.originalSellingPrice.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="originalSellingPrice">
                  {{ $t("form.purchasePrice.label") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.purchasePrice.info')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-4">
                <div class="has-unit-right">
                  <input id="originalSellingPrice" class="form-input" type="text" name="originalSellingPrice" v-model.number="originalSellingPrice">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.originalSellingPrice.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.originalSellingPrice.between">{{ $t("formError.between", { min: $options.filters.currency($v.originalSellingPrice.$params.between.min, 0, ''), max: $options.filters.currency($v.originalSellingPrice.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.originalSellingPrice.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Asunnon velaton hinta -->
            <div v-if="(!isRefinance && !isConstructionMortgage && isApartment) || (!isApartment && !isRealEstate && !isRightOfOccupancy)" class="form-group row gx-4" :class="{ 'has-error': $v.debtFreePrice.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="debtFreePrice">{{ $t("form.debtFreePrice.label") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.debtFreePrice.info')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-4">
                <div class="has-unit-right">
                  <input id="debtFreePrice" class="form-input" type="text" name="debtFreePrice" v-model.number="debtFreePrice">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.debtFreePrice.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.debtFreePrice.between">{{ $t("formError.between", { min: $options.filters.currency($v.debtFreePrice.$params.between.min, 0, ''), max: $options.filters.currency($v.debtFreePrice.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.debtFreePrice.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Asunnon myyntihinta -->
            <div v-if="(!isRefinance && !isConstructionMortgage && (isApartment || isRealEstate)) || (!isApartment && !isRealEstate && !isRightOfOccupancy)" class="form-group row gx-4" :class="{ 'has-error': $v.sellingPrice.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="sellingPrice">
                  {{ $t("form.sellingPrice.label") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.sellingPrice.info')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-4">
                <div class="has-unit-right">
                  <input id="sellingPrice" class="form-input" type="text" name="sellingPrice" v-model.number="sellingPrice">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.sellingPrice.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.sellingPrice.between">{{ $t("formError.between", { min: $options.filters.currency($v.sellingPrice.$params.between.min, 0, ''), max: $options.filters.currency($v.sellingPrice.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && isApartment && !$v.sellingPrice.lessThanOrEqual">{{ $t("formError.sellingPriceLessThanOrEqual") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.sellingPrice.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Asumisoikeusmaksu -->
            <div v-if="!isConstructionMortgage && isRightOfOccupancy" class="form-group row gx-4" :class="{ 'has-error': $v.rightOfOccupancySellingPrice.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="rightOfOccupancySellingPrice">
                  {{ $t("form.rightOfOccupancySellingPrice.label") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.rightOfOccupancySellingPrice.info')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-4">
                <div class="has-unit-right">
                  <input id="rightOfOccupancySellingPrice" class="form-input" type="text" name="rightOfOccupancySellingPrice" v-model.number="rightOfOccupancySellingPrice">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.rightOfOccupancySellingPrice.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.rightOfOccupancySellingPrice.between">{{ $t("formError.between", { min: $options.filters.currency($v.rightOfOccupancySellingPrice.$params.between.min, 0, ''), max: $options.filters.currency($v.rightOfOccupancySellingPrice.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.rightOfOccupancySellingPrice.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Alkuperäinen asumisoikeusmaksu -->
            <div v-if="!isConstructionMortgage && isRightOfOccupancy" class="form-group row gx-4" :class="{ 'has-error': $v.originalRightOfOccupancyFee.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="originalRightOfOccupancyFee">
                  {{ $t("form.originalRightOfOccupancyFee.label") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.originalRightOfOccupancyFee.info')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-4">
                <div class="has-unit-right">
                  <input id="originalRightOfOccupancyFee" class="form-input" type="text" name="originalRightOfOccupancyFee" v-model.number="originalRightOfOccupancyFee">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.originalRightOfOccupancyFee.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.originalRightOfOccupancyFee.between">{{ $t("formError.between", { min: $options.filters.currency($v.originalRightOfOccupancyFee.$params.between.min, 0, ''), max: $options.filters.currency($v.originalRightOfOccupancyFee.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.originalRightOfOccupancyFee.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Asunnon yhtiölainaosuus -->
            <div v-if="isRefinance && isApartment" class="form-group row gx-4" :class="{ 'has-error': $v.housingCompanyLoan.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="housingCompanyLoan">{{ $t("form.housingCompanyLoan.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4">
                <div class="has-unit-right">
                  <input id="housingCompanyLoan" class="form-input" type="text" name="housingCompanyLoan" v-model.number="housingCompanyLoan">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.housingCompanyLoan.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.housingCompanyLoan.between">{{ $t("formError.between", { min: $options.filters.currency($v.housingCompanyLoan.$params.between.min, 0, ''), max: $options.filters.currency($v.housingCompanyLoan.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.housingCompanyLoan.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Asunnon hankintavuosi -->
            <div v-if="isRefinance" class="form-group row gx-4" :class="{ 'has-error': $v.purchaseYear.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="purchaseYear">{{ $t("form.purchaseYear.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4">
                <select id="purchaseYear" class="form-select" name="purchaseYear" v-model="purchaseYear">
                  <option value="" disabled>{{ $t("year") }}</option>
                  <option v-for="year in pastYears" :key="year" :value="year">{{year}}</option>
                </select>
                <div class="form-input-hint" v-if="submitted && !$v.purchaseYear.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <!-- Tontin tyyppi -->
            <div v-if="isConstructionMortgage" class="form-group row gx-4" :class="{ 'has-error': $v.isBuildingGroundRented.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label">{{ $t("form.isBuildingGroundRented.label") }}</label>
              </div>
              <div class="col col-12 col-sm-7">
                <label class="form-button" for="isBuildingGroundRented-false">
                  <input id="isBuildingGroundRented-false" type="radio" name="isBuildingGroundRented" :value="false" v-model="isBuildingGroundRented">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.isBuildingGroundRented.opts.no") }}</span>
                </label>
                <label class="form-button" for="isBuildingGroundRented-true">
                  <input id="isBuildingGroundRented-true" type="radio" name="isBuildingGroundRented" :value="true" v-model="isBuildingGroundRented">
                  <span class="form-button-body"><i class="form-icon"></i>{{ $t("form.isBuildingGroundRented.opts.yes") }}</span>
                </label>
                <div class="form-input-hint" v-if="submitted && !$v.isBuildingGroundRented.required">{{ $t("formError.required") }}</div>
              </div>
            </div>

            <!-- Tontin myynti-ilmoitus -->
            <div v-if="isConstructionMortgage && !isBuildingGroundRented" class="form-group row gx-4" :class="{ 'has-error': $v.advertisementUrl.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="advertisementUrl">{{ $t("form.advertisementUrl.label_2") }}</label>
                <div class="form-label-hint">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-7">
                <input id="advertisementUrl" class="form-input" type="text" name="advertisementUrl" :placeholder="$t('form.advertisementUrl.placeholder')" v-model.trim="advertisementUrl">
                <div class="form-input-hint text-gray">{{ $t("form.advertisementUrl.hint_2") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.advertisementUrl.maxLength">{{ $t("formError.maxLength") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.advertisementUrl.url">{{ $t("formError.url") }}</div>
              </div>
            </div>

            <!-- Tontin myyntihinta -->
            <div v-if="isConstructionMortgage && !isBuildingGroundRented" class="form-group row gx-4" :class="{ 'has-error': $v.buildingGroundPrice.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="buildingGroundPrice">{{ $t("form.buildingGroundPrice.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4">
                <div class="has-unit-right">
                  <input id="buildingGroundPrice" class="form-input" type="text" name="buildingGroundPrice" v-model.number="buildingGroundPrice">
                  <div class="form-unit">&euro;</div>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingGroundPrice.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingGroundPrice.between">{{ $t("formError.between", { min: $options.filters.currency($v.buildingGroundPrice.$params.between.min, 0, ''), max: $options.filters.currency($v.buildingGroundPrice.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingGroundPrice.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Tontin vuokra -->
            <div v-if="isConstructionMortgage && isBuildingGroundRented" class="form-group row gx-4" :class="{ 'has-error': $v.buildingGroundRentAmount.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="buildingGroundRentAmount">{{ $t("form.buildingGroundRentAmount.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4">
                <div class="has-unit-right">
                  <input id="buildingGroundRentAmount" class="form-input" type="text" name="buildingGroundRentAmount" v-model.number="buildingGroundRentAmount">
                  <div class="form-unit">&euro;/v</div>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingGroundRentAmount.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingGroundRentAmount.between">{{ $t("formError.between", { min: $options.filters.currency($v.buildingGroundRentAmount.$params.between.min, 0, ''), max: $options.filters.currency($v.buildingGroundRentAmount.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingGroundRentAmount.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Tontin vuokraoikeuden voimassaolo -->
            <div v-if="isConstructionMortgage && isBuildingGroundRented" class="form-group row gx-4" :class="{ 'has-error': $v.buildingGroundLeaseEndDate.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="buildingGroundLeaseEndDate">{{ $t("form.buildingGroundLeaseEndDate.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4">
                <input id="buildingGroundLeaseEndDate" class="form-input" type="text" name="buildingGroundLeaseEndDate" placeholder="1.10.2030" v-model.trim="buildingGroundLeaseEndDate">
                <div class="form-input-hint" v-if="submitted && !$v.buildingGroundLeaseEndDate.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingGroundLeaseEndDate.date">{{ $t("formError.date") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingGroundLeaseEndDate.futureDate">{{ $t("formError.futureDate") }}</div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12 col-sm-4"></div>
              <div class="col col-12 col-sm-7">
                <div class="divider mb-6"></div>
              </div>
            </div>

            <!-- Rakentamisen kustannusarvio -->
            <div v-if="isConstructionMortgage" class="form-group row gx-4" :class="{ 'has-error': $v.buildingCostEstimate.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="buildingCostEstimate">{{ $t("form.buildingCostEstimate.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4">
                <div class="has-unit-right">
                  <input id="buildingCostEstimate" class="form-input" type="text" name="buildingCostEstimate" v-model.number="buildingCostEstimate">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingCostEstimate.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingCostEstimate.between">{{ $t("formError.between", { min: $options.filters.currency($v.buildingCostEstimate.$params.between.min), max: $options.filters.currency($v.buildingCostEstimate.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.buildingCostEstimate.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <div v-if="isConstructionMortgage" class="row">
              <div class="col col-12 col-sm-4">
              </div>
              <div class="col col-12 col-sm-7">
                <p class="text-gray">Tarvitsetko apua rakennuskustannusten arvioinnissa? <a href="https://www.prkk.fi/aktia" target="_blank" class="text-nowrap">Kokeile kustannuslaskuria.<Icon class="ml-1" name="launch" /></a></p>
                <div class="divider my-6"></div>
              </div>
            </div>

            <!-- Hoitovastike -->
            <div v-if="(!isConstructionMortgage && isApartment) || (!isApartment && !isRealEstate && !isRightOfOccupancy)" class="form-group row gx-4" :class="{ 'has-error': $v.maintenanceCost.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="maintenanceCost">
                  {{ $t("form.maintenanceCost.label") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.maintenanceCost.info')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="maintenanceCost" class="form-input" type="text" name="maintenanceCost" v-model.number="maintenanceCost">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.maintenanceCost.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.maintenanceCost.between">{{ $t("formError.between", { min: $options.filters.currency($v.maintenanceCost.$params.between.min, 0, ''), max: $options.filters.currency($v.maintenanceCost.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.maintenanceCost.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Rahoitusvastike -->
            <div v-if="(!isConstructionMortgage && isApartment) || (!isApartment && !isRealEstate && !isRightOfOccupancy)" class="form-group row gx-4" :class="{ 'has-error': $v.financingCost.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="financingCost">
                  {{ $t("form.financingCost.label") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.financingCost.info')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="financingCost" class="form-input" type="text" name="financingCost" v-model.number="financingCost">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.financingCost.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.financingCost.between">{{ $t("formError.between", { min: $options.filters.currency($v.financingCost.$params.between.min, 0, ''), max: $options.filters.currency($v.financingCost.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.financingCost.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Käyttövastike -->
            <div v-if="!isConstructionMortgage && isRightOfOccupancy" class="form-group row gx-4" :class="{ 'has-error': $v.residenceCharge.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="residenceCharge">
                  {{ $t("form.residenceCharge.label") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.residenceCharge.info')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="residenceCharge" class="form-input" type="text" name="residenceCharge" v-model.number="residenceCharge">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.residenceCharge.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.residenceCharge.between">{{ $t("formError.between", { min: $options.filters.currency($v.residenceCharge.$params.between.min, 0, ''), max: $options.filters.currency($v.residenceCharge.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.residenceCharge.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Asumiskustannukset -->
            <div class="form-group row gx-4" :class="{ 'has-error': $v.otherHousingExpenses.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label v-if="isRealEstate" class="form-label" for="otherHousingExpenses">
                  {{ $t("form.otherHousingExpenses.label_1") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.otherHousingExpenses.info_2')">
                    <Icon name="help" />
                  </span>
                </label>
                <label v-else class="form-label" for="otherHousingExpenses">
                  {{ $t("form.otherHousingExpenses.label_2") }}
                  <span class="tooltip tooltip-info" :data-tooltip="$t('form.otherHousingExpenses.info_1')">
                    <Icon name="help" />
                  </span>
                </label>
              </div>
              <div class="col col-12 col-sm-3">
                <div class="has-unit-right">
                  <input id="otherHousingExpenses" class="form-input" type="text" name="otherHousingExpenses" v-model.number="otherHousingExpenses">
                  <span class="form-unit">{{ $t("eurosPerMonth") }}</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.otherHousingExpenses.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.otherHousingExpenses.between">{{ $t("formError.between", { min: $options.filters.currency($v.otherHousingExpenses.$params.between.min, 0, ''), max: $options.filters.currency($v.otherHousingExpenses.$params.between.max, 0, ` ${$t("eurosPerMonth")}`) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.otherHousingExpenses.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <h2 v-if="isRefinance" class="h3 heading-underline heading-underline-lg mt-12 mb-8">{{ $t("secondStep.currentMortgage.title") }}</h2>

            <!-- Nykyisen asuntolainan määrä-->
            <div v-if="isRefinance" class="form-group row gx-4" :class="{ 'has-error': $v.currentMortgageAmount.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label" for="currentMortgageAmount">{{ $t("form.currentMortgageAmount.label") }}</label>
              </div>
              <div class="col col-12 col-sm-4">
                <div class="has-unit-right">
                  <input id="currentMortgageAmount" class="form-input" type="text" name="currentMortgageAmount" v-model.number="currentMortgageAmount">
                  <span class="form-unit">&euro;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.currentMortgageAmount.required">{{ $t("formError.required") }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.currentMortgageAmount.between">{{ $t("formError.between", { min: $options.filters.currency($v.currentMortgageAmount.$params.between.min, 0, ''), max: $options.filters.currency($v.currentMortgageAmount.$params.between.max) }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.currentMortgageAmount.integer">{{ $t("formError.integer") }}</div>
              </div>
            </div>

            <!-- Nykyisen asuntolainan pankki -->
            <div v-if="isRefinance" class="form-group row gx-4">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="currentMortgageBank">{{ $t("form.currentMortgageBank.label") }}</label>
                <div v-if="isRefinance">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-4">
                <select id="currentMortgageBank" class="form-select" name="currentMortgageBank" v-model="currentMortgageBank">
                  <option value="">{{ $t("select") }}</option>
                  <option value="AKTIA">{{ $t("form.currentMortgageBank.opts.aktia") }}</option>
                  <option value="OSUUSPANKKI">{{ $t("form.currentMortgageBank.opts.osuuspankki") }}</option>
                  <option value="NORDEA">{{ $t("form.currentMortgageBank.opts.nordea") }}</option>
                  <option value="DANSKE">{{ $t("form.currentMortgageBank.opts.danske") }}</option>
                  <option value="POPPANKKI">{{ $t("form.currentMortgageBank.opts.poppankki") }}</option>
                  <option value="SAASTOPANKKI">{{ $t("form.currentMortgageBank.opts.saastopankki") }}</option>
                  <option value="SPANKKI">{{ $t("form.currentMortgageBank.opts.spankki") }}</option>
                  <option value="HANDELSBANKEN">{{ $t("form.currentMortgageBank.opts.handelsbanken") }}</option>
                  <option value="ALANDSBANKEN">{{ $t("form.currentMortgageBank.opts.alandsbanken") }}</option>
                  <option value="OMASP">{{ $t("form.currentMortgageBank.opts.omasp") }}</option>
                  <option value="HYPO">{{ $t("form.currentMortgageBank.opts.hypo") }}</option>
                  <option value="OTHER">{{ $t("form.currentMortgageBank.opts.other") }}</option>
                </select>
              </div>
            </div>

            <!-- Nykyisen asuntolainan marginaali -->
            <div v-if="isRefinance" class="form-group row gx-4" :class="{ 'has-error': $v.currentMortgageMargin.$error }">
              <div class="col col-12 col-sm-4 col-form-label">
                <label class="form-label mb-0" for="currentMortgageMargin">{{ $t("form.currentMortgageMargin.label") }}</label>
                <div v-if="isRefinance">{{ $t("formHint.optional") }}</div>
              </div>
              <div class="col col-12 col-sm-2">
                <div class="has-unit-right">
                  <input id="currentMortgageMargin" class="form-input" type="text" name="currentMortgageMargin" v-decimal v-positive-number v-model.trim="currentMortgageMargin">
                  <span class="form-unit">&percnt;</span>
                </div>
                <div class="form-input-hint" v-if="submitted && !$v.currentMortgageMargin.between">{{ $t("formError.between", { min: $v.currentMortgageMargin.$params.between.min, max: $options.filters.currency($v.currentMortgageMargin.$params.between.max, 0, ' %') }) }}</div>
                <div class="form-input-hint" v-if="submitted && !$v.currentMortgageMargin.decimal">{{ $t("formError.decimal") }}</div>
              </div>
            </div>

            <Toast v-if="submitted && $v.$invalid" error class="mt-8">
              <div class="row gx-4">
                <div class="col col-auto">
                  <Icon name="info" />
                </div>
                <div class="col">
                  {{ $t("formError.anyError") }}
                </div>
              </div>
            </Toast>

            <div class="d-flex justify-content-between mt-12">
              <router-link id="secondStepPrev" to="/1" class="btn btn-lg btn-prev">
                {{ $t("prev") }}
              </router-link>
              <button id="secondStepNext" type="submit" class="btn btn-lg btn-primary btn-next">
                {{ $t("next") }}<Icon class="ml-1" name="arrow-forward" />
              </button>
            </div>
          </form>

          <Footer />
        </div>

        <!-- Sidebar -->
        <div class="col col-12 col-md-3">
          <Stepper />
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Stepper from '@/components/Stepper.vue'
import { between, decimal, integer, maxLength, required, requiredIf } from 'vuelidate/lib/validators'
import { date, futureDate, lessThanOrEqual, url, zip } from '@/validators'

export default {
  name: 'second-step',
  components: {
    Stepper
  },
  data() {
    return {
      submitted: false
    }
  },
  computed: {
    pastYears() {
      const currentYear = new Date().getFullYear()
      const startYear = 1900
      const endYear = currentYear
      return Array.from({length: endYear - (startYear - 1)}, (value, index) => startYear + index).reverse()
    },
    isConstructionMortgage() {
      return this.$store.getters.isConstructionMortgage
    },
    isRefinance() {
      return this.$store.getters.isRefinance
    },
    isLoanTargetKnown() {
      return this.$store.state.isLoanTargetKnown
    },
    isApartment() {
      return this.$store.getters.isApartment
    },
    isRealEstate() {
      return this.$store.getters.isRealEstate
    },
    isRightOfOccupancy() {
      return this.$store.getters.isRightOfOccupancy
    },
    loanTargetType: {
      get() {
        return this.$store.state.loanTarget.type
      },
      set(value) {
        this.$store.commit('setLoanTargetType', value)

        if (value === 'RIGHT_OF_OCCUPANCY') {
          this.$store.commit('resetCollateralsOnRightOfOccupancy')
        }
      }
    },
    streetAddress: {
      get() {
        return this.$store.state.loanTarget.address.streetAddress
      },
      set(value) {
        this.$store.commit('setLoanTargetStreetAddress', value)
      }
    },
    zip: {
      get() {
        return this.$store.state.loanTarget.address.zip
      },
      set(value) {
        this.$store.commit('setLoanTargetZip', value)
      }
    },
    city: {
      get() {
        return this.$store.state.loanTarget.address.city
      },
      set(value) {
        this.$store.commit('setLoanTargetCity', value)
      }
    },
    originalSellingPrice: {
      get() {
        return this.$store.state.loanTarget.originalSellingPrice
      },
      set(value) {
        this.$store.commit('setOriginalSellingPrice', value)
      }
    },
    debtFreePrice: {
      get() {
        return this.$store.state.loanTarget.debtFreePrice
      },
      set(value) {
        this.$store.commit('setDebtFreePrice', value)
      }
    },
    sellingPrice: {
      get() {
        return this.$store.state.loanTarget.sellingPrice
      },
      set(value) {
        this.$store.commit('setSellingPrice', value)
      }
    },
    housingCompanyLoan: {
      get() {
        return this.$store.state.loanTarget.housingCompanyLoan
      },
      set(value) {
        this.$store.commit('setHousingCompanyLoan', value)
      }
    },
    rightOfOccupancySellingPrice: {
      get() {
        return this.$store.state.loanTarget.rightOfOccupancySellingPrice
      },
      set(value) {
        this.$store.commit('setRightOfOccupancySellingPrice', value)
      }
    },
    originalRightOfOccupancyFee: {
      get() {
        return this.$store.state.loanTarget.originalRightOfOccupancyFee
      },
      set(value) {
        this.$store.commit('setOriginalRightOfOccupancyFee', value)
      }
    },
    buildingCostEstimate: {
      get() {
        return this.$store.state.loanTarget.buildingCostEstimate
      },
      set(value) {
        this.$store.commit('setBuildingCostEstimate', value)
      }
    },
    isBuildingGroundRented: {
      get() {
        return this.$store.state.loanTarget.isBuildingGroundRented
      },
      set(value) {
        this.$store.commit('setIsBuildingGroundRented', value)
      }
    },
    buildingGroundPrice: {
      get() {
        return this.$store.state.loanTarget.buildingGroundPrice
      },
      set(value) {
        this.$store.commit('setBuildingGroundPrice', value)
      }
    },
    buildingGroundRentAmount: {
      get() {
        return this.$store.state.loanTarget.buildingGroundRentAmount
      },
      set(value) {
        this.$store.commit('setBuildingGroundRentAmount', value)
      }
    },
    buildingGroundLeaseEndDate: {
      get() {
        return this.$store.state.loanTarget.buildingGroundLeaseEndDate
      },
      set(value) {
        this.$store.commit('setBuildingGroundLeaseEndDate', value)
      }
    },
    advertisementUrl: {
      get() {
        return this.$store.state.loanTarget.advertisementUrl
      },
      set(value) {
        this.$store.commit('setAdvertisementUrl', value)
      }
    },
    purchaseYear: {
      get() {
        return this.$store.state.loanTarget.purchaseYear
      },
      set(value) {
        this.$store.commit('setPurchaseYear', value)
      }
    },
    maintenanceCost: {
      get() {
        return this.$store.state.loanTarget.maintenanceCost
      },
      set(value) {
        this.$store.commit('setMaintenanceCost', value)
      }
    },
    financingCost: {
      get() {
        return this.$store.state.loanTarget.financingCost
      },
      set(value) {
        this.$store.commit('setFinancingCost', value)
      }
    },
    residenceCharge: {
      get() {
        return this.$store.state.loanTarget.residenceCharge
      },
      set(value) {
        this.$store.commit('setResidenceCharge', value)
      }
    },
    otherHousingExpenses: {
      get() {
        return this.$store.state.loanTarget.otherHousingExpenses
      },
      set(value) {
        this.$store.commit('setOtherHousingExpenses', value)
      }
    },
    currentMortgageAmount: {
      get() {
        return this.$store.state.currentMortgage.amount
      },
      set(value) {
        this.$store.commit('setCurrentMortgageAmount', value)
      }
    },
    currentMortgageBank: {
      get() {
        return this.$store.state.currentMortgage.bank
      },
      set(value) {
        this.$store.commit('setCurrentMortgageBank', value)
      }
    },
    currentMortgageMargin: {
      get() {
        return this.$store.state.currentMortgage.margin
      },
      set(value) {
        this.$store.commit('setCurrentMortgageMargin', value)
      }
    },
  },
  validations() {
    return {
      loanTargetType: {
        required: requiredIf(() => !this.isConstructionMortgage)
      },
      streetAddress: {
        required: requiredIf(() => !this.isConstructionMortgage),
        maxLength: maxLength(100)
      },
      city: {
        required: requiredIf(() => !this.isConstructionMortgage),
        maxLength: maxLength(100)
      },
      zip: {
        required: requiredIf(() => !this.isConstructionMortgage),
        zip
      },
      originalSellingPrice: {
        required: requiredIf(() => (this.isRefinance && !this.isRightOfOccupancy)),
        ...(this.isRefinance && !this.isRightOfOccupancy) && {
          integer,
          between: between(10000, 1000000)
        }
      },
      debtFreePrice: {
        required: requiredIf(() => (!this.isRefinance && !this.isConstructionMortgage && this.isApartment)),
        ...(!this.isRefinance && !this.isConstructionMortgage && this.isApartment) && {
          integer,
          between: between(10000, 2000000)
        }
      },
      sellingPrice: {
        required: requiredIf(() => (!this.isRefinance && !this.isConstructionMortgage && (this.isApartment || this.isRealEstate))),
        ...(!this.isRefinance && !this.isConstructionMortgage && (this.isApartment || this.isRealEstate)) && {
          integer,
          between: between(10000, 2000000)
        },
        ...(!this.isRefinance && !this.isConstructionMortgage && this.isApartment) && {
          lessThanOrEqual: lessThanOrEqual('debtFreePrice')
        }
      },
      housingCompanyLoan: {
        required: requiredIf(() => (this.isRefinance && this.isApartment)),
        ...(this.isRefinance && this.isApartment) && {
          integer,
          between: between(0, 1000000)
        }
      },
      rightOfOccupancySellingPrice: {
        required: requiredIf(() => (!this.isConstructionMortgage && this.isRightOfOccupancy)),
        ...(!this.isConstructionMortgage && this.isRightOfOccupancy) && {
          integer,
          between: between(10000, 2000000)
        }
      },
      originalRightOfOccupancyFee: {
        required: requiredIf(() => (!this.isConstructionMortgage && this.isRightOfOccupancy)),
        ...(!this.isConstructionMortgage && this.isRightOfOccupancy) && {
          integer,
          between: between(10000, 2000000)
        }
      },
      buildingCostEstimate: {
        required: requiredIf(() => this.isConstructionMortgage),
        ...this.isConstructionMortgage && {
          integer,
          between: between(10000, 2000000)
        }
      },
      isBuildingGroundRented: {
        required: requiredIf(() => this.isConstructionMortgage)
      },
      buildingGroundPrice: {
        required: requiredIf(() => this.isConstructionMortgage && !this.isBuildingGroundRented),
        ...this.isConstructionMortgage && !this.isBuildingGroundRented && {
          integer,
          between: between(0, 2000000)
        }
      },
      buildingGroundRentAmount: {
        required: requiredIf(() => this.isConstructionMortgage && this.isBuildingGroundRented),
        ...this.isConstructionMortgage && this.isBuildingGroundRented && {
          integer,
          between: between(0, 100000)
        }
      },
      buildingGroundLeaseEndDate: {
        required: requiredIf(() => this.isConstructionMortgage && this.isBuildingGroundRented),
        ...this.isConstructionMortgage && this.isBuildingGroundRented && {
          date: date('D.M.YYYY'),
          futureDate: futureDate('D.M.YYYY')
        }
      },
      advertisementUrl: {
        ...(this.isConstructionMortgage && !this.isBuildingGroundRented) || !this.isRefinance && {
          maxLength: maxLength(1000),
          url
        }
      },
      purchaseYear: {
        required: requiredIf(() => this.isRefinance)
      },
      maintenanceCost: {
        required: requiredIf(() => !this.isConstructionMortgage && this.isApartment),
        ...!this.isConstructionMortgage && this.isApartment && {
          integer,
          between: between(0, 10000)
        }
      },
      financingCost: {
        required: requiredIf(() => !this.isConstructionMortgage && this.isApartment),
        ...!this.isConstructionMortgage && this.isApartment && {
          integer,
          between: between(0, 10000)
        }
      },
      residenceCharge: {
        required: requiredIf(() => !this.isConstructionMortgage && this.isRightOfOccupancy),
        ...!this.isConstructionMortgage && this.isRightOfOccupancy && {
          integer,
          between: between(0, 10000)
        }
      },
      otherHousingExpenses: {
        required,
        integer,
        between: between(0, 10000)
      },
      currentMortgageAmount: {
        required: requiredIf(() => this.isRefinance),
        ...this.isRefinance && {
          integer,
          between: between(10000, 1000000)
        }
      },
      currentMortgageMargin: {
        ...this.isRefinance && {
          decimal,
          between: between(0, 5)
        }
      }
    }
  },
  created() {
    this.$store.commit('setIsSecondStepSubmitted', false)
  },
  methods: {
    handleSubmit() {
      this.submitted = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$store.commit('setIsSecondStepSubmitted', true)
        this.$router.push({ name: 'thirdStep' })
      }
    }
  }
}
</script>
