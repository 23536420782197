<template>
  <div :class="{ 'has-error': hasError }">
    <label v-if="label" :class="labelClassList" :for="id">{{ label }}</label>
    <div :class="containerClassList">
      <input
        autocomplete="off"
        :aria-invalid="hasError"
        :id="id"
        :ref="id"
        :class="inputClassList"
        :type="type"
        v-model="displayValue"
        v-bind="$attrs"
        v-on="inputListeners"
      />
      <span v-if="unitLeft" :class="unitClassList">{{ unitLeft }}</span>
      <span v-if="unitRight" :class="unitClassList">{{ unitRight }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'number-input',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: [ Number, String ],
      default: ''
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    unitLeft: {
      type: String,
      default: ''
    },
    unitRight: {
      type: String,
      default: ''
    },
    sm: {
      type: Boolean,
      default: false
    },
    lg: {
      type: Boolean,
      default: false
    },
    textCenter: {
      type: Boolean,
      default: false
    },
    textRight: {
      type: Boolean,
      default: false
    },
    decimals: {
      type: Number,
      default: 0
    },
    thousandsSeparator: {
      type: String,
      default: ' '
    },
    decimalSeparator: {
      type: String,
      default: ','
    }
  },
  data() {
    return {
      realValue: this.value,
      displayValue: this.value,
      isFocused: false,
      numberFormat: this.$wNumb({
        decimals: this.decimals,
        mark: this.decimalSeparator,
        thousand: this.thousandsSeparator
      })
    }
  },
  computed: {
    inputClassList() {
      return {
        'form-input': true,
        'input-sm': this.sm,
        'input-lg': this.lg,
        'text-center': this.textCenter,
        'text-right': this.textRight
      }
    },
    labelClassList() {
      return {
        'form-label': true,
        'label-sm': this.sm,
        'label-lg': this.lg
      }
    },
    containerClassList() {
      return {
        'has-unit-left': this.unitLeft !== '',
        'has-unit-right': this.unitRight !== ''
      }
    },
    unitClassList() {
      return {
        'form-unit': true,
        'unit-sm': this.sm,
        'unit-lg': this.lg
      }
    },
    inputListeners() {
      const vm = this
      return Object.assign(
        {},
        this.$listeners,
        {
          input(e) {
            vm.onInput(e)
            vm.$emit('input', vm.realValue)
          },
          blur(e) {
            vm.onBlur(e)
            vm.$emit('blur', e)
          },
          focus(e) {
            vm.onFocus()
            vm.$emit('focus', e)
          }
        }
      )
    }
  },
  watch: {
    value(newValue) {
      this.realValue = newValue
      if (!this.isFocused) {
        this.updateDisplayValue()
      }
    }
  },
  created() {
    this.updateDisplayValue()
  },
  methods: {
    onInput(e) {
      const realValue = this.numberFormat.from(this.displayValue)
      this.realValue = realValue ? parseFloat(realValue.toFixed(this.decimals)) : e.target.value
    },
    onBlur() {
      this.isFocused = false
      this.updateDisplayValue()
    },
    onFocus() {
      this.isFocused = true
    },
    updateDisplayValue() {
      const displayValue = this.numberFormat.to(this.realValue)
      this.displayValue = displayValue ? displayValue : this.realValue
    }
  }
}
</script>
