export class LoanCalculator {

  static calculateLoanAmount(loanPayment, loanTimeInYears, numberOfPaymentsPerYear, loanInterestPerYear) {
    const loanInterestPerPayment = loanInterestPerYear / numberOfPaymentsPerYear
    const numberOfPayments = numberOfPaymentsPerYear * loanTimeInYears
    const loanAmount = (loanPayment / loanInterestPerPayment) * (1 - Math.pow((1 + loanInterestPerPayment), -numberOfPayments))
    return loanAmount
  }

  static calculateLoanPayment(loanAmount, loanTimeInYears, numberOfPaymentsPerYear, loanInterestPerYear) {
    const loanInterestPerPayment = loanInterestPerYear / numberOfPaymentsPerYear
    const numberOfPayments = numberOfPaymentsPerYear * loanTimeInYears
    const loanPayment = ((Math.pow((1 + loanInterestPerPayment), numberOfPayments) * loanInterestPerPayment) / (Math.pow((1 + loanInterestPerPayment), numberOfPayments) - 1)) * loanAmount
    return loanPayment
  }

  static calculateLoanTime(loanAmount, loanPayment, numberOfPaymentsPerYear, loanInterestPerYear) {
    const loanInterestPerPayment = loanInterestPerYear / numberOfPaymentsPerYear
    const numberOfPayments = -(Math.log(1 - loanInterestPerPayment * loanAmount / loanPayment) / Math.log(1 + loanInterestPerPayment))
    const loanPaymentIntervalInMonths = 12 / numberOfPaymentsPerYear
    const loanTimeInMonths = numberOfPayments * loanPaymentIntervalInMonths
    const years = Math.floor(loanTimeInMonths / 12)
    const months = Math.ceil(loanTimeInMonths % 12)
    return months === 12 ? { years: years + 1, months: 0, numberOfPayments: numberOfPayments } : { years: years, months: months, numberOfPayments: numberOfPayments }
  }

  static calculateEffectiveInterest(loanAmount, loanTimeInYears, loanPayment, numberOfPaymentsPerYear, originationFee, serviceFee, guess, type = 1) {
    const numberOfPayments = loanTimeInYears * numberOfPaymentsPerYear
    const loanPaymentWithFee = -(loanPayment + serviceFee)
    const loanAmountWithFee = loanAmount - originationFee

    // Sets the limits for possible guesses to any number between 0% and 100%
    let lowLimit = 0
    let highLimit = 1

    // Defines a tolerance of up to +/- 0.00005% of loan payment, to accept the solution as valid
    const tolerance = Math.abs(0.00000005 * loanPaymentWithFee)

    // Tries at most 40 times to find a solution within the tolerance
    for (let i = 0; i < 40; i++) {
      // Resets the balance to the original loan amount
      let balance = loanAmountWithFee

      // Calculates the balance at the end of the loan, based on loan conditions
      for (let j = 0; j < numberOfPayments; j++ ) {
        if (type == 0) {
          // Interests applied before payment
          balance = balance * (1 + guess) + loanPaymentWithFee
        } else {
          // Payments applied before insterests
          balance = (balance + loanPaymentWithFee) * (1 + guess)
        }
      }

      // Returns the guess if balance is within tolerance.
      // If not, adjusts the limits and starts with a new guess.
      if (Math.abs(balance) < tolerance) {
        return guess * numberOfPaymentsPerYear
      } else if (balance > 0) {
        // Sets a new highLimit knowing that the current guess was too big.
        highLimit = guess
      } else  {
        // Sets a new lowLimit knowing that the current guess was too small.
        lowLimit = guess
      }

      // Calculates the new guess
      guess = (highLimit + lowLimit) / 2
    }

    // Returns null if no acceptable result was found after 40 tries.
    return null
  }

}
