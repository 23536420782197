import moment from 'moment'
import zipCodes from '@/data/zipCodes.json'
import { helpers } from 'vuelidate/lib/validators'

/*
 * Checks that date is in provided date string format
 */
export const date = (dateStringFormat = 'D.M.YYYY') => value => {
  return !helpers.req(value) || moment(value, dateStringFormat, true).isValid()
}

/*
 * Checks that date is today or in the future.
 * By default the input value is compared against today's date but
 * optionally some other comparison date can be provided as a parameter.
 * Both input value and comparisonDate param must be in same date string format.
 */
export const futureDate = (dateStringFormat = 'YYYY-MM-DD', comparisonDate) => value => {
  const isValueDateStringValid = date(dateStringFormat)(value)

  // If comparisonDate param is present check that both value and param are in 'YYYY-MM-DD' format
  // and that value date has occured after than comparison date
  if (comparisonDate) {
    const isComparisonDateStringValid = date(dateStringFormat)(comparisonDate)
    return !helpers.req(value) || !isValueDateStringValid || (isValueDateStringValid && isComparisonDateStringValid && moment(comparisonDate, dateStringFormat, true).isSameOrBefore(moment(value, dateStringFormat, true)))
  }

  // If comparisonDate param is not present check that value is in given format
  // and that value date has occured after today's date
  const today = moment().format(dateStringFormat)
  return !helpers.req(value) || !isValueDateStringValid || (isValueDateStringValid && moment(today, dateStringFormat, true).isSameOrBefore(moment(value, dateStringFormat, true)))
}

/*
 * Checks that date is today or in the past.
 * By default the input value is compared against today's date but
 * optionally some other comparison date can be provided as a parameter.
 * Both input value and comparisonDate param must be in same date string format.
 */
export const pastDate = (dateStringFormat = 'YYYY-MM-DD', comparisonDate) => value => {
  const isValueDateStringValid = date(dateStringFormat)(value)

  // If comparisonDate param is present check that both value and param are in 'YYYY-MM-DD' format
  // and that value date has occured before than comparison date
  if (comparisonDate) {
    const isComparisonDateStringValid = date(dateStringFormat)(comparisonDate)
    return !helpers.req(value) || !isValueDateStringValid || (isValueDateStringValid && isComparisonDateStringValid && moment(comparisonDate, dateStringFormat, true).isSameOrAfter(moment(value, dateStringFormat, true)))
  }

  // If comparisonDate param is not present check that value is in given format
  // and that value date has occured before today's date
  const today = moment().format(dateStringFormat)
  return !helpers.req(value) || !isValueDateStringValid || (isValueDateStringValid && moment(today, dateStringFormat, true).isSameOrAfter(moment(value, dateStringFormat, true)))
}

export const ssn = value => {
  return !helpers.req(value) || isValidSsn(value)
}

const checksums = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y']

export function isValidSsn(ssn) {
  const regExp = new RegExp("\\d{6}[+-YXWVUABCDEF]\\d{3}["+checksums.join('')+"]$")
  return regExp.test(ssn) && isValidChecksum(ssn)
}

function isValidChecksum(ssn) {
  return getChecksum(ssn) == ssn.charAt(10)
}

function getChecksum(ssn) {
  const appended = ssn.substring(0, 6) + ssn.substring(7, 10)
  return checksums[parseInt(appended) % 31]
}

export const zip = value => {
  return !helpers.req(value) || zipCodes.includes(value)
}

export const phoneNumber = value => {
  if (value.startsWith('+358')) {
    // Finnish international number
    return !helpers.req(value) || /^\+358[1-9]{1}[0-9]{3,11}$/.test(value)
  } else {
    // International number
    return !helpers.req(value) || /^\+[0-9]{7,15}$/.test(value)
  }
}

export const url = value => {
  return !helpers.req(value) || /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)
}

export const email = value => {
  return !helpers.req(value) || /^[a-zA-Zåöä0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Zåöä0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-zA-Zåöä0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Zåöä0-9!#$%&'*+/=?^_`{|}~-]+)*|\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])$/.test(value)
}

export const lessThan = prop =>
  helpers.withParams(
    { type: 'lessThan', prop },
    (value, parentVm) => !helpers.req(value) || !helpers.req(helpers.ref(prop, this, parentVm)) || +value < +helpers.ref(prop, this, parentVm)
  )

export const lessThanOrEqual = prop =>
  helpers.withParams(
    { type: 'lessThanOrEqual', prop },
    (value, parentVm) => !helpers.req(value) || !helpers.req(helpers.ref(prop, this, parentVm)) || +value <= +helpers.ref(prop, this, parentVm)
  )

export const greaterThan = prop =>
  helpers.withParams(
    { type: 'greaterThan', prop },
    (value, parentVm) => !helpers.req(value) || !helpers.req(helpers.ref(prop, this, parentVm)) || +value > +helpers.ref(prop, this, parentVm)
  )

export const greaterThanOrEqual = prop =>
  helpers.withParams(
    { type: 'greaterThanOrEqual', prop },
    (value, parentVm) => !helpers.req(value) || !helpers.req(helpers.ref(prop, this, parentVm)) || +value >= +helpers.ref(prop, this, parentVm)
  )
