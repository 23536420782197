import Vue from 'vue'
import { Utils } from '@/utils/utils'

const findInputElement = el => el.tagName.toLowerCase() === 'input' ? el : el.querySelector('input')
const trimString = value => value.toString().trim().split(' ').join('')
const trimNumber = value => trimString(value).replace(',', '.')

// Transform integer to decimal number
Vue.directive('decimal', {
  bind(el, binding, vnode) {
    const element = findInputElement(el)
    element.addEventListener('blur', e => {
      const value = e.target.value
      if (Utils.isEmpty(value)) return
      const trimmedValue = trimNumber(value)
      const numberValue = Utils.toNumber(trimmedValue)
      const numberOfDecimals = binding.value || null
      if (!Utils.isFinite(numberValue)) return
      e.target.value = numberOfDecimals ? numberValue.toFixed(numberOfDecimals) : numberValue
      vnode.elm.dispatchEvent(new CustomEvent('input'))
    })
  }
})

// Transform decimal number to integer
Vue.directive('integer', {
  bind(el, binding, vnode) {
    const element = findInputElement(el)
    element.addEventListener('blur', e => {
      const value = e.target.value
      if (Utils.isEmpty(value)) return
      const trimmedValue = trimNumber(value)
      const numberValue = Utils.toNumber(trimmedValue)
      if (!Utils.isFinite(numberValue) || Utils.isInteger(numberValue)) return
      e.target.value = Utils.toInteger(numberValue)
      vnode.elm.dispatchEvent(new CustomEvent('input'))
    })
  }
})

// Transform negative number to zero
Vue.directive('positive-number', {
  bind(el, binding, vnode) {
    const element = findInputElement(el)
    element.addEventListener('blur', e => {
      const value = e.target.value
      if (Utils.isEmpty(value)) return
      const trimmedValue = trimNumber(value)
      const numberValue = Utils.toNumber(trimmedValue)
      if (!Utils.isFinite(numberValue) || numberValue >= 0) return
      e.target.value = 0
      vnode.elm.dispatchEvent(new CustomEvent('input'))
    })
  }
})

// Transform number to at least min value
Vue.directive('min-number', {
  bind(el, binding) {
    const element = findInputElement(el)
    element.addEventListener('blur', e => {
      const value = e.target.value
      if (Utils.isEmpty(value)) return
      const trimmedValue = trimNumber(value)
      const numberValue = Utils.toNumber(trimmedValue)
      const minValue = binding.value || null
      if (Utils.isFinite(numberValue) && numberValue >= minValue) return
      e.target.value = minValue
      element.dispatchEvent(new CustomEvent('input'))
    })
  }
})

// Transform number to at most max value
Vue.directive('max-number', {
  bind(el, binding) {
    const element = findInputElement(el)
    element.addEventListener('blur', e => {
      const value = e.target.value
      if (Utils.isEmpty(value)) return
      const trimmedValue = trimNumber(value)
      const numberValue = Utils.toNumber(trimmedValue)
      const maxValue = binding.value || null
      if (Utils.isFinite(numberValue) && numberValue <= maxValue) return
      e.target.value = maxValue
      element.dispatchEvent(new CustomEvent('input'))
    })
  }
})

// Remove white space
Vue.directive('white-space', {
  bind(el, binding, vnode) {
    const element = findInputElement(el)
    element.addEventListener('blur', e => {
      const value = e.target.value
      if (Utils.isEmpty(value)) return
      const trimmedValue = trimString(value)
      e.target.value = trimmedValue
      vnode.elm.dispatchEvent(new CustomEvent('input'))
    })
  }
})

// Select text on input focus
Vue.directive('text-select', {
  bind(el) {
    const element = findInputElement(el)
    element.addEventListener('focus', e => {
      const value = e.target.value
      element.setSelectionRange(0, value.length)
    })
  }
})
