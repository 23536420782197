import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { NavGuard }  from  '@/router/nav-guard'
import { Utils } from '@/utils/utils'

import FirstStep from '@/views/FirstStep.vue'
import SecondStep from '@/views/SecondStep.vue'
import ThirdStep from '@/views/ThirdStep.vue'
import FirstApplicantStep from '@/views/FirstApplicantStep.vue'
import SecondApplicantStep from '@/views/SecondApplicantStep.vue'
import FifthStep from '@/views/FifthStep.vue'
import SummaryStep from '@/views/SummaryStep.vue'
import ConfirmationStep from '@/views/ConfirmationStep.vue'

import IntroView from '@/views/IntroView.vue'
import LoginView from '@/views/LoginView.vue'
import ThanksInitialView from '@/views/ThanksInitialView.vue'
import ThanksFinalView from '@/views/ThanksFinalView.vue'
import ErrorView from '@/views/ErrorView.vue'
import MaintenanceView from '@/views/MaintenanceView.vue'

Vue.use(VueRouter)

const beforeEnter = (to, from, next) => {
  return !store.state.isSecondApplicantAuthenticated && NavGuard.isRouteAllowed(to) ? next() : next(from.path)
}

const beforeEnterConfirmation = (to, from, next) => {
  return store.state.isSecondApplicantAuthenticated ? next() : next('/intro')
}

const beforeEnterLogin = (to, from, next) => {
  return (store.getters.isAuthenticated) ? next(from.path) : next()
}

const beforeEnterMaintenance = (to, from, next) => {
  return store.state.isMaintenance ? next() : next('/')
}

const beforeEnterPsd2DataSuccess = (to, from, next) => {
  if (store.state.isFirstApplicantAuthenticated) {
    next('/4-1?psd2Success=true')
  } else if (store.state.isSecondApplicantAuthenticated) {
    next('/vahvistus?psd2Success=true')
  } else {
    next()
  }
}

const beforeEnterPsd2DataError = (to, from, next) => {
  if (store.state.isFirstApplicantAuthenticated) {
    next('/4-1?psd2Error=true')
  } else if (store.state.isSecondApplicantAuthenticated) {
    next('/vahvistus?psd2Error=true')
  } else {
    next()
  }
}

export const routes = [
  {
    path: '/',
    redirect: '/1'
  },
  {
    path: '/1',
    name: 'firstStep',
    component: FirstStep,
    meta: {
      order: 1,
      isSubmitted: store => store.state.isFirstStepSubmitted,
      skip: () => false,
      analytics: {
        descName: 'start'
      }
    },
    beforeEnter: beforeEnter
  },
  {
    path: '/2',
    name: 'secondStep',
    component: SecondStep,
    meta: {
      order: 2,
      isSubmitted: store => store.state.isSecondStepSubmitted,
      skip: store => (!store.getters.isConstructionMortgage && !store.getters.isRefinance && !store.state.isLoanTargetKnown),
      analytics: {
        descName: 'apartment-details'
      }
    },
    beforeEnter: beforeEnter
  },
  {
    path: '/3',
    name: 'thirdStep',
    component: ThirdStep,
    meta: {
      order: 3,
      isSubmitted: store => store.state.isThirdStepSubmitted,
      skip: () => false,
      analytics: {
        descName: 'loan-details'
      }
    },
    beforeEnter: beforeEnter
  },
  {
    path: '/4-1',
    name: 'fourthStepOne',
    component: FirstApplicantStep,
    meta: {
      order: 4,
      isSubmitted: store => store.state.isFourthStepOneSubmitted,
      skip: () => false,
      analytics: {
        descName: 'first-applicant'
      }
    },
    beforeEnter: beforeEnter
  },
  {
    path: '/4-2',
    name: 'fourthStepTwo',
    component: SecondApplicantStep,
    meta: {
      order: 5,
      isSubmitted: store => store.state.isFourthStepTwoSubmitted,
      skip: store => store.getters.isOneApplicant,
      analytics: {
        descName: 'second-applicant'
      }
    },
    beforeEnter: beforeEnter
  },
  {
    path: '/5',
    name: 'fifthStep',
    component: FifthStep,
    meta: {
      order: 6,
      isSubmitted: store => store.state.isFifthStepSubmitted,
      skip: () => false,
      analytics: {
        descName: 'household'
      }
    },
    beforeEnter: beforeEnter
  },
  {
    path: '/yhteenveto',
    name: 'summaryStep',
    component: SummaryStep,
    meta: {
      order: 7,
      isSubmitted: store => store.state.isSummaryStepSubmitted,
      skip: () => false,
      analytics: {
        descName: 'summary'
      }
    },
    beforeEnter: beforeEnter
  },
  {
    path: '/vahvistus',
    name: 'confirmationStep',
    component: ConfirmationStep,
    meta: {
      analytics: {
        descName: 'confirmation'
      }
    },
    beforeEnter: beforeEnterConfirmation
  },
  {
    path: '/kiitos',
    name: 'thanksInitial',
    component: ThanksInitialView,
    meta: {
      analytics: {
        descName: 'thanks'
      }
    }
  },
  {
    path: '/kiitos-2',
    name: 'thanksFinal',
    component: ThanksFinalView,
    meta: {
      analytics: {
        descName: 'thanks'
      }
    }
  },
  {
    path: '/intro',
    name: 'intro',
    component: IntroView,
    meta: {
      analytics: {
        descName: 'intro'
      }
    }
  },
  {
    path: '/tunnistus',
    name: 'login',
    component: LoginView,
    meta: {
      analytics: {
        descName: 'login'
      }
    },
    beforeEnter: beforeEnterLogin
  },
  {
    path: '/virhe',
    name: 'error',
    component: ErrorView
  },
  {
    path: '/huolto',
    name: 'maintenance',
    component: MaintenanceView,
    beforeEnter: beforeEnterMaintenance
  },
  {
    path: '/psd2-data/success',
    beforeEnter: beforeEnterPsd2DataSuccess
  },
  {
    path: '/psd2-data/error',
    beforeEnter: beforeEnterPsd2DataError
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  store.commit('initStore')

  if (store.state.isMaintenance && to.name !== 'maintenance') {
    next({ name: 'maintenance' })
  } else {
    const transactionId = window.sessionStorage.getItem('transactionID')

    if (transactionId) {
      store.commit('setTransactionId', transactionId)
    }

    const authSource = Utils.getURLQueryParam('authSource')

    if (authSource) {
      store.commit('setAuthSource', authSource)
    }

    const authReason = Utils.getURLQueryParam('reason')
    const authStatus = Utils.getURLQueryParam('status')

    if (authReason && authStatus) {
      const nextUrl = `${window.location.origin}/#${to.path}`
      const errorUrl = `${window.location.origin}/#/virhe`
      const homeUrl = `${window.location.origin}/#/1`
      const introUrl = `${window.location.origin}/#/intro`

      switch(authStatus) {
        case 'OK':
          window.location.replace(nextUrl)
          break
        case 'CANCEL' && authReason === 'AUTHENTICATION':
          window.location.replace(homeUrl)
          break
        case 'CANCEL' && authReason === 'EDIT_TOKEN':
          window.location.replace(introUrl)
          break
        case 'INVALID_SSN':
          window.location.replace(errorUrl)
          break
        case 'ERROR':
          window.location.replace(errorUrl)
          break
        default:
          window.location.replace(nextUrl)
      }
    }

    store.dispatch('getUser')
      .then(() => {
        if (![ 'firstStep', 'login', 'thanksInitial', 'thanksFinal', 'error', 'maintenance', 'intro', 'confirmationStep' ].includes(to.name) && !store.getters.isAuthenticated) {
          store.commit('resetStore')
          next({ name: 'login' })
        } else {
          next()
        }
      })
  }
})

export default router
