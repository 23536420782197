<template>
  <div class="bar">
    <div
      class="bar-item"
      :style="`width: ${progressPercentage}%;`"
      role="progressbar"
      :aria-valuenow="value"
      :aria-valuemin="min"
      :aria-valuemax="max"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'bar',
  props: {
    value: {
      type: [ Number, String ],
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: false
    }
  },
  computed: {
    progressPercentage() {
      return this.value / this.max * 100
    }
  }
}
</script>
