<template>
  <div class="bg-gray p-4">
    <CalculateLoanPayment
      v-if="calculationType === 'CALCULATE_LOAN_PAYMENT'"
      :loanAmount="loanAmount"
      :loanTime="loanTime"
      :loanInterest="loanInterest / 100"
      :numberOfPaymentsPerYear="numberOfPaymentsPerYear"
      :originationFee="originationFee"
      :serviceFee="serviceFee"
      :hasError="hasError"
    />

    <CalculateLoanAmount
      v-if="calculationType === 'CALCULATE_LOAN_AMOUNT'"
      :loanPayment="loanPayment"
      :loanTime="loanTime"
      :loanInterest="loanInterest / 100"
      :numberOfPaymentsPerYear="numberOfPaymentsPerYear"
      :originationFee="originationFee"
      :serviceFee="serviceFee"
      :hasError="hasError"
    />

    <a
      :href="$t('mortgageCalculator.linkToApplication.url')"
      id="linkToMortgageForm"
      class="btn btn-block btn-primary mt-4"
      target="_top"
    >
      {{ $t("mortgageCalculator.linkToApplication.text") }}
    </a>
  </div>
</template>

<script>
import CalculateLoanAmount from './Calculations/CalculateLoanAmount'
import CalculateLoanPayment from './Calculations/CalculateLoanPayment'

export default {
  components: {
    CalculateLoanAmount,
    CalculateLoanPayment
  },
  props: {
    calculationType: {
      type: String,
      required: true
    },
    loanAmount: {
      type: [ Number, String ],
      required: true
    },
    loanPayment: {
      type: [ Number, String ],
      required: true
    },
    loanTime: {
      type: [ Number, String ],
      required: true
    },
    loanInterest: {
      type: [ Number, String ],
      required: true
    },
    numberOfPaymentsPerYear: {
      type: Number,
      required: true
    },
    originationFee: {
      type: Number,
      required: true
    },
    serviceFee: {
      type: Number,
      required: true
    },
    hasError: {
      type: Boolean,
      default: false
    }
  }
}
</script>
