import store from '@/store'
import { routes } from '@/router'

export class NavGuard {

  /*
   * Get first submitted form step
   *
   * 1. Filter form steps from all routes (i.e routes with 'meta' property)
   * 2. Filter form steps that should be skipped
   * 3. Find first submitted form step
   */
  static getFirstSubmittedRoute() {
    const firstSubmittedRoute = routes
      .filter(route => route && route.meta && route.meta.order)
      .filter(route => !route.meta.skip(store))
      .find(route => !route.meta.isSubmitted(store))

    return firstSubmittedRoute
  }

  /*
   * Allow access to form step if:
   *
   * 1. All form steps before activated step are submitted
   * 2. Activated form step should not be skipped
   *
   */
  static isRouteAllowed(route) {
    const firstSubmittedRoute = this.getFirstSubmittedRoute()
    return !firstSubmittedRoute ? true : firstSubmittedRoute.meta.order >= route.meta.order && !route.meta.skip(store)
  }

}