<template>
  <div class="input-group">
    <button
      :class="buttonClasses"
      type="button"
      role="button"
      aria-label="Vähennä"
      @click="decrease()"
    >
      <Icon class="h4" name="remove" />
    </button>
    <input
      :ref="id"
      :id="id"
      :class="inputClasses"
      type="text"
      :name="name"
      :value="value"
      v-bind="$attrs"
      @input="onInput($event.target.value)"
      @blur="onBlur($event.target.value)"
    >
    <button
      :class="buttonClasses"
      type="button"
      role="button"
      aria-label="Lisää"
      @click="increase()"
    >
      <Icon class="h4" name="add" />
    </button>
  </div>
</template>

<script>
import { Utils } from '@/utils/utils'

export default {
  name: 'number-input',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: [ Number, String ]
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      required: true
    },
    sm: {
      type: Boolean,
      default: false
    },
    lg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputClasses() {
      return {
        'form-input mx-2 s-rounded text-center': true,
        'input-sm': this.sm,
        'input-lg': this.lg
      }
    },
    buttonClasses() {
      return {
        'btn btn-action btn-primary': true,
        'btn-sm': this.sm,
        'btn-lg': this.lg
      }
    },
    decimals() {
      // Count number of decimals from step
      return Math.floor(this.step) === this.step ? 0 : (this.step.toString().split('.')[1].length || 0)
    }
  },
  methods: {
    formatValue(value, decimals = 0) {
      const numberValue = Utils.toNumber(value)

      if (Utils.isFinite(numberValue)) {
        return numberValue.toFixed(decimals)
      }
    },
    decrease() {
      const { min, step, decimals, formatValue } = this
      const value = this.$refs[this.id].value

      // Set min value if current value is empty on decrease click
      if (Utils.isEmpty(value)) {
        const formattedValue = formatValue(min, decimals)
        this.onInput(formattedValue)
        return
      }

      const numberValue = Utils.toNumber(value)
      const newValue = numberValue - step

      if (newValue > min) {
        const roundedToMultipleValue = Utils.roundToNearestMultiple(newValue, step)
        const roundedValue = Utils.round(roundedToMultipleValue, decimals)
        const formattedValue = formatValue(roundedValue, decimals)
        this.onInput(formattedValue)
      } else {
        const formattedValue = formatValue(min, decimals)
        this.onInput(formattedValue)
      }
    },
    increase() {
      const { min, max, step, decimals, formatValue } = this
      const value = this.$refs[this.id].value

      // Set min value if current value is empty on increase click
      if (Utils.isEmpty(value)) {
        const formattedValue = formatValue(min, decimals)
        this.onInput(formattedValue)
        return
      }

      const numberValue = Utils.toNumber(value)
      const newValue = numberValue + step

      if (newValue < max) {
        const roundedToMultipleValue = Utils.roundToNearestMultiple(newValue, step)
        const roundedValue = Utils.round(roundedToMultipleValue, decimals)
        const formattedValue = formatValue(roundedValue, decimals)
        this.onInput(formattedValue)
      } else {
        const formattedValue = formatValue(max, decimals)
        this.onInput(formattedValue)
      }
    },
    onInput(value) {
      this.$emit('input', value)
    },
    onBlur(value) {
      // Set null if value is empty on blur
      if (Utils.isEmpty(value)) {
        this.onInput(null)
        return
      }

      const { min, max, step, decimals, formatValue } = this
      const numberValue = Utils.toNumber(value)

      if (numberValue < min) {
        const formattedValue = formatValue(min, decimals)
        this.onInput(formattedValue)
        return
      }

      if (numberValue > max) {
        const formattedValue = formatValue(max, decimals)
        this.onInput(formattedValue)
        return
      }

      if (Utils.isFinite(numberValue)) {
        const roundedToMultipleValue = Utils.roundToNearestMultiple(numberValue, step)
        const roundedValue = Utils.round(roundedToMultipleValue, decimals)
        const formattedValue = formatValue(roundedValue, decimals)
        this.onInput(formattedValue)
      }
    }
  }
}
</script>
